import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { IdInterface } from '@thomas-duke-co/reis-shared';

@Component({
	templateUrl: './prompt-dialog.component.pug',
	styleUrls: ['./prompt-dialog.component.scss'],
})
export class PromptDialogComponent {

	public data: {
		title: string,
		instructions: string,
		allowCancel: boolean,
		placeholder: string,
		value: string,
		list: IdInterface[],
	};

	constructor(@Inject(MAT_DIALOG_DATA) data) {
		this.data = data;
	}

}
