import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { debounce } from 'rxjs/operators';
import { timer } from 'rxjs/observable/timer';
import { UserListModel } from '@thomas-duke-co/reis-shared';
import { Subscription } from 'rxjs/Subscription';
import { MatSelect, MatTableDataSource } from '@angular/material';
import { SummaryService } from '../../service/summary.service';
import { UsersService } from '../../../admin/service/users.service';
import { DataSource } from '@angular/cdk/collections';

@Component({
	templateUrl: './prospect-summary.page.pug',
	styleUrls: ['./prospect-summary.page.scss'],
})
export class ProspectSummaryPage implements OnInit, OnDestroy {

	public loading = false;
	public summary: { [key: string]: { count: number, percent: number } };
	public hasWantsByCategory: { category: string, subCategory: string, has: number; wants: number }[];
	public users: UserListModel[];
	public selectedUser = 0;
	public lastUpdated: Date;
	public dataSource: DataSource<{ category: string; subCategory: string; has: number; wants: number }>;
	public displayedColumns = ['category', 'subCategory', 'has', 'wants'];

	@ViewChild('selectedUserControl', {static: true})
	private selectedUserControl: MatSelect;
	private summarySubscription: Subscription;

	constructor(private usersService: UsersService, private summaryService: SummaryService) {}

	public ngOnInit() {
		this.loading = true;
		forkJoin(this.usersService.getAllUsers(), this.summaryService.getProspectSummary(), this.summaryService.getHasWantsByCategory())
			.finally(() => {
				this.loading = false;
			})
			.subscribe(([users, summary, hasWantsByCategory]) => {
				this.users = users.filter(user => user.active);
				this.summary = summary;
				this.hasWantsByCategory = hasWantsByCategory;
				this.updateDatasource();
				this.lastUpdated = new Date();
			});

		this.selectedUserControl.selectionChange
			.pipe(debounce(() => timer(500)))
			.subscribe(this.getSummary.bind(this));
	}

	public ngOnDestroy() {
		if (this.summarySubscription) {
			this.summarySubscription.unsubscribe();
			this.summarySubscription = null;
		}
	}

	public getSummary() {
		if (this.summarySubscription) {
			this.summarySubscription.unsubscribe();
			this.summarySubscription = null;
		}

		this.loading = true;
		this.summarySubscription = forkJoin(this.summaryService.getProspectSummary(this.selectedUser), this.summaryService.getHasWantsByCategory(this.selectedUser))
			.finally(() => {
				this.loading = false;
			})
			.subscribe(([summary, hasWantsByCategory]) => {
				this.lastUpdated = new Date();
				this.summary = summary;
				this.hasWantsByCategory = hasWantsByCategory;
				this.updateDatasource();
			});
	}

	public printSummary(): void {
		window.print();
	}

	private updateDatasource() {
		this.dataSource = new MatTableDataSource<{ category: string; subCategory: string; has: number; wants: number }>(this.hasWantsByCategory);
	}

}
