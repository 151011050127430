import { Component, DoCheck, EventEmitter, Input, Output, OnInit, ViewChild } from '@angular/core';
import { ProspectWantsModel } from '@thomas-duke-co/reis-shared/dist/model/prospect-wants.model';
import { DeleteDialogComponent } from '../../../../../component/deleteDialog/delete-dialog.component';
import { MatDialog, MatPaginator } from '@angular/material';
import { WantDialogComponent } from '../want-dialog/want-dialog.component';
import { TableColumnInterface } from '../../../../../component/table/interface/table-column.interface';
import { PropertyMatchModel, ValuesModel, ProspectModel, PropertyModel } from '@thomas-duke-co/reis-shared';
import { PaginationDatasource } from '../../../../../datasource/pagination.datasource';
import { Subject } from 'rxjs';
import { ProspectWantService } from '../../../service/prospect-want.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { MatchmakerDialogComponent } from '../../../../../component/matchmaker-dialog/matchmaker-dialog.component';
import { Router } from '@angular/router';


@Component({
	selector: 'prospect-wants',
	templateUrl: './prospect-wants.component.pug',
	styleUrls: ['./prospect-wants.component.scss'],
})
export class ProspectWantsComponent implements DoCheck, OnInit {

	@ViewChild(MatPaginator, {static: true}) protected paginator: MatPaginator;

	protected _prospectId: number;

	@Input('prospectId')
	set prospectId(prospectId: number) {
		this._prospectId = prospectId;
		this.ngOnInit();
	}

	get prospectId() {
		return this._prospectId;
	}

	@Input() public values: ValuesModel;
	@Input() public createMode = false;
	@Input() public hasRoutedToRights = false;
	@Input() public isDeleted = false;
	@Input() public needToCheckDuplicates: boolean = false;
	@Input() protected hasAdminRights = false;

	@Output() public valueChanged: EventEmitter<any> = new EventEmitter<any>();
	@Output() public addShownEvent: EventEmitter<{match: PropertyMatchModel, result: PropertyModel | ProspectModel}> = new EventEmitter<{match: PropertyMatchModel, result: PropertyModel | ProspectModel}>();

	public dataSource: PaginationDatasource<ProspectWantsModel>;
	public displayedColumns: TableColumnInterface[];
	public tableLoading: boolean = false;

	protected subject = new Subject<any>();

	constructor(
		protected dialog: MatDialog,
		protected deleteDialog: MatDialog,
		protected wantsService: ProspectWantService,
		protected toastrService: ToastrService,
		protected router: Router,
	) {}

	public ngOnInit(): void {
		this.dataSource = new PaginationDatasource(this.getWants.bind(this), this.paginator, null, [this.subject.asObservable()]);
		this.paginator.page.subscribe(() => this.tableLoading = true);
	}

	public getWants() {
		return this.wantsService.getWantsByProspectId(this.prospectId, this.paginator.pageIndex, this.paginator.pageSize)
			.finally(() => this.tableLoading = false);
	}

	public addWant(event?, want?: ProspectWantsModel) {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}

		const config = {
			width: '400px',
			data: {
				want: new ProspectWantsModel(),
				values: this.values,
				action: 'Add',
				canEdit: true,
				editMode: false,
			},
		};

		if (want) {
			config.data.want = new ProspectWantsModel(want);
		}

		const dialog = this.dialog.open(WantDialogComponent, config);
		dialog.afterClosed().subscribe((data) => {
			if (!data || data === 'Cancel') {
				return;
			} else {
				this.wantsService.createWant(data.want, this.prospectId)
					.subscribe(() => {
							this.toastrService.success('Want successfully added.');
							this.subject.next();
						}
						,
						(error: HttpErrorResponse) => {
							this.toastrService.error(error.error.message);
						});
			}
			if (data.addAnother) {
				this.addWant(null, data.want);
			}
		});
	}

	public editWant(event) {
		const want = event.row;
		const canEditOrDelete = this.hasAdminRights || (this.hasRoutedToRights && !this.isDeleted);
		const dialog = this.dialog.open(WantDialogComponent, {
			width: '400px',
			data: {
				want: {
					financialClassId: want.financialClassId,
					financialClassName: want.financialClassName,
					minPrice: want.minPrice,
					maxPrice: want.maxPrice,
					minSize: want.minSize,
					maxSize: want.maxSize,
					countyId: want.countyId,
					categoryId: want.categoryId,
					cityId: want.cityId,
					roadId: want.roadId,
					id: want.id,
				},
				values: this.values,
				action: 'Edit',
				canEdit: canEditOrDelete,
				canDelete: canEditOrDelete,
				editMode: true,
				noSaveAndAdd: true,
			},
		});

		dialog.afterClosed().subscribe((action) => {
			if (!action || action === 'Cancel') {
				return;
			} else if (action === 'Delete') {
				const deleteDialog = this.deleteDialog.open(DeleteDialogComponent, {
					width: '400px',
					data: {
						text: `this Want entry`,
						type: 'Want Entry',
					},
				});
				deleteDialog.afterClosed().subscribe((result) => {
					if (result === 'Delete') {
						this.wantsService.deleteWant(this.prospectId, want.id)
							.subscribe(() => {
									this.toastrService.success(`Want for #${this.prospectId} has been deleted`);
									this.subject.next();
								},
								(error: HttpErrorResponse) => {
									this.toastrService.error(error.error.message);
								});
					}
				});
			} else {
				this.wantsService.updateWant(action.want)
					.subscribe(() => {
							this.toastrService.success(`Want for #${this.prospectId} has been updated.`);
							this.subject.next();
						},
						(error: HttpErrorResponse) => {
							this.toastrService.error(error.error.message);
						},
					);
			}
		});
	}

	public showMatch(want: ProspectWantsModel): void {
		this.dialog
			.open(MatchmakerDialogComponent, {
				width: '1000px',
				data: {
					want: want,
				},
			})
			.afterClosed()
			.subscribe((result) => {
				if (result && !result.location) {
					this.addShownEvent.emit(result);
				}
				if (result.location) {
					// noinspection JSIgnoredPromiseFromCall
					this.router.navigateByUrl(`/${result.location}/detail/${result.id}#details`);
				}
			});
	}

	ngDoCheck() {
		if (this.displayedColumns) {
			return;
		}

		this.displayedColumns = [{
			columnDef: 'financialClass',
			title: 'Financial Class',
		}, {
			columnDef: 'lastUpdated',
			title: 'Last Updated'
		}, {
			columnDef: 'category',
			title: 'Category',
		}, {
			columnDef: 'minPrice',
			title: 'Min Price',
			pipe: 'currency',
		}, {
			columnDef: 'maxPrice',
			title: 'Max Price',
			pipe: 'currency',
		}, {
			columnDef: 'minSize',
			title: 'Min Size',
			pipe: 'number',
		}, {
			columnDef: 'maxSize',
			title: 'Max Size',
			pipe: 'number',
		}, {
			columnDef: 'propertyMatch',
			title: 'Find Matches',
			pipe: 'actions',
			buttons: [{
				raised: true,
				tooltip: 'Property Matches',
				label: 'Property Matches',
				callback: (row: ProspectWantsModel): void => {
					this.showMatch(row);
				},
			}],
		}];
	}
}
