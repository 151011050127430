import { AfterViewInit, ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ValuesService } from '../../../../service/values.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LeaseSpaceModel, PropertyModel, ProspectListModel, PropertyMatchModel, ProspectModel, ValuesModel, UserModel } from '@thomas-duke-co/reis-shared';
import { PropertyService } from '../../service/property.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { DeleteDialogComponent } from '../../../../component/deleteDialog/delete-dialog.component';
import { MatDialog } from '@angular/material';
import { ScrollSpyIndexService, ScrollSpyService } from 'ngx-scrollspy';
import { UserService } from '../../../../service/user.service';
import { CanComponentDeactivate } from '../../../../guard/can-deactivate.guard';
import { UnsavedChangesDialogComponent } from '../../../../component/unsaved-changes-dialog/unsaved-changes-dialog.component';
import { Observable } from 'rxjs/Observable';
import { YesNoDialogComponent } from '../../../../component/yes-no-dialog/yes-no-dialog.component';
import { ProspectService } from '../../../prospect/service/prospect.service';
import { Moment } from 'moment';
import { CostarEmailDialogComponent } from '../../component/property-edit/costar-email-dialog/costar-email-dialog.component';
import { EmailService } from '../../../../service/email.service';
import { ShownComponent } from '../../../../component/shown/shown.component';
import { ProspectSelectionEventInterface } from '../../../../interface/prospect-selection-event.interface';
import { Subscription } from 'rxjs/Subscription';
import { saveAs } from 'file-saver';
import { PromptDialogComponent } from '../../../../component/prompt-dialog/prompt-dialog.component';
import * as moment from 'moment';
import { ImportDialogComponent } from '../../component/import-dialog/import-dialog.component';
import { ArchiveDataDialogComponent } from '../../component/archive-data-dialog/archive-data-dialog.component';
import { MailingsComponent } from '../../../../component/mailings/mailings.component';
import { HighlightsComponent } from '../../component/property-edit/highlights/highlights.component';
import { RouterService } from '../../../../service/router.service';
import { TasksComponent } from '../../component/property-edit/tasks/tasks.component';

@Component({
	templateUrl: './property.page.pug',
	styleUrls: ['./property.page.scss'],
})
export class PropertyPage implements OnInit, AfterViewInit, CanComponentDeactivate {

	@ViewChild(ShownComponent, {static: false}) shownComponent: ShownComponent;
	@ViewChild(MailingsComponent, {static: true}) mailingsComponent: MailingsComponent;
	@ViewChild(TasksComponent, {static: false}) taskComponent: TasksComponent;

	public values: ValuesModel;
	public user: UserModel;
	public property: PropertyModel;
	public propertyFormGroup: FormGroup;
	public loading = false;
	public pdfLoading = false;
	public createMode = false;
	public hasAdminRights = false;
	public canHaveLeaseSpaces: boolean;
	public emailSignature: string;
	public currentSection: string = 'details';
	public csvUpdate: boolean = false;
	public backToLink: {title: string, url: string};
	protected csvSubscription: Subscription;
	protected fragment: string;
	protected importCreate: boolean = false;

	constructor(
		protected valuesService: ValuesService,
		protected route: ActivatedRoute,
		protected router: Router,
		protected fb: FormBuilder,
		protected propertyService: PropertyService,
		protected toastrService: ToastrService,
		protected deleteDialog: MatDialog,
		protected unsavedChangesDialog: MatDialog,
		protected scrollSpyService: ScrollSpyService,
		protected scrollSpyIndex: ScrollSpyIndexService,
		protected userService: UserService,
		protected changeDetectionRef: ChangeDetectorRef,
		protected yesNoDialog: MatDialog,
		protected prospectService: ProspectService,
		protected costarEmailDialog: MatDialog,
		protected emailService: EmailService,
		protected promptDialog: MatDialog,
		protected activatedRoute: ActivatedRoute,
		protected importDialog: MatDialog,
		protected archiveDataDialog: MatDialog,
		protected routerService: RouterService,
	) {}

	public confirmDelete(): void {
		const dialog = this.deleteDialog.open(DeleteDialogComponent, {
			width: '300px',
			data: {
				text: this.property.details.name,
				type: 'Property',
			},
		});

		dialog.afterClosed().subscribe(result => {
			if (result === 'Delete') {
				this.property.details.statusId = 8;
				this.propertyService.save(this.property).subscribe(() => {
						this.toastrService.success('Property #' + this.property.id + ' successfully deleted.');
						this.router.navigateByUrl('/property/search');
					}, (error: HttpErrorResponse) => {
						this.toastrService.error(error.message);
					},
				);
			}
		});
	}

	public async revert() {
		if (!await this.confirmRevert()) {
			return;
		}

		this.propertyFormGroup.patchValue({
			details: this.property.details,
			location: this.property.location,
			closing: this.property.closing,
			photos: {photos: this.property.photos},
			seller: this.property.seller,
			marketing: this.property.marketing,
			attributes: {attributes: this.property.attributes},
		});

		this.propertyFormGroup.setControl('leaseSpaces', this.fb.group({
			leaseSpaces: this.fb.array(this.property.leaseSpaces ? this.property.leaseSpaces
				.map((leaseSpace: LeaseSpaceModel) => this.fb.group({
					id: [leaseSpace.id],
					size: [leaseSpace.size, [Validators.pattern('^\\d*(\\.\\d{1,2})?$'), Validators.required]],
					sizeUnitId: [leaseSpace.sizeUnitId],
					minDivisible: [leaseSpace.minDivisible, [Validators.pattern('^\\d*(\\.\\d{1,2})?$'), Validators.required]],
					maxContiguous: [leaseSpace.maxContiguous, [Validators.pattern('^\\d*(\\.\\d{1,2})?$'), Validators.required]],
					leaseRate: [leaseSpace.leaseRate, [Validators.pattern('^\\d*(\\.\\d{1,2})?$'), Validators.required]],
					leaseRateType: [leaseSpace.leaseRateType, Validators.maxLength(200)],
					pricePerAmount: [leaseSpace.pricePerAmount],
					pricePerUnitId: [leaseSpace.pricePerUnitId],
					suiteOrBuilding: [leaseSpace.suiteOrBuilding, [Validators.maxLength(255), Validators.required]],
					floor: [leaseSpace.floor, Validators.maxLength(255)],
					leaseTypeId: [leaseSpace.leaseTypeId],
					statusId: [leaseSpace.statusId],
					notes: [leaseSpace.notes, Validators.maxLength(255)],
				})) : []),
		}));

		this.propertyFormGroup.setControl('highlights', this.fb.group({
			highlights: this.fb.array(this.property.marketing.highlights.map((highlight: string) => [highlight, Validators.maxLength(255)])),
		}));

		this.propertyFormGroup.setControl('photos', this.fb.group({
			photos: this.fb.array(this.property.photos.map(photo => [photo])),
		}));

		this.propertyFormGroup.markAsPristine();
		this.changeDetectionRef.detectChanges();
	}

	private touchAll(formGroup: FormGroup | FormArray, func = 'markAsTouched', opts = {onlySelf: false}): void {
		Object.keys(formGroup.controls).forEach(key => {
			if (formGroup.controls[key] instanceof FormGroup || formGroup.controls[key] instanceof FormArray) {
				this.touchAll(formGroup.controls[key], func, opts);
			} else {
				formGroup.controls[key][func](opts);
			}
		});
	}

	private getErroredFields(formGroup: FormGroup | FormArray): any {
		const fields = [];
		Object.keys(formGroup.controls).forEach(key => {
			if (formGroup.controls[key].invalid) {
				if (key === 'leaseSpaces') {
					fields.push('Lease Spaces');
				} else {
					fields.push(key.charAt(0).toUpperCase() + key.substring(1));
				}
			}
		});

		return fields;
	}

	public saveButtonClicked(): void {
		// noinspection JSIgnoredPromiseFromCall
		this.saveChanges();
	}

	public getEmailSignature(): Observable<string> {
		this.userService.getDefaultEmailSignature().subscribe(signature => {
			this.emailSignature = signature;
		});
		return;
	}

	protected sendToCostar(): Promise<void> {
		return new Promise<void>((resolve: any): void => {
			this.costarEmailDialog
				.open(CostarEmailDialogComponent, {
					width: '1000px',
					data: {
						propertyName: this.property.details.name,
						url: HighlightsComponent.BROCHURE_URL + '/' + this.property.marketing.buildoutId,
						userEmail: this.userService.getUser().email,
						userText: this.emailSignature,
					},
				})
				.afterClosed()
				.subscribe((result) => {
					if (result === 'Cancel' || result == null) {
						return;
					}
					this.emailService.sendEmailToCostar(result).subscribe(
						() => {
							this.toastrService.success(`Email successfully sent to CoStar`);
							return resolve(result);
						},
						(error: HttpErrorResponse) => {
							this.toastrService.error(error.error.message);
						},
					);
				});
		});
	}

	protected promptToUpdateCoStar(): Promise<boolean> {
		return new Promise<boolean>((resolve: any): void => {
			this.yesNoDialog
				.open(YesNoDialogComponent, {
					disableClose: true,
					data: {
						title: 'Update CoStar',
						question: `Do you want to update CoStar?`,
						allowCancel: false,
					},
				})
				.afterClosed()
				.subscribe(result => resolve(result === 'Yes'));
		});
	}

	private async saveChanges(): Promise<boolean> {
		if (this.propertyFormGroup.invalid) {
			this.touchAll(this.propertyFormGroup);
			const errors = this.getErroredFields(this.propertyFormGroup);
			this.toastrService.error('Please resolve errors in ' + errors.join(', ') + ' and try again.');
			return false;
		}

		this.loading = true;
		this.toastrService.clear();

		const value = this.propertyFormGroup.getRawValue();
		const updatedProp = new PropertyModel(value);
		updatedProp.details.categoryIds = value.details.categoryIds;
		updatedProp.details.financialClassIds = value.details.financialClassIds;
		updatedProp.details.code = value.details.code;
		updatedProp.photos = value.photos.photos;
		updatedProp.leaseSpaces = value.leaseSpaces.leaseSpaces;
		updatedProp.attributes = value.attributes.attributes;
		updatedProp.id = this.property.id;
		updatedProp.shouldUpload = value.details.shouldUpload;
		updatedProp.marketing.highlights = value.highlights.highlights;
		updatedProp.marketing.buildoutId = this.property.marketing.buildoutId;

		if (updatedProp.details.salesMtgPresentationDate) {
			updatedProp.details.salesMtgPresentationDate = moment.utc(value.details.salesMtgPresentationDate).format('YYYY-MM-DD');
		}

		this.canHaveLeaseSpaces = updatedProp.details.financialClassIds.includes(2);

		return new Promise<boolean>((resolve: any) => {
			if (!this.property.id) {
				this.propertyService.create(updatedProp, this.importCreate)
					.subscribe(
						id => {
							this.toastrService.success('Property successfully created.');
							this.propertyFormGroup.markAsPristine();
							this.router.navigateByUrl('/property/detail/' + id);
							resolve(true);
						},
						(error: HttpErrorResponse) => {
							this.loading = false;
							this.toastrService.error(error.error.message);
							resolve(false);
						},
					);
			} else {
				this.propertyService.save(updatedProp)
					.finally(() => {
						this.loading = false;
						this.property = updatedProp;
					})
					.subscribe(
						async () => {
							this.toastrService.success('Property successfully saved.');
							this.propertyFormGroup.markAsPristine();

							if (!this.property.marketing.buildoutId) {
								return;
							}

							if (await this.promptToUpdateCoStar()) {
								await this.sendToCostar();
							}
							this.router.navigateByUrl('/property/detail/' + this.property.id + '?saved=1');
							resolve(true);
						},
						(error: HttpErrorResponse) => {
							this.toastrService.error(error.error.message);
							resolve(false);
						},
					);
			}
		});
	}

	getData(): void {
		this.loading = true;
		this.route.data
			.finally(() => {
				this.loading = false;
			})
			.subscribe(response => {
				this.property = response.detail;
				this.buildFormGroup();
			});
	}

	buildFormGroup(): void {
		if (!this.property) {
			this.property = new PropertyModel({details: {statusId: 1, isConfidential: true}, shouldUpload: true});
			this.createMode = true;
		} else if (this.router.url.indexOf('create') > -1) {
			this.createMode = true;
			this.property.id = null;
			this.property.details.financialClassIds = [null, null];
			this.property.details.categoryIds = [null, null];
			this.property.details.code = null;
			this.property.details.buildoutId = null;
			this.property.details.isConfidential = true;
		}

		this.canHaveLeaseSpaces = this.property.details.financialClassIds.includes(2);

		let salesMeetingPresentationDate: Date | null = null;
		if (this.property.details.salesMtgPresentationDate) {
			salesMeetingPresentationDate = new Date(this.property.details.salesMtgPresentationDate.replace(/-/g, '\/').replace(/T.+/, ''));
		}

		this.propertyFormGroup = this.fb.group({
			details: this.fb.group({
				name: [this.property.details.name, [Validators.required, Validators.maxLength(50)]],
				propertyDescription: [this.property.details.propertyDescription],
				code: [this.property.details.code, Validators.maxLength(50)],
				financialClassIds: this.fb.array(this.property.details.financialClassIds.map(id => [id])),
				categoryIds: this.fb.array(this.property.details.categoryIds.map(id => [id])),
				agentIds: this.fb.array(this.property.details.agentIds.map(id => [id])),
				statusId: [this.property.details.statusId],
				parcelId: [this.property.details.parcelId, Validators.maxLength(1000)],
				zoning: [this.property.details.zoning, Validators.maxLength(1000)],
				utilities: [this.property.details.utilities, Validators.maxLength(255)],
				surroundingBusinesses: [this.property.details.surroundingBusinesses],
				currentOccupancy: [this.property.details.currentOccupancy, Validators.maxLength(255)],
				yearBuilt: [this.property.details.yearBuilt, Validators.pattern('^\\d{4}$')],
				yearLastRenovated: [this.property.details.yearLastRenovated, Validators.pattern('^\\d{4}$')],
				numberOfStories: [this.property.details.numberOfStories, [Validators.pattern('^\\d*$'), Validators.maxLength(100)]],
				parkingRatio: [this.property.details.parkingRatio, Validators.maxLength(100)],
				ceilingHeight: [this.property.details.ceilingHeight, [Validators.pattern('^\\d*$'), Validators.maxLength(100)]],
				nnnExpenses: [this.property.details.nnnExpenses, Validators.maxLength(100)],
				buildingSize: [this.property.details.buildingSize, Validators.pattern('^\\d*(\\.\\d{1,2})?$')],
				termsOfSale: [this.property.details.termsOfSale, Validators.maxLength(255)],
				legalDescription: [this.property.details.legalDescription],
				size: [this.property.details.size, Validators.pattern('^\\d*(\\.\\d{1,2})?$')],
				sizeUnitId: [this.property.details.sizeUnitId],
				unitsOrSuite: [this.property.details.unitsOrSuite, Validators.maxLength(200)],
				price: [this.property.details.price, Validators.pattern('^\\d*(\\.\\d{1,2})?$')],
				pricePerAmount: [this.property.details.pricePerAmount, Validators.pattern('^\\d*(\\.\\d{1,2})?$')],
				pricePerUnitId: [this.property.details.pricePerUnitId],
				buildingClass: [this.property.details.buildingClass],
				listDate: [this.property.details.listDate],
				expirationDate: [this.property.details.expirationDate],
				salesMtgPresentationDate: [salesMeetingPresentationDate],
				minAvailable: [this.property.details.minAvailable, Validators.pattern('^\\d*(\\.\\d{1,2})?$')],
				maxContiguous: [this.property.details.maxContiguous, Validators.pattern('^\\d*(\\.\\d{1,2})?$')],
				isFeatured: [this.property.details.isFeatured],
				isConfidential: [this.property.details.isConfidential],
				shouldUpload: [this.property.shouldUpload],
				summerTaxes: [this.property.details.summerTaxes, Validators.pattern('^\\d*(\\.\\d{1,2})?$')],
				winterTaxes: [this.property.details.winterTaxes, Validators.pattern('^\\d*(\\.\\d{1,2})?$')],
				totalTaxes: [this.property.details.totalTaxes, Validators.pattern('^\\d*(\\.\\d{1,2})?$')],
				buildoutId: [this.property.details.buildoutId, Validators.pattern('^\\d*$')],
			}),
			location: this.fb.group({
				location: [this.property.location.location, Validators.maxLength(75)],
				roadId: [this.property.location.roadId],
				cityId: [this.property.location.cityId],
				countyId: [this.property.location.countyId, Validators.required],
				mapAddress: [this.property.location.mapAddress, Validators.maxLength(255)],
				mapCity: [this.property.location.mapCity, [Validators.required, Validators.maxLength(255)]],
				mapZip: [this.property.location.mapZip, [Validators.required, Validators.maxLength(10)]],
				latitude: [this.property.location.latitude, [
					Validators.min(-90),
					Validators.max(90),
					Validators.pattern('^-?\\d*(\\.\\d*)?$'),
				]],
				longitude: [this.property.location.longitude, [
					Validators.min(-180),
					Validators.max(180),
					Validators.pattern('^-?\\d*(\\.\\d*)?$'),
				]],
				locationDescription: [this.property.location.locationDescription],
				trafficCounts: [this.property.location.trafficCounts, Validators.maxLength(255)],
				override: [this.property.location.override],
			}),
			closing: this.fb.group({
				commitmentNumber: [this.property.closing.commitmentNumber, Validators.maxLength(30)],
				executionDate: [this.property.closing.executionDate],
				closingDate: [this.property.closing.closingDate],
				closeFileDate: [this.property.closing.closeFileDate],
				closingPrice: [this.property.closing.closingPrice],
				leaseRate: [this.property.closing.leaseRate],
				leaseRateUnitId: [this.property.closing.leaseRateUnitId],
				leaseTypeId: [this.property.closing.leaseTypeId],
				buyerName: [this.property.closing.buyerName],
			}),
			photos: this.fb.group({photos: this.fb.array(this.property.photos.map(photo => [photo]))}),
			seller: this.fb.group({
				sellerMotivation: [this.property.seller.sellerMotivation, Validators.maxLength(255)],
				owner: this.fb.group({
					id: [this.property.seller.owner.id],
					details: this.fb.group({
						firstName: [this.property.seller.owner.details.firstName, Validators.maxLength(30)],
						lastName: [this.property.seller.owner.details.lastName, Validators.maxLength(30)],
						code: [this.property.seller.owner.details.code, Validators.maxLength(9)],
						workPhone: [this.property.seller.owner.details.workPhone, Validators.maxLength(14)],
						extension: [this.property.seller.owner.details.extension],
						homePhone: [this.property.seller.owner.details.homePhone, Validators.maxLength(14)],
						cellPhone: [this.property.seller.owner.details.cellPhone, Validators.maxLength(14)],
					}),
				}),
				coOpPercent: [this.property.seller.coOpPercent, [
					Validators.pattern('^-?\\d*(\\.\\d{1,2})?$'),
					Validators.max(100),
					Validators.min(0),
				]],
				commissionPercent: [this.property.seller.commissionPercent, [
					Validators.pattern('^-?\\d*(\\.\\d{1,2})?$'),
					Validators.max(100),
					Validators.min(0),
				]],
				commissionDollars: [this.property.seller.commissionDollars, Validators.pattern('^\\d*(\\.\\d{1,2})?$')],
			}),
			highlights: this.fb.group({
				highlights: this.fb.array(this.property.marketing.highlights.map((highlight: string) => [highlight, Validators.maxLength(255)])),
			}),
			leaseSpaces: this.fb.group({
				leaseSpaces: this.fb.array(this.property.leaseSpaces ? this.property.leaseSpaces
					.map((leaseSpace: LeaseSpaceModel) => this.fb.group({
						id: [leaseSpace.id],
						size: [leaseSpace.size, [Validators.pattern('^\\d*(\\.\\d{1,2})?$'), Validators.required]],
						sizeUnitId: [leaseSpace.sizeUnitId],
						minDivisible: [leaseSpace.minDivisible, [Validators.pattern('^\\d*(\\.\\d{1,2})?$'), Validators.required]],
						maxContiguous: [leaseSpace.maxContiguous, [Validators.pattern('^\\d*(\\.\\d{1,2})?$'), Validators.required]],
						leaseRate: [leaseSpace.leaseRate, [Validators.pattern('^\\d*(\\.\\d{1,2})?$'), Validators.required]],
						leaseRateType: [leaseSpace.leaseRateType, Validators.maxLength(200)],
						pricePerAmount: [leaseSpace.pricePerAmount],
						pricePerUnitId: [leaseSpace.pricePerUnitId],
						suiteOrBuilding: [leaseSpace.suiteOrBuilding, [Validators.maxLength(255), Validators.required]],
						floor: [leaseSpace.floor, Validators.maxLength(255)],
						leaseTypeId: [leaseSpace.leaseTypeId],
						statusId: [leaseSpace.statusId],
						notes: [leaseSpace.notes, Validators.maxLength(255)],
					})) : []),
			}),
			attributes: this.fb.group({attributes: [this.property.attributes]}),
		});
	}

	getValues(): void {
		this.loading = true;
		this.valuesService.getValues()
			.finally(() => {
				this.loading = false;
			})
			.subscribe(values => {
				this.values = values;
			});
	}

	ngOnInit(): void {
		this.backToLink = this.getBackToLink(this.routerService.getPreviousUrl());

		this.activatedRoute.queryParams.subscribe((params) => {

			if (params.restore) {
				setTimeout(() => {
					this.goToSection('details');
				}, 1500);
			}

			if (!params.import) {
				return;
			}

			setTimeout(() => {
				this.loading = true;

				this.importDialog.open(ImportDialogComponent, {
					width: '500px',
				}).afterClosed().subscribe((result) => {

					if (!result || result === 'Cancel') {
						this.loading = false;
						this.router.navigateByUrl('/property/create');
						return;
					}

					this.importDataFromBuildout(result);
					this.router.navigateByUrl('/property/create');
				});
			});
		});
		this.getData();
		this.getValues();
		this.setPermissions();
		this.getEmailSignature();
	}

	ngAfterViewInit(): void {
		this.scrollSpyService.getObservable('content2').subscribe((e: any) => {
			const sectionId: string = this.getScrollSpySection(e);

			if (sectionId && sectionId !== this.currentSection) {
				this.currentSection = sectionId;
				this.updateUrlFragment(sectionId);
			}
		});

		this.route.fragment.subscribe(fragment => {
			if (!fragment) {
				return;
			}

			setTimeout(() => {
				this.goToSection(fragment);
			}, 1000);
		});
	}

	public importDataFromBuildout(id: number): void {
		this.propertyService.importFromBuildout(id).finally(() => this.loading = false).subscribe((data) => {
			this.property = data;
			this.buildFormGroup();
			this.touchAll(this.propertyFormGroup);
			this.changeDetectionRef.detectChanges();
			this.propertyFormGroup.markAsDirty();
			this.importCreate = true;
			this.toastrService.success('Property imported from buildout - review and save in order to create');
		}, (error: HttpErrorResponse) => {
			if (error.error.name === 'PropertyExistsError') {
				this.toastrService.error(`Property Already Exists in IS.`);
			} else {
				this.toastrService.error('Something went wrong while accessing the property from buildout:', error.message);
			}
		});
	}

	public cancelCreate() {
		window.history.back();
	}

	public setPermissions() {
		this.user = this.userService.getUser();
		this.hasAdminRights = (this.userService.hasRole('Admin') || this.userService.hasRole('Secretary'));
	}

	public saveAsPdf() {
		this.pdfLoading = true;
		const tab: Window = window.open('/loading');
		this.propertyService.getPdf(this.property.id)
			.finally(() => {
				this.pdfLoading = false;
			})
			.subscribe((data) => {
				const file = new Blob([data], {type: 'application/pdf'});
				tab.location.replace(URL.createObjectURL(file));
				setTimeout(() => tab.print(), 1500);
			});
	}

	public async getStatusUpdates(includePhoneNumbers: boolean) {
		let filename: string;
		let tab: Window;

		this.pdfLoading = true;

		if (!this.csvUpdate) {
			tab = window.open('/loading');
		} else {
			try {
				filename = await this.promptForCsvFilename();
			} catch (e) {
				return; // user cancelled
			}
		}

		if (this.csvUpdate) {
			this.csvSubscription = this.propertyService.getStatusUpdateCsv(this.property.id, includePhoneNumbers)
				.finally(() => {
					this.pdfLoading = false;
				})
				.subscribe((data) => {
					if (!filename.match(/\.csv$/)) {
						filename += '.csv';
					}

					const file = new Blob([data], {type: 'text/csv'});
					saveAs(file, filename);
					this.csvUpdate = false;
				});
		} else {
			this.propertyService.getStatusUpdatesPdf(this.property.id, includePhoneNumbers)
				.finally(() => {
					this.pdfLoading = false;
				})
				.subscribe((data) => {
					const file = new Blob([data], {type: 'application/pdf'});
					tab.location.replace(URL.createObjectURL(file));
					setTimeout(() => tab.print(), 1500);
				});
		}


	}

	public askAboutPhoneNumber(type?: string) {
		if (type === 'csv') {
			this.csvUpdate = true;
		}
		this.yesNoDialog.open(YesNoDialogComponent, {
			disableClose: true,
			data: {
				callback: this.getStatusUpdates.bind(this),
				title: 'Include Phone Numbers?',
				question: 'Do you want to include prospect phone numbers?',
				allowCancel: true,
			},
		});
	}

	public closeProperty() {
		this.pdfLoading = true;
		const tab: Window = window.open('/loading');
		this.propertyService.closingProcess(this.property.id)
			.finally(() => {
				this.pdfLoading = false;
			})
			.subscribe((data) => {
				const file = new Blob([data], {type: 'application/pdf'});
				tab.location.replace(URL.createObjectURL(file));
				setTimeout(() => tab.print(), 1500);
			});
	}

	public getDetailsFormGroup() {
		return this.propertyFormGroup.controls['details'] as FormGroup;
	}

	public archiveData(): void {
		this.archiveDataDialog.open(ArchiveDataDialogComponent, {
			width: '600px',
		}).afterClosed().subscribe((data) => {
			if (data === 'Cancel') {
				return;
			}

			this.propertyService.archiveData(this.property.id, data).subscribe(() => {
				data.forEach((section) => {
					if (section === 'closing') {
						this.propertyFormGroup.controls['closing'].reset();
					}

					if (section === 'mailings') {
						this.mailingsComponent.ngOnInit();
					}

					if (section === 'showns') {
						this.shownComponent.ngOnInit();
					}

					if (section === 'tasks') {
						this.taskComponent.ngOnInit();
					}
				});
			});
		});
	}

	public getUnsavedChangesTooltip(defaultMessage?: string): string {
		if (this.propertyFormGroup.dirty) {
			return 'Please save your changes';
		}

		return defaultMessage;
	}

	public prospectSelected(event: ProspectSelectionEventInterface) {
		// noinspection JSIgnoredPromiseFromCall
		this.router.navigateByUrl(`/prospect/detail/${event.prospect.id}#details`);
	}

	public async addMatchMakerShown(event: {match: PropertyMatchModel, result: ProspectModel, event}): Promise<void> {
		await this.shownComponent.addMatchMakerShown(event.match, event.result, event.event);
	}

	public setNextContactDate(event: {nextContactDate: Moment, prospect: Partial<ProspectListModel>}): void {
		this.prospectService.setNextContactDate(event.prospect.id, event.nextContactDate).subscribe(
			() => {
				this.toastrService.success('Next Contact Date updated.');
			},
			(error: HttpErrorResponse) => {
				this.toastrService.error('Failed to update Next Contact Date: ' + error.error.message);
			},
		);
	}

	public goToSection(fragment: string): void {
		const element: HTMLElement = document.getElementById(fragment);
		if (element) {
			element.scrollIntoView({behavior: 'smooth'});
		}
	}

	protected updateUrlFragment(fragment: string): void {
		if (history.replaceState) {
			history.replaceState(null, null, window.location.pathname + '#' + fragment);
		} else {
			window.location.hash = fragment;
		}
	}

	protected getScrollSpySection(e: any): string {
		const items: any[] = this.scrollSpyIndex.getIndex('sections');

		if (!items || !items.length) {
			return;
		}

		// if scroll position is at the bottom of the page
		if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
			return items[items.length - 1].id;
		} else {
			for (let i = items.length - 1; i >= 0; i--) {
				if ((e.target.scrollTop - items[i].offsetTop >= -115)) {
					return items[i].id;
				}
			}
		}
	}

	protected confirmRevert() {
		return new Promise<boolean>(resolve => {
			this.unsavedChangesDialog
				.open(UnsavedChangesDialogComponent, {
					width: '400px',
					data: {
						hideSave: true,
					},
				})
				.afterClosed()
				.subscribe(choice => {
					if (choice === 'Discard') {
						resolve(true);
					} else {
						resolve(false);
					}
				});
		});
	}

	@HostListener('window:beforeunload', ['$event'])
	public onBeforeUnload($event: BeforeUnloadEvent): void {
		if (this.propertyFormGroup.dirty) {
			$event.returnValue = true;
		}
	}

	public canDeactivate(): Promise<boolean> | boolean {
		if (!this.propertyFormGroup.dirty) {
			return true;
		}

		return new Promise<boolean>((resolve: any) => {
			this.unsavedChangesDialog
				.open(UnsavedChangesDialogComponent, {width: '400px'})
				.afterClosed()
				.subscribe(async choice => {
					if (choice === 'Cancel') {
						resolve(false);
					} else if (choice === 'Save') {
						resolve(await this.saveChanges());
					} else {
						resolve(true);
					}
				});
		});
	}

	protected promptForCsvFilename(): Promise<string> {
		return new Promise<string>((resolve: any, reject: any): void => {
			this.promptDialog
				.open(PromptDialogComponent, {
					width: '400px',
					disableClose: true,
					data: {
						title: 'CSV Filename',
						allowCancel: true,
						placeholder: 'Filename',
						value: 'status-update.csv',
					},
				})
				.afterClosed()
				.subscribe(result => {
					if (result === false) {
						reject();
					} else {
						resolve(result);
					}
				});
		});
	}

	protected getBackToLink(previousUrl: string): {title: string, url: string} {
		const previousUrlRevised: string = previousUrl.indexOf('?') > 0 ? previousUrl.substring(0, previousUrl.indexOf('?')) : previousUrl;
		const segments = previousUrlRevised.split('/');
		const title = segments[1];
		const backToLink = {title: 'search', url: '/property/search'};

		if (!title) {
			return backToLink;
		}

		backToLink.title = segments[2] === 'search' ? 'Search' : title;
		backToLink.url = previousUrlRevised;

		return backToLink;
	}
}
