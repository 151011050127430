import { Component, OnInit, ViewChild } from '@angular/core';
import { ReportService } from '../../service/report.service';
import { MatTableDataSource } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { ProspectListModel, ValueInterface } from '@thomas-duke-co/reis-shared';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PhoneNumberFormatPipe } from '../../../../pipe/phone-number-format.pipe';
import { BoxComponent } from '../box/box.component';
import * as moment from 'moment';

const DATE_FORMAT = 'MM/DD/YYYY';
const NEXT_CONTACTS = 'NEXT_CONTACTS';
const PAST_DUE_CONTACTS = 'PAST_DUE_CONTACTS';

@Component({
	selector: 'is2-contacts',
	templateUrl: './contacts.component.pug',
	styleUrls: ['./contacts.component.scss'],
})
export class ContactsComponent implements OnInit {

	@ViewChild('box', {static: true})
	protected box: BoxComponent;

	protected startDate;
	protected endDate;

	public loading = false;
	public dataSource: MatTableDataSource<any>;
	public displayedColumns = ['nextContactDate', 'name', 'company', 'actions'];
	public isExtraSmall: boolean;
	public optionsForm: FormGroup;
	public phoneFormatter: PhoneNumberFormatPipe;
	public resultList: ProspectListModel[];
	public dropdown: ValueInterface[] = [
		{ id: NEXT_CONTACTS, name: 'Next Contacts', selected: true },
		{ id: PAST_DUE_CONTACTS, name: 'Past Due Contacts' },
	];

	public constructor(
		protected reportService: ReportService,
		protected toastrService: ToastrService,
		protected fb: FormBuilder,
		protected mediaObserver: MediaObserver,
	) {
		this.mediaObserver.media$.subscribe((change: MediaChange) => {
			this.isExtraSmall = change.mqAlias === 'xs';
		});
		this.phoneFormatter = new PhoneNumberFormatPipe();
		this.setToNextContacts();
	}

	public ngOnInit(): void {
		this.getContacts();
	}

	public optionsChanged() {
		this.startDate = this.optionsForm.value.dateRange.startDate;
		this.endDate = this.optionsForm.value.dateRange.endDate;
		this.box.closeMenu();
		this.getContacts();
	}

	public cancelMenu(): void {
		this.box.closeMenu();
	}

	public dialed(error: string) {
		if (!error) {
			this.toastrService.success('Dial completed.');
		} else {
			this.toastrService.error(error);
		}
	}

	public clickToEmail(email: string): void {
		location.href = `mailto:${email}`;
	}

	public dropdownChanged(selection: string): void {
		if (selection === NEXT_CONTACTS) {
			this.setToNextContacts();
		} else if (selection === PAST_DUE_CONTACTS) {
			this.setToPastDueContacts();
		}

		this.getContacts();
	}

	protected setToNextContacts(): void {
		this.endDate = moment().endOf('week').format(DATE_FORMAT);
		this.startDate = moment().startOf('week').format(DATE_FORMAT);
		this.makeOptionsForm();
	}

	protected setToPastDueContacts(): void {
		this.endDate = moment().format(DATE_FORMAT);
		this.startDate = null;
		this.makeOptionsForm();
	}

	protected getContacts(): void {
		this.loading = true;

		this.reportService.getNextContacts(this.startDate || null, this.endDate || null)
			.finally(() => {
				this.loading = false;
			})
			.subscribe((results: ProspectListModel[]) => {
				this.resultList = results;
				this.dataSource = new MatTableDataSource<any>(results.map(prospect => ({
					id: prospect.id,
					nextContactDate: prospect.nextContactDate,
					name: ((prospect.firstName || '') + ' ' + (prospect.lastName || '')).trim(),
					company: prospect.company,
					phone: prospect.cellPhone || prospect.workPhone || prospect.homePhone || prospect.faxNumber,
					email: prospect.email,
				})));
			});
	}

	protected makeOptionsForm(): void {
		this.optionsForm = this.fb.group({
			dateRange: this.fb.group({
				startDate: new FormControl(this.startDate),
				endDate: new FormControl(this.endDate),
			}),
		});
	}

}
