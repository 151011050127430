import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserListModel, UserModel, ValueInterface } from '@thomas-duke-co/reis-shared';
import { ValuesService } from '../../../../service/values.service';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { UsersService } from '../../service/users.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatInput, MatSelect } from '@angular/material';
import { UserService } from '../../../../service/user.service';
import { YesNoDialogComponent } from '../../../../component/yes-no-dialog/yes-no-dialog.component';

const ID_PATTERN = /^[0-9]+$/;
const PHONE_PATTERN = /^[0-9]{10}$/;
const EXTENSION_PATTERN = /^[0-9]{3,5}$/;
const ZIPCODE_PATTERN = /^[0-9]{5}$/;

@Component({
	templateUrl: './user-detail.page.pug',
	styleUrls: ['./user-detail.page.scss'],
})

export class UserDetailPage implements OnInit {

	public loading = false;
	public teams: ValueInterface[];
	public users: UserListModel[];
	public user: UserModel;
	public userFormGroup: FormGroup;
	public passwordsFormGroup: FormGroup;
	public zipcodeMask = [/[1-9]/, /\d/, /\d/, /\d/, /\d/];
	public states = require('../../../../../assets/states.json');
	@ViewChildren(MatInput) inputs: QueryList<MatInput>;
	@ViewChildren(MatSelect) selects: QueryList<MatSelect>;

	constructor(
		private route: ActivatedRoute,
		private fb: FormBuilder,
		private toastrService: ToastrService,
		private valuesService: ValuesService,
		private usersService: UsersService,
		private userService: UserService,
		private yesNoDialog: MatDialog,
	) {
	}

	public async saveChanges(): Promise<void> {
		const ext = this.userFormGroup.controls['extension'].value;

		if (this.user.extension !== ext) {
			const save = await this.checkDuplicateExt(ext);

			if (!save) {
				this.userFormGroup.controls['extension'].setValue(this.user.extension);
				this.toastrService.warning('User phone extension has not been updated');
			}
		}

		if (this.userFormGroup.invalid) {
			const focused = false;
			Object.keys(this.userFormGroup.controls).forEach(field => {
				const control = this.userFormGroup.get(field);
				control.markAsTouched({onlySelf: true});
			});

			const input = this.inputs.find(control => control.ngControl.invalid);
			const select = this.selects.find(control => control.ngControl.invalid);

			if (input) {
				input.focus();
			} else if (select) {
				select.focus();
			}

			return;
		}

		const formValue = this.userFormGroup.value;
		formValue.password = formValue.passwords.password;

		const user = new UserModel(formValue);

		if (!this.user.userId) {
			this.usersService.createUser(user).subscribe((newUser: UserModel) => {
				this.user = newUser;
				this.userFormGroup.patchValue(newUser);
				this.passwordsFormGroup.reset();
				this.toastrService.success('Successfully created user');
			}, (response) => {
				this.toastrService.error(response.error.message);
			});
		} else {
			this.usersService.saveUser(user).subscribe((updatedUser: UserModel) => {
				this.user = updatedUser;
				this.userFormGroup.patchValue(updatedUser);
				this.passwordsFormGroup.reset();
				this.toastrService.success('Successfully updated user');
			}, (response) => {
				this.toastrService.error(response.error.message);
			});
		}
	}

	public ngOnInit(): void {
		this.loading = true;
		combineLatest([this.usersService.getAllUsers(), this.valuesService.getValues(), this.route.data])
			.subscribe(([users, values, resolve]) => {
				this.users = users.filter(user => user.active);
				this.teams = values.team;
				this.user = new UserModel(resolve.user);

				this.createFormGroup();
				this.loading = false;
			});
	}

	public checkDuplicateExt(ext: string): Promise<boolean> {
		const user = `${this.userFormGroup.controls['firstName'].value} ${this.userFormGroup.controls['lastName'].value}`;

		return new Promise<boolean>((resolve: any) => {
			this.userService.checkDuplicateExtension(ext, this.user.userId).subscribe((duplicate: UserListModel) => {
				if (duplicate) {
					this.yesNoDialog.open(YesNoDialogComponent, {
						disableClose: true,
						data: {
							title: `${duplicate.firstName} ${duplicate.lastName} has ext ${ext}`,
							question: `Do you want to reassign extension ${ext} to ${user}? ${duplicate.firstName} ${duplicate.lastName} will no longer have an extension assigned.`,
							allowCancel: true,
						},
					}).afterClosed().subscribe((result) => {
						if (result === 'Yes') {
							this.userService.removePhoneExt(duplicate.userId).subscribe(() => {
								resolve(true);
							});
						} else {
							resolve(false);
						}
					});
				} else {
					resolve(true);
				}
			});
		});
	}

	private createFormGroup() {
		if (!this.user) {
			this.user = new UserModel();
		}

		const password = this.fb.control('', Validators.maxLength(20));
		const confirmPassword = this.fb.control('', Validators.maxLength(20));

		password.valueChanges.subscribe((value) => {
			if (password.value && confirmPassword.value !== value) {
				confirmPassword.setErrors({passwordMismatch: true});
			} else {
				confirmPassword.updateValueAndValidity({onlySelf: true, emitEvent: false});
			}
		});

		confirmPassword.valueChanges.subscribe((value) => {
			if ((password.value || confirmPassword.value) && password.value !== value) {
				confirmPassword.setErrors({passwordMismatch: true});
			}
		});

		this.passwordsFormGroup = this.fb.group({
			password,
			confirmPassword,
		});

		this.userFormGroup = this.fb.group({
			userId: this.fb.control(this.user.userId, Validators.pattern(ID_PATTERN)),
			username: this.fb.control(this.user.username, [Validators.required, Validators.maxLength(20)]),
			passwords: this.passwordsFormGroup,
			roles: this.fb.array([this.fb.control(this.user.roles[0], Validators.required)]),
			title: this.fb.control(this.user.title, Validators.required),
			firstName: this.fb.control(this.user.firstName, [Validators.required, Validators.maxLength(30)]),
			middleInitial: this.fb.control(this.user.middleInitial, Validators.maxLength(1)),
			lastName: this.fb.control(this.user.lastName, [Validators.required, Validators.maxLength(30)]),
			initials: this.fb.control(this.user.initials, [Validators.required, Validators.maxLength(3)]),
			address1: this.fb.control(this.user.address1, Validators.maxLength(50)),
			address2: this.fb.control(this.user.address2, Validators.maxLength(50)),
			city: this.fb.control(this.user.city, Validators.maxLength(25)),
			state: this.fb.control(this.user.state),
			zipcode: this.fb.control(this.user.zipcode, Validators.pattern(ZIPCODE_PATTERN)),
			email: this.fb.control(this.user.email, [this.emailValidator, Validators.maxLength(50)]),
			homePhone: this.fb.control(this.user.homePhone, Validators.pattern(PHONE_PATTERN)),
			workPhone: this.fb.control(this.user.workPhone, Validators.pattern(PHONE_PATTERN)),
			extension: this.fb.control(this.user.extension, Validators.pattern(EXTENSION_PATTERN)),
			cellPhone: this.fb.control(this.user.cellPhone, Validators.pattern(PHONE_PATTERN)),
			pager: this.fb.control(this.user.pager, Validators.pattern(PHONE_PATTERN)),
			position: this.fb.control(this.user.position, Validators.maxLength(50)),
			teamId: this.fb.control(this.user.teamId),
			assistantId: this.fb.control(this.user.assistantId),
			active: this.fb.control(this.user.active, Validators.required),
			buildoutId: this.fb.control(this.user.buildoutId, Validators.pattern(ID_PATTERN)),
		});
	}

	private emailValidator(control: AbstractControl): ValidationErrors {
		// the default email validator also makes email a required field, but for us it is not required
		if (!control.value) {
			return null;
		}

		return Validators.email(control);
	}

	public trim(field) {
		const control: FormControl = this.userFormGroup.get(field) as FormControl;
		control.setValue(control.value.trim());
	}
}
