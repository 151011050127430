import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { UserListModel } from '@thomas-duke-co/reis-shared';
import { UsersService } from '../../module/admin/service/users.service';

@Component({
	selector: 'print-header',
	templateUrl: './print-header.component.pug',
	styleUrls: ['./print-header.component.scss']
})

export class PrintHeaderComponent implements OnInit {

	public currentDate: string = moment(new Date()).format('MM/DD/YYYY');
	public users: UserListModel[];

	@Input() public selectedUser: number;
	@Input() public startDate: Date;
	@Input() public endDate: Date;
	@Input() public timePeriod: string;
	@Input() public title: string;

	constructor(
		public router: Router,
		public usersService: UsersService,
		) {}

	public ngOnInit(): void {
		this.usersService.getAllUsers().subscribe((result) => this.users = result);
	}

	public getTitle(): string {
		let title: string;

		if (this.title) {
			title = this.title;
		} else {
			const routeArray: string[] = this.router.url.split('/');
			title = routeArray[routeArray.length - 1].replace(/([-])/g, ' ');
		}

		return `IS2 - ${title}`;
	}

	public getStartDate(): string {
		return moment(this.startDate).format('MM/DD/YYYY');
	}

	public getEndDate(): string {
		return moment(this.endDate).format('MM/DD/YYYY');
	}

	public getAgent(): string {
		const agent: UserListModel = this.users.find((user) => user.userId === this.selectedUser);
		return `${agent.firstName} ${agent.lastName}`;
	}

	public getTimePeriod(): string {
		return (this.timePeriod === 'week' ? `Past ${this.timePeriod}` : `Past Day`);
	}
}
