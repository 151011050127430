import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { PaginationDto, PropertyListModel, PropertySearchDto } from '@thomas-duke-co/reis-shared';
import { Observable } from 'rxjs/Observable';
import { Service } from '../../../service';
import { PropertyService } from './property.service';

@Injectable()
export class SearchService extends Service {

	public static readonly DEFAULT_STATUSES: number[] = PropertyService.ACTIVE_STATUSES;

	constructor(protected http: HttpClient) {
		super();
	}

	public search(searchDto: Partial<PropertySearchDto>, currentPage: number = 0, pageSize: number = 10): Observable<PaginationDto<PropertyListModel>> {
		if (!searchDto.statusId || searchDto.statusId.length === 0) {
			searchDto.statusId = SearchService.DEFAULT_STATUSES;
		}

		return this.http.post<PaginationDto<PropertyListModel>>(`${environment.apiUrl}/property`, searchDto, {params: this.buildHttpParams({currentPage, pageSize})});
	}

	public getMeetingAgendaPdf(searchDto: Partial<PropertySearchDto>): Observable<ArrayBuffer> {
		if (!searchDto.statusId || searchDto.statusId.length === 0) {
			searchDto.statusId = [1];
		}

		return this.http.get(`${environment.apiUrl}/property/agenda`, {
			headers: {'Accept': 'application/pdf'},
			responseType: 'arraybuffer',
			params: this.buildHttpParams(searchDto),
		});
	}

	public getPropertySearchResultsPdf(searchDto: PropertySearchDto): Observable<ArrayBuffer> {
		if (!searchDto.statusId || searchDto.statusId.length === 0) {
			searchDto.statusId = SearchService.DEFAULT_STATUSES;
		}

		return this.http.get(`${environment.apiUrl}/property/pdf`, {
			headers: {'Accept': 'application/pdf'},
			responseType: 'arraybuffer',
			params: this.buildHttpParams(searchDto),
		});
	}

}
