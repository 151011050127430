import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TeamModel, UserListModel, UserModel } from '@thomas-duke-co/reis-shared';
import { TeamsService } from '../../service/teams.service';
import { MatTableDataSource } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UsersService } from '../../service/users.service';
import { TableColumnInterface } from '../../../../component/table/interface/table-column.interface';
import { MatDialog } from '@angular/material';
import { TeamMemberDialogComponent } from '../../component/team-member-dialog/team-member-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { DeleteDialogComponent } from '../../../../component/deleteDialog/delete-dialog.component';

@Component({
	templateUrl: './team-detail.page.pug',
	styleUrls: ['./team-detail.page.scss'],
})

export class TeamDetailPage implements OnInit {

	public team: TeamModel;
	public dataSource: MatTableDataSource<UserModel>;
	public teamFormGroup: FormGroup;
	public users: UserListModel[];
	public loading: boolean = false;
	public teamMembers: UserModel[];
	public columns: TableColumnInterface[] = [
		{columnDef: 'name', title: 'Team Member'},
		{columnDef: 'position', title: 'Position'},
		{
			columnDef: 'delete',
			title: 'Remove',
			pipe: 'actions',
			buttons: [{
				fontSet: 'far',
				fontIcon: 'fa-trash-alt',
				color: 'warn',
				tooltip: 'Remove Member',
				callback: (row: UserModel): void => {
					this.removeMember(row);
				},
			}],
		},
	];

	constructor(
		protected route: ActivatedRoute,
		protected teamsService: TeamsService,
		protected fb: FormBuilder,
		protected usersService: UsersService,
		protected addMemberDialog: MatDialog,
		protected deleteDialog: MatDialog,
		protected toastrService: ToastrService,
		protected router: Router,
	) {}

	public ngOnInit(): void {
		this.loading = true;
		this.route.data.subscribe((resolve) => this.team = new TeamModel(resolve.user));
		this.teamsService.getAllTeamMembers(this.team.teamId).subscribe((results: UserModel[]) => {
			this.teamMembers = results;
			this.setDataSource();
		});
		this.usersService.getAllUsers().subscribe((users) => this.users = users.filter(user => user.active));
		this.createTeamFormGroup();
		this.loading = false;
	}

	public createTeamFormGroup(): void {
		this.teamFormGroup = this.fb.group({
			teamId: this.team.teamId,
			name: [this.team.name, [Validators.required]],
			teamAdmin: [this.team.teamAdmin, [Validators.required]],
		});
	}

	public deleteTeam(): void {
		this.deleteDialog.open(DeleteDialogComponent, {
			width: '300px',
			data: {
				text: 'this team',
				type: 'Team',
			},
		}).afterClosed().subscribe((response) => {
			if (response === 'Delete') {
				this.teamsService.deleteTeam(this.team.teamId).subscribe((result) => {
					if (result) {
						this.toastrService.success(`Team ${this.team.name} successfully deleted`);
						// noinspection JSIgnoredPromiseFromCall
						this.router.navigateByUrl('/admin/teams');
					}
				}, (error: HttpErrorResponse) => {
					this.toastrService.error(error.error.message, `Deleting team ${this.team.name} was unsuccessful`);
				});
			}
		});
	}

	public saveTeam(): void {
		if (this.teamFormGroup.controls['name'].invalid) {
			this.toastrService.error(`Team Name and Admin must be complete`);
			this.teamFormGroup.controls['name'].markAsTouched();
			this.teamFormGroup.controls['teamAdmin'].markAsTouched();
			return;
		}

		const team = new TeamModel(this.teamFormGroup.value);
		if (!team.teamId) {
			this.teamsService.createTeam(team).subscribe((result) => {
				if (result) {
					this.toastrService.success(`Team ${team.name} successfully created`);
					this.router.navigate([`/admin/teams/detail/${result.teamId}`]);
				}
			}, (error: HttpErrorResponse) => {
				this.toastrService.error(error.error.message, `Team ${team.name} has not been created`);
			});
		} else {
			this.teamsService.saveTeam(team).subscribe((result) => {
				if (result) {
					this.toastrService.success(`Team ${team.name} successfully updated`);
				}
			}, (error: HttpErrorResponse) => {
				this.toastrService.error(error.error.message, `Team ${team.name} has not been updated`);
			});
		}
	}

	public addTeamMember(): void {
		this.addMemberDialog.open(TeamMemberDialogComponent, {
			width: '500px',
			data: {
				users: this.users,
			},
		}).afterClosed().subscribe((data) => {
			if (data === 'Cancel') {
				return;
			}

			if (Array.isArray(data.members)) {
				this.teamsService.addTeamMembers(data.members, this.team.teamId).subscribe((result) => {
					if (result) {
						this.toastrService.success('Team members successfully added');
						this.ngOnInit();
					}
				}, (error: HttpErrorResponse) => {
					this.toastrService.error(error.error.message, 'Something went wrong, team members have not been added');
				});
			}
		});
	}

	public removeMember(row: UserModel): void {
		this.deleteDialog.open(DeleteDialogComponent, {
			width: '300px',
			data: {
				text: 'this team member',
				type: 'Team Member',
			},
		}).afterClosed().subscribe((response) => {
			if (response === 'Delete') {
				this.teamsService.removeMember(row.userId).subscribe((result) => {
					if (result) {
						this.toastrService.success(`${row.firstName} ${row.lastName} has been deleted from Team ${this.team.name}`);
						this.ngOnInit();
					}
				}, (error: HttpErrorResponse) => {
					this.toastrService.error(error.error.message, `Something went wrong, ${row.firstName} ${row.lastName} has not been deleted`);
				});
			}
		});
	}

	protected setDataSource(): void {
		this.teamMembers.forEach((member: UserModel) => {
			(member as any).name = member.firstName + ' ' + member.lastName;
		});
		this.dataSource = new MatTableDataSource<UserModel>(this.teamMembers);
	}

}
