import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ValuesService } from '../../service/values.service';
import { PropertySearchDto, ValuesModel } from '@thomas-duke-co/reis-shared';

@Component({
	selector: 'property-advanced-search',
	templateUrl: './property-advanced-search.component.pug',
	styleUrls: ['./property-advanced-search.component.scss'],
})
export class PropertyAdvancedSearchComponent {

	@Output() public search: EventEmitter<Partial<PropertySearchDto>> = new EventEmitter<PropertySearchDto>();

	public formGroup: FormGroup;
	public valuesModel: ValuesModel;

	@ViewChild('nameInput', {static: false}) public nameInput: ElementRef;

	constructor(protected fb: FormBuilder, valuesService: ValuesService) {
		valuesService.getValues()
			.subscribe(values => {
				this.valuesModel = values;
			});

		this.buildForm();
	}

	public propertySearch(): void {
		const searchDto: Partial<PropertySearchDto> = {};
		Object.keys(this.formGroup.value).forEach(key => {
			if (this.formGroup.value[key]) {
				searchDto[key] = this.formGroup.value[key];
			}
		});
		this.search.emit(searchDto);
	}

	public reset(): void {
		this.buildForm();
	}

	protected buildForm(): void {
		this.formGroup = this.fb.group({
			name: [],
			code: [],
			propertyDescription: [],
			ownerName: [],
			statusId: [],
			financialClass1Id: [],
			financialClass2Id: [],
			category1Id: [],
			category2Id: [],
			countyId: [],
			cityId: [],
			roadId: [],
			agent1Id: [],
			agent2Id: [],
			minPrice: [],
			maxPrice: [],
			minSize: [],
			maxSize: [],

			closingPrice: [],
			closingLeaseRate: [],
			closingLeaseRateUnitId: [],
			closingLeaseTypeId: [],

			listFromRelation: [],
			listFromValue: [],
			listToRelation: [],
			listToValue: [],

			expirationFromRelation: [],
			expirationFromValue: [],
			expirationToRelation: [],
			expirationToValue: [],

			closedFromRelation: [],
			closedFromValue: [],
			closedToRelation: [],
			closedToValue: [],

			salesPresentationFromRelation: [],
			salesPresentationFromValue: [],
			salesPresentationToRelation: [],
			salesPresentationToValue: [],
		});
	}

}
