import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ValuesService} from '../../../../service/values.service';
import {CodeModalComponent} from '../code-modal/code-modal.component';
import {CodesService} from '../../service/codes.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ModalButtonInterface} from '../code-modal/modal-button.interface';
import {DeleteDialogComponent} from '../../../../component/deleteDialog/delete-dialog.component';
import {MatDialog} from '@angular/material';
import {HttpErrorResponse} from '@angular/common/http';
import {SubcategoryModel} from '@thomas-duke-co/reis-shared/dist/model/subcategory.model';

@Component({
	selector: 'sub-category-codes',
	templateUrl: './sub-category-codes.component.pug',
	styleUrls: ['./sub-category-codes.component.scss'],
})
export class SubCategoryCodesComponent implements OnInit {

	public searchText: string = null;
	public loading = false;

	public modalSubCat: FormGroup;
	public modalTitle: string;

	@Input()
	public categoryID: number;

	@ViewChild('modal', {static: true}) modal: CodeModalComponent;

	public subcategories: SubcategoryModel[];
	public actionButtons: ModalButtonInterface[];

	constructor(private fb: FormBuilder,
							private codesService: CodesService,
							private toastrService: ToastrService,
							private valuesService: ValuesService,
							private deleteDialog: MatDialog) {}

	ngOnInit() {
		this.loading = true;
		this.codesService.getSubCategories(this.categoryID)
			.subscribe((values: SubcategoryModel[]) => {
				this.subcategories = values;
				this.loading = false;
			}, (error) => {
				this.toastrService.error(error);
				this.loading = false;
			});
	}

	public openModal() {
		this.modal.show();
	}

	public editSubCategory(subCat: any) {
		this.modalTitle = 'Edit SubCategory';
		this.modalSubCat = this.fb.group({
			id: subCat.id,
			name: [subCat.name, [Validators.required, Validators.maxLength(50)]],
			assignedNumber: [subCat.assignedNumber, [Validators.required, Validators.maxLength(5)]],
			description: [subCat.description, Validators.maxLength(50)],
			categoryID: subCat.categoryID
		});

		this.actionButtons = [
			{ id: 'cancel', title: 'Cancel', close: true},
			{ id: 'delete', title: 'Delete', color: 'warn'},
			{ id: 'save', title: 'Save', color: 'primary'}
		];
		this.openModal();
	}

	public modalAction(event) {
		if (this.modalSubCat.valid) {
			if (event.id === 'save') {
				this.loading = true;
				const subCat = this.modalSubCat.value;
				if (subCat.id) {
					this.codesService.saveSubCategory(subCat, this.categoryID)
						.subscribe((result) => {
							this.toastrService.success(`${subCat.name} saved.`);
							this.modal.close();
							this.ngOnInit();
							this.loading = false;
						}, (error) => {
							this.toastrService.error(error);
							this.loading = false;
						});
				} else {
					this.codesService.createSubCategory(subCat, this.categoryID)
						.subscribe((result) => {
							this.toastrService.success(`${subCat.name} created.`);
							this.modal.close();
							this.ngOnInit();
							this.loading = false;
						}, (error) => {
							this.toastrService.error(error);
							this.loading = false;
						});
				}
			} else if (event.id === 'delete') {
				this.deleteSubCategory(this.modalSubCat.value);
			}
		}
	}

	public deleteSubCategory(subCat: SubcategoryModel) {
		this.modal.close();
		const deleteDialog = this.deleteDialog.open(DeleteDialogComponent, {
			width: '400px',
			data: {
				text: subCat.description,
				type: 'SubCategory'
			}
		});
		deleteDialog.afterClosed().subscribe((result) => {
			if (result === 'Delete') {
				this.loading = true;
				this.codesService.deleteSubCategory(subCat.id, this.categoryID)
					.subscribe(() => {
							this.toastrService.success(`${subCat.name} has been deleted.`);
							this.ngOnInit();
							this.loading = false;
						},
						(error: HttpErrorResponse) => {
							this.toastrService.error(error.error.message);
							this.loading = false;
						}
					);
			} else {
				this.modal.show();
			}
		});
	}

	public addSubCategory() {
		this.modalTitle = 'Add SubCategory';
		this.actionButtons = [
			{ id: 'cancel', title: 'Cancel', close: true},
			{ id: 'save', title: 'Save', color: 'primary'}
		];
		const subCat = new SubcategoryModel();
		this.modalSubCat = this.fb.group({
			name: [subCat.name, [Validators.required, Validators.maxLength(50)]],
			assignedNumber: [subCat.assignedNumber, [Validators.required, Validators.maxLength(5)]],
			description: [subCat.description, Validators.maxLength(50)],
			categoryID: this.categoryID
		});
		this.openModal();
	}

}
