import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {ValuesModel } from '@thomas-duke-co/reis-shared';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { ValueInterface } from '@thomas-duke-co/reis-shared/dist/interface/value.interface';

const TYPES = [
	'propertyStatus',
	'financialClass',
	'propertyCategory',
	'agent',
	'activeAgent',
	'routedToAgent',
	'listBrokerAgent',
	'operatorAgent',
	'inputtedByAgent',
	'sizeUnit',
	'leaseType',
	'leaseStatus',
	'team',
	'prospectType',
	'callSource',
	'county',
	'city',
	'road',
	'title',
	'countyCode',
	'cityCode',
	'roadCode',
	'mailingType',
	'closingLeaseRateUnitId',
	'closingLeaseTypeId',
	'dashboards',
];

@Injectable()
export class ValuesService {

	constructor(protected http: HttpClient) {}

	public getValues(): Observable<ValuesModel> {
		let params = new HttpParams();
		TYPES.forEach(type => params = params.append('types', type));
		return this.http.get<ValuesModel>(`${environment.apiUrl}/values`, {params});
	}

	public getCitiesAndCounties(): Observable<(ValueInterface & {countyId: number})[]> {
		return this.http.get<(ValueInterface & {countyId: number})[]>(`${environment.apiUrl}/values/city-county`);
	}

}
