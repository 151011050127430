import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ValuesService} from '../../../../service/values.service';
import {LocationCodeModel} from '@thomas-duke-co/reis-shared/dist/model/location-code.model';
import {CodeModalComponent} from '../code-modal/code-modal.component';
import {CodesService} from '../../service/codes.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ValueInterface} from '@thomas-duke-co/reis-shared/dist/interface/value.interface';
import {ModalButtonInterface} from '../code-modal/modal-button.interface';
import {HttpErrorResponse} from '@angular/common/http';
import {DeleteDialogComponent} from '../../../../component/deleteDialog/delete-dialog.component';
import {MatDialog} from '@angular/material';
import {Subscription} from 'rxjs/Subscription';

@Component({
	selector: 'location-codes',
	templateUrl: './location-codes.component.pug',
	styleUrls: ['./location-codes.component.scss'],
})
export class LocationCodesComponent implements OnInit, OnDestroy {

	public searchText: string = null;
	public loading = false;

	public modalLocation: FormGroup;
	public modalTitle: string;

	private pdfSubscription: Subscription;

	@Input()
	public viewType: string;

	@ViewChild('modal', {static: true}) modal: CodeModalComponent;

	public locations: LocationCodeModel[];
	public counties: ValueInterface[];
	public actionButtons: ModalButtonInterface[];

	constructor(private fb: FormBuilder,
							private codesService: CodesService,
							private toastrService: ToastrService,
							private valuesService: ValuesService,
							private deleteDialog: MatDialog) {}

	ngOnInit() {
		this.loading = true;
		this.getLocations();
		this.valuesService.getValues()
			.subscribe((values) => {
				this.counties = values.county;
				this.loading = false;
			}, (error) => {
				this.toastrService.error(error);
				this.loading = false;
			});
	}

	ngOnDestroy() {
		if (this.pdfSubscription) {
			this.pdfSubscription.unsubscribe();
			this.pdfSubscription = null;
		}
	}

	public getLocations() {
		this.codesService.getLocations(this.viewType)
			.subscribe((results) => {
					this.locations = results;
					this.loading = false;
				},
				(error) => {
					this.toastrService.error(error);
					this.loading = false;
				});
	}

	public openModal() {
		this.modal.show();
	}

	public editLocation(loc: LocationCodeModel) {
		this.modalTitle = 'Edit ' + this.viewType;
		this.modalLocation = this.fb.group({
			name: loc.name,
			code: [loc.code, [Validators.required, Validators.maxLength(3)]],
			type: loc.type,
			description: loc.description,
			id: loc.id,
			countyID: loc.countyID
		});

		this.actionButtons = [
			{id: 'cancel', title: 'Cancel', close: true},
			{id: 'delete', title: 'Delete', color: 'warn'},
			{id: 'save', title: 'Save', color: 'primary'}
		];

		this.openModal();
	}

	public modalAction(event) {
		if (this.modalLocation.valid) {
			if (event.id === 'save') {
				this.loading = true;
				const loc = this.modalLocation.value;
				if (loc.id) {
					this.codesService.saveLocation(loc)
						.subscribe((result) => {
							this.toastrService.success(`${loc.name} saved.`);
							this.modal.close();
							this.getLocations();
							this.loading = false;
						}, (error) => {
							this.toastrService.error(error);
							this.loading = false;
						});
				} else {
					this.codesService.createLocation(loc)
						.subscribe((result) => {
							this.toastrService.success(`${loc.name} created.`);
							this.modal.close();
							this.getLocations();
							this.loading = false;
						}, (error) => {
							this.toastrService.error(error);
							this.loading = false;
						});
				}
			} else if (event.id === 'delete') {
				this.deleteLocation(this.modalLocation.value);
			}
		}
	}

	public deleteLocation(loc: LocationCodeModel) {
		const deleteDialog = this.deleteDialog.open(DeleteDialogComponent, {
			width: '400px',
			data: {
				text: loc.name,
				type: this.viewType
			}
		});
		deleteDialog.afterClosed().subscribe((result) => {
			if (result === 'Delete') {
				this.codesService.deleteLocation(loc.id)
					.subscribe(() => {
							this.toastrService.success(`${loc.name} has been deleted.`);
							this.modal.close();
							this.ngOnInit();
						},
						(error: HttpErrorResponse) => {
							this.toastrService.error(error.error.message);
						}
					);
			} else {
				this.modal.close();
			}
		});
	}

	public addLocation() {
		this.modalTitle = 'Add ' + this.viewType;
		this.actionButtons = [
			{id: 'cancel', title: 'Cancel', close: true},
			{id: 'save', title: 'Save', color: 'primary'}
		];
		const loc = new LocationCodeModel();
		this.modalLocation = this.fb.group({
			name: loc.name,
			code: [loc.code, [Validators.required, Validators.maxLength(3)]],
			type: this.viewType,
			description: loc.description,
			id: loc.id,
			countyID: loc.countyID
		});
		this.openModal();
	}

	public print() {
		if (this.pdfSubscription) {
			this.pdfSubscription.unsubscribe();
			this.pdfSubscription = null;
		}

		this.loading = true;
		const tab: Window = window.open('/loading');
		this.pdfSubscription = this.codesService.getLocationsPdf()
			.finally(() => {
				this.loading = false;
			})
			.subscribe((data) => {
				const file = new Blob([data], {type: 'application/pdf'});
				tab.location.replace(URL.createObjectURL(file));
				setTimeout(() => tab.print(), 1500);
			});
	}

}
