import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatSelectChange } from '@angular/material';
import {
	PropertyModel,
	ProspectModel,
	TaskOccurrenceAssigneeModel,
	TaskOccurrenceModel,
	TaskGroupModel,
	UserModel,
	UserListModel, ValueInterface,
	TaskOccurrenceDto,
} from '@thomas-duke-co/reis-shared';
import { TaskService } from '../../../../../service/task.service';
import { TaskDialogComponent } from '../../../../../component/task-dialog/task-dialog.component';
import { TaskGroupDialogComponent } from '../../../../../component/task-group-dialog/task-group-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from '../../../../admin/service/users.service';
import { TaskGroupListComponent } from '../../../../../component/task-group-list/task-group-list.component';

@Component({
	selector: 'tasks',
	templateUrl: './tasks.component.pug',
	styleUrls: ['./tasks.component.scss'],
})
export class TasksComponent implements OnInit {

	public tasks: TaskOccurrenceDto[];
	public loading: boolean = false;
	public users: UserListModel[];
	public taskGroups: boolean = false;
	public labelNames: ValueInterface[];
	public showCompleted: boolean = false;
	public showArchived: boolean = false;

	public _user: UserModel;
	@Input() set user(user: UserModel) {
		this._user = user;
	}

	get user(): UserModel {
		return this._user;
	}

	public _property: PropertyModel;
	@Input() set property(property: PropertyModel) {
		this._property = property;
		if (property) {
			this.getTasks();
		}
	}

	get property(): PropertyModel {
		return this._property;
	}

	public _prospect: ProspectModel;
	@Input() set prospect(prospect: ProspectModel) {
		this._prospect = prospect;
		if (prospect) {
			this.getTasks();
		}
	}

	get prospect(): ProspectModel {
		return this._prospect;
	}

	public _hasAdminRights: boolean;
	@Input() set hasAdminRights(hasAdminRights: boolean) {
		this._hasAdminRights = hasAdminRights;
		if (hasAdminRights && (this.property || this.prospect)) {
			this.getTasks();
		}
	}

	get hasAdminRights(): boolean {
		return this._hasAdminRights;
	}

	@ViewChild('taskGroupList', {static: true}) public taskGroupList: TaskGroupListComponent;

	constructor(
		protected taskDialog: MatDialog,
		protected taskGroupDialog: MatDialog,
		protected taskService: TaskService,
		protected toastrService: ToastrService,
		protected usersService: UsersService,
	) {}

	public ngOnInit(): void {
		this.usersService.getAllUsers().subscribe((users: UserListModel[]) => {
			this.users = users;
		});

		this.taskService.getLabels().subscribe((result: ValueInterface[]) => {
			this.labelNames = result;
		});
	}

	public getGroups(taskGroups: boolean) {
		this.taskGroups = taskGroups;
	}

	public getTasks(): void {
		this.loading = true;
		let method: string;
		let id: number;

		if (this.property) {
			method = 'getTasksByPropertyId';
			id = this.property.id;
		}

		if (this.prospect) {
			method = 'getTasksByProspectId';
			id = this.prospect.id;
		}

		this.taskService[method](id, this.showCompleted, this.showArchived).subscribe((data: TaskOccurrenceDto[]) => {
			this.tasks = data;
			this.loading = false;
		});
	}

	public addTask(event: MouseEvent): void {
		if (event) {
			event.stopPropagation();
			event.preventDefault();
		}

		this.taskDialog.open(TaskDialogComponent, {
			width: '1000px',
			data: {
				propertyId: this.property ? this.property.id : null,
				prospectId: this.prospect ? this.prospect.id : null,
				task: new TaskOccurrenceDto({
					occurrence: new TaskOccurrenceModel({
						assignee: new TaskOccurrenceAssigneeModel({
							userId: null,
						}),
					}),
				}),
				action: 'Add',
			},
		}).afterClosed().subscribe((data: {task: TaskOccurrenceDto, action: string}) => {
			if (!data) {
				return;
			}

			if (data.task.propertyId) {
				this.taskService.createTaskProperty(data.task).subscribe((result) => {
					if (result) {
						this.toastrService.success(`Task Successfully Added to Property ${this.property.id}`);
						this.getTasks();
					}
				}, (error) => {
					this.toastrService.error(`Unable to Add Task`, error);
				});
			}

			if (data.task.prospectId) {
				this.taskService.createTaskProspect(data.task).subscribe((result) => {
					if (result) {
						this.toastrService.success(`Task Successfully Added to Prospect ${this.prospect.id}`);
						this.getTasks();
					}
				}, (error) => {
					this.toastrService.error(`Unable to Add Task`, error);
				});
			}
		});
	}

	public addGroup(event: MouseEvent): void {
		if (event) {
			event.stopPropagation();
			event.preventDefault();
		}

		this.taskGroupDialog.open(TaskGroupDialogComponent, {
			width: '1000px',
			data: {
				propertyId: this.property ? this.property.id : null,
				prospectId: this.prospect ? this.prospect.id : null,
				taskGroup: new TaskGroupModel(),
				action: 'Add',
				hasAdminRights: this.hasAdminRights,
				hasEditRights: true,
			},
		}).afterClosed().subscribe((data: {group: TaskGroupModel, templateId: number, action: string}) => {
			if (!data) {
				return;
			}

			if (data.templateId) {
				let info: {
					templateId: number,
					propertyId: number,
					prospectId: number,
				};

				if (this.property) {
					info = {
						templateId: data.templateId,
						propertyId: this.property.id,
						prospectId: null,
					};
				} else {
					info = {
						templateId: data.templateId,
						propertyId: null,
						prospectId: this.prospect.id,
					};
				}

				this.taskService.createTaskGroupByTemplateId(info).subscribe((result) => {
					if (result) {
						this.toastrService.success(`Task Group Successfully Created`);
						this.taskGroupList.getTaskGroups();
					}
				}, (error) => {
					this.toastrService.error(error);
				});
			} else {
				let method: string;
				let id: number;

				if (this.property) {
					method = 'createTaskGroupProperty';
					id = this.property.id;
				} else {
					method = 'createTaskGroupProspect';
					id = this.prospect.id;
				}
				this.taskService[method](id, data.group).subscribe((result) => {
					if (result) {
						this.toastrService.success(`Task Group Successfully Created`);
						this.taskGroupList.getTaskGroups();
					}
				}, (error) => {
					this.toastrService.error(error);
				});
			}
		});
	}

	public selectionMade(event: MouseEvent): void {
		if (event) {
			event.stopPropagation();
			event.preventDefault();
		}
	}

	public selectionChanged(event: MatSelectChange): void {
		this.showCompleted = event.value.indexOf('completed') > -1;
		this.showArchived = event.value.indexOf('archived') > -1;
		this.taskGroupList.showCompleted = event.value.indexOf('completed') > -1;
		this.taskGroupList.showArchived = event.value.indexOf('archived') > -1;
		this.getTasks();
		this.taskGroupList.getTaskGroups();
	}

}
