import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

import {LocationCodeModel, ProspectTypeCodeModel, CallSourceCodeModel} from '@thomas-duke-co/reis-shared';
import {CategoryModel} from '@thomas-duke-co/reis-shared/dist/model/category.model';
import {SubcategoryModel} from '@thomas-duke-co/reis-shared/dist/model/subcategory.model';

@Injectable()
export class CodesService {
	constructor(protected http: HttpClient) {
	}

	getLocations(type: string) {
		return this.http.get<LocationCodeModel[]>(`${environment.apiUrl}/codes/locations/${type}`);
	}

	getLocationsPdf() {
		return this.http.get(`${environment.apiUrl}/codes/locations-pdf`, {
			headers: {'Accept': 'application/pdf'},
			responseType: 'arraybuffer'
		});
	}

	saveLocation(location: LocationCodeModel) {
		return this.http.put(`${environment.apiUrl}/codes/locations/${location.id}`, location);
	}

	deleteLocation(id: number) {
		return this.http.delete(`${environment.apiUrl}/codes/locations/${id}`);
	}

	createLocation(location: LocationCodeModel) {
		return this.http.post(`${environment.apiUrl}/codes/locations`, location);
	}

	getProspectTypes() {
		return this.http.get<ProspectTypeCodeModel[]>(`${environment.apiUrl}/codes/prospect-types`);
	}

	getProspectTypesPdf() {
		return this.http.get(`${environment.apiUrl}/codes/prospect-types-pdf`, {
			headers: {'Accept': 'application/pdf'},
			responseType: 'arraybuffer'
		});
	}

	saveProspectType(type: ProspectTypeCodeModel) {
		return this.http.put(`${environment.apiUrl}/codes/prospect-types/${type.id}`, type);
	}

	deleteProspectType(id: number) {
		return this.http.delete(`${environment.apiUrl}/codes/prospect-types/${id}`);
	}

	createProspectType(type: ProspectTypeCodeModel) {
		return this.http.post(`${environment.apiUrl}/codes/prospect-types`, type);
	}

	getCallSources() {
		return this.http.get<CallSourceCodeModel[]>(`${environment.apiUrl}/codes/call-sources`);
	}

	getCallSourcesPdf() {
		return this.http.get(`${environment.apiUrl}/codes/call-sources-pdf`, {
			headers: {'Accept': 'application/pdf'},
			responseType: 'arraybuffer'
		});
	}

	saveCallSource(type: CallSourceCodeModel) {
		return this.http.put(`${environment.apiUrl}/codes/call-sources/${type.id}`, type);
	}

	deleteCallSource(id: number) {
		return this.http.delete(`${environment.apiUrl}/codes/call-sources/${id}`);
	}

	createCallSource(type: CallSourceCodeModel) {
		return this.http.post(`${environment.apiUrl}/codes/call-sources`, type);
	}

	getCategories() {
		return this.http.get(`${environment.apiUrl}/codes/category`);
	}

	getCategoriesPdf() {
		return this.http.get(`${environment.apiUrl}/codes/categories-pdf`, {
			headers: {'Accept': 'application/pdf'},
			responseType: 'arraybuffer'
		});
	}

	getCategoryById(id: number) {
		return this.http.get(`${environment.apiUrl}/codes/category/${id}`);
	}

	saveCategory(category: CategoryModel) {
		return this.http.put(`${environment.apiUrl}/codes/category/${category.id}`, category);
	}

	deleteCategory(id: number) {
		return this.http.delete(`${environment.apiUrl}/codes/category/${id}`);
	}

	createCategory(category: CategoryModel) {
		return this.http.post(`${environment.apiUrl}/codes/category`, category);
	}

	getSubCategories(categoryID: number) {
		return this.http.get(`${environment.apiUrl}/codes/category/${categoryID}/sub-category`);
	}

	saveSubCategory(subcat: SubcategoryModel, categoryID: number) {
		return this.http.put(`${environment.apiUrl}/codes/category/${categoryID}/sub-category/${subcat.id}`, subcat);
	}

	deleteSubCategory(subCatID: number, categoryID: number) {
		return this.http.delete(`${environment.apiUrl}/codes/category/${categoryID}/sub-category/${subCatID}`);
	}

	createSubCategory(subcat: SubcategoryModel, categoryID: number) {
		return this.http.post(`${environment.apiUrl}/codes/category/${categoryID}/sub-category`, subcat);
	}
}
