import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ValueInterface, ValuesModel } from '@thomas-duke-co/reis-shared';

@Component({
	selector: 'property-closing',
	templateUrl: './closing.component.pug',
	styleUrls: ['./closing.component.scss']
})

export class ClosingComponent {

	@Input() public units: ValueInterface[];
	@Input() public leaseTypes: ValueInterface[];
	@Input() public values: ValuesModel;
	@Input() public closingFormGroup: FormGroup;
	@Input() public createMode: boolean;
	@Input() public hasAdminRights = false;
	public currentDate = new Date();
}
