import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PaginationDto, CallSummaryByUserModel, TeamModel} from '@thomas-duke-co/reis-shared';
import {FormControl} from '@angular/forms';
import {ReportService} from '../../service/report.service';
import {PaginationDatasource} from '../../../../datasource/pagination.datasource';
import {Observable} from 'rxjs/Observable';
import {RouterService} from '../../../../service/router.service';
import {ActivatedRoute, Params} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {TeamsService} from '../../../admin/service/teams.service';
import { TableComponent } from '../../../../component/table/table.component';

@Component({
	templateUrl: './team-call-summary.page.pug',
	styleUrls: ['./team-call-summary.page.scss'],
})
export class TeamCallSummaryPage implements OnInit, OnDestroy {

	@ViewChild(TableComponent, {static: true})
	private table: TableComponent;
	public team: TeamModel;
	private subscription: Subscription;

	public range: FormControl = new FormControl('week');
	public dataSource: PaginationDatasource<CallSummaryByUserModel>;
	public totalItems: number;

	public columns = [{
		title: 'Name',
		columnDef: 'name',
		sortable: true,
		fontWeight: 'bold'
	}, {
		title: 'Calls',
		columnDef: 'calls',
		sortable: true,
		pipe: 'number'
	}, {
		title: 'Productive Calls',
		columnDef: 'productiveCalls',
		sortable: true,
		pipe: 'number'
	}, {
		title: 'Total Duration',
		columnDef: 'totalDuration',
		sortable: true,
		pipe: 'duration'
	}, {
		title: 'Average Duration',
		columnDef: 'avgDuration',
		sortable: true,
		pipe: 'duration'
	}];

	constructor(private reportService: ReportService, private teamsService: TeamsService,
	            private routerService: RouterService, private route: ActivatedRoute) {
	}

	private getData(): Observable<PaginationDto<CallSummaryByUserModel>> {
		return this.reportService.getCallSummaryForTeam(this.range.value,
			this.team.teamId,
			this.table.paginator.pageIndex,
			this.table.paginator.pageSize,
			this.table.sort.active,
			this.table.sort.direction);
	}

	public onRowClicked(event) {
		const { row } = event;
		this.routerService.navigate([`/dashboard/calls/user/${row.userId}`]);
	}

	public backClicked() {
		this.routerService.navigate([`/dashboard/calls`]);
	}

	public ngOnInit(): void {
		this.subscription = this.route.params
			.flatMap(({id}: Params) => this.teamsService.getTeam(id))
			.subscribe((team: TeamModel) => {
				this.team = team;
				this.dataSource = new PaginationDatasource(this.getData.bind(this), this.table.paginator, this.table.sort, [this.range.valueChanges]);
				setTimeout(() => this.totalItems = this.dataSource.pager.data.length, 1000);
			});
	}

	public ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	public print(): void {
		window.print();
	}
}
