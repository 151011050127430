import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ProspectListModel, ValueInterface, ProspectModel, ValuesModel } from '@thomas-duke-co/reis-shared';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { ProspectSearchDialogComponent } from '../../../../../component/prospect-search-dialog/prospect-search-dialog.component';
import { CreateOwnerDialogComponent } from '../../create-owner-dialog/create-owner-dialog.component';

@Component({
	selector: 'property-seller',
	templateUrl: './seller.component.pug',
	styleUrls: ['./seller.component.scss'],
})

export class SellerComponent implements OnInit {

	@Input() public values: ValuesModel;
	@Input() public sellerFormGroup: FormGroup;
	@Input() public priceControl: FormControl;
	@Input() public hasAdminRights = false;
	@Input() public agents: ValueInterface[];

	public isExtraSmall: boolean;
	public loading: boolean = false;

	constructor(
		protected dialog: MatDialog,
		protected toastrService: ToastrService,
		protected mediaObserver: MediaObserver,
	) {
		this.mediaObserver.media$.subscribe((change: MediaChange) => {
			this.isExtraSmall = change.mqAlias === 'xs';
		});
	}

	public ngOnInit(): void {
		const commissionControl = this.sellerFormGroup.get('commissionPercent');

		this.priceControl.valueChanges.subscribe((price) => {
			const commissionPercent = commissionControl.value;
			if (this.priceControl.pristine || commissionControl.pristine) {
				return;
			} else if (Number.isNaN(price) || Number.isNaN(commissionPercent) || price < 0 || commissionPercent < 0 || commissionPercent > 100) {
				return;
			}

			const commissionDollars = Math.floor(price * (commissionPercent / 100));
			this.sellerFormGroup.patchValue({commissionDollars});
		});

		commissionControl.valueChanges.subscribe((commissionPercent) => {
			const price = this.priceControl.value;
			if (this.priceControl.pristine || commissionControl.pristine) {
				return;
			} else if (Number.isNaN(price) || Number.isNaN(commissionPercent) || price < 0 || commissionPercent < 0 || commissionPercent > 100) {
				return;
			}

			const commissionDollars = Math.floor(price * (commissionPercent / 100));
			this.sellerFormGroup.patchValue({commissionDollars});
		});

		this.sellerFormGroup.get('owner').disable();
	}

	public openSearch() {
		this.dialog.open(ProspectSearchDialogComponent, {
			width: '1000px',
			data: {
				multiple: false,
			},
		})
			.afterClosed()
			.subscribe(async (prospect: ProspectListModel) => {
				if (!prospect) {
					return;
				}
				await this.setFormValues(prospect);
			});
	}

	public getPhoneNumber(field: string): string {
		return this.sellerFormGroup.get('owner').get('details').get(field).value;
	}

	public dialed(error: string) {
		if (!error) {
			this.toastrService.success('Dial completed.');
		} else {
			this.toastrService.error(error);
		}
	}

	public hasOwner() {
		return !!this.sellerFormGroup.get('owner').value;
	}

	public getOwnerId() {
		return this.hasOwner() ? this.sellerFormGroup.get('owner').value.id : null;
	}

	public create(): void {
		this.loading = true;
		this.dialog.open(CreateOwnerDialogComponent, {
			width: '1500px',
		})
			.afterClosed()
			.finally(() => {
				this.loading = false;

			})
			.subscribe(async (prospect: ProspectModel | ProspectListModel | string) => {
				if (!prospect || prospect === 'Cancel') {
					return;
				}

				if (prospect instanceof ProspectModel) {
					await this.setFormValues(prospect.details);
				}

				if (this.isProspectListModel(prospect)) {
					await this.setFormValues(prospect);
				}
			});
	}

	protected isProspectListModel(object: any): object is ProspectListModel {
		return object && object.firstName;
	}

	protected async setFormValues(prospect: any): Promise<void> {
		this.sellerFormGroup.patchValue({
			owner: {
				id: prospect.id,
				details: {
					firstName: prospect.firstName,
					lastName: prospect.lastName,
					workPhone: prospect.workPhone,
					cellPhone: prospect.cellPhone,
					homePhone: prospect.homePhone,
					extension: prospect.extension,
					code: prospect.code,
				}
			}
		});
		this.sellerFormGroup.markAsDirty();
	}
}
