import {Component, OnDestroy, OnInit} from '@angular/core';
import {UsersService} from '../../service/users.service';
import {UserListModel} from '@thomas-duke-co/reis-shared';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';

@Component({
	templateUrl: './users.page.pug',
	styleUrls: ['./users.page.scss'],
})
export class UsersPage implements OnInit, OnDestroy {

	public searchText: string = null;
	public loading = false;
	public showInactive = false;
	public users: UserListModel[];

	private usersSubscription: Subscription;
	private pdfSubscription: Subscription;

	constructor(private usersService: UsersService, private router: Router) {}

	public navigate(userId: number): void {
		this.router.navigate([`/admin/users/detail/${userId}`]);
	}

	public ngOnInit(): void {
		this.loading = true;
		this.usersSubscription = this.usersService.getAllUsers().subscribe((users: UserListModel[]) => {
			this.users = users;
			this.loading = false;
		});
	}

	public ngOnDestroy(): void {
		if (this.pdfSubscription) {
			this.usersSubscription.unsubscribe();
			this.usersSubscription = null;
		}

		if (this.pdfSubscription) {
			this.pdfSubscription.unsubscribe();
			this.pdfSubscription = null;
		}
	}

	public print() {
		if (this.pdfSubscription) {
			this.pdfSubscription.unsubscribe();
			this.pdfSubscription = null;
		}

		this.loading = true;
		const tab: Window = window.open('/loading');
		this.pdfSubscription = this.usersService.getBrokerListPdf()
			.finally(() => {
				this.loading = false;
			})
			.subscribe((data) => {
				const file = new Blob([data], {type: 'application/pdf'});
				tab.location.replace(URL.createObjectURL(file));
				setTimeout(() => tab.print(), 1500);
			});
	}

	public userList() {
		if (this.pdfSubscription) {
			this.pdfSubscription.unsubscribe();
			this.pdfSubscription = null;
		}

		this.loading = true;
		const tab: Window = window.open('/loading');
		this.pdfSubscription = this.usersService.getUserListPdf()
			.finally(() => {
				this.loading = false;
			})
			.subscribe((data) => {
				const file = new Blob([data], {type: 'application/pdf'});
				tab.location.replace(URL.createObjectURL(file));
				setTimeout(() => tab.print(), 1500);
			});
	}

	public phoneList() {
		if (this.pdfSubscription) {
			this.pdfSubscription.unsubscribe();
			this.pdfSubscription = null;
		}

		this.loading = true;
		const tab: Window = window.open('/loading');
		this.pdfSubscription = this.usersService.getPhoneListPdf()
			.finally(() => {
				this.loading = false;
			})
			.subscribe((data) => {
				const file = new Blob([data], {type: 'application/pdf'});
				tab.location.replace(URL.createObjectURL(file));
				setTimeout(() => tab.print(), 1500);
			});
	}
}
