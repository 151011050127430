import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { PaginationDto, ProspectRemarkModel } from '@thomas-duke-co/reis-shared';

@Injectable()
export class ProspectRemarkService {
	constructor(protected http: HttpClient) {
	}

	public getProspectRemarks(
		id: number,
		currentPage: number,
		pageSize: number,
	): Observable<PaginationDto<ProspectRemarkModel>> {
		const params = new HttpParams()
			.append('currentPage', `${currentPage}`)
			.append('pageSize', `${pageSize}`);

		return this.http.get<PaginationDto<ProspectRemarkModel>>(`${environment.apiUrl}/prospect/${id}/remarks`, {params});
	}

	public createRemark(remark: ProspectRemarkModel): Observable<any> {
		return this.http.post(`${environment.apiUrl}/prospect/remarks`, remark);
	}

	public createBulkRemarks(remarks: ProspectRemarkModel[]): Observable<any> {
		return this.http.post(`${environment.apiUrl}/prospect/bulk-remarks`, remarks);
	}

	public deleteRemark(prospectId: number, remarkId: number): Observable<any> {
		return this.http.delete(`${environment.apiUrl}/prospect/${prospectId}/remarks/${remarkId}`);
	}

	public bulkDeleteRemarks(prospectId: number, remarkIds: number[]): Observable<any> {
		return this.http.post(`${environment.apiUrl}/prospect/${prospectId}/remark/delete`, {remarkIds});
	}

	public selectAllDeleteRemarks(prospectId: number, ids: number | number[]) {
		const params = new HttpParams()
			.append('prospectId', `${prospectId}`);

		if (!Array.isArray(ids)) {
			ids = [ids];
		}

		return this.http.delete<void>(`${environment.apiUrl}/prospect/select/all/delete/${ids.join(',')}`, {params});
	}

	public updateRemark(remark: ProspectRemarkModel, prospectId: number): Observable<any> {
		return this.http.put(`${environment.apiUrl}/prospect/${prospectId}/remarks/${remark.id}`, remark);
	}

}
