import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';
import {PropertyService} from '../../../property/service/property.service';
import {UsersService} from '../../../admin/service/users.service';
import {UserListModel} from '@thomas-duke-co/reis-shared';

@Component({
	templateUrl: './listing-summary.page.pug',
	styleUrls: ['./listing-summary.page.scss']
})
export class ListingSummaryPage implements OnInit, OnDestroy {

	public showInactive = false;
	public loading = false;
	public users: UserListModel[];
	public selectedUserId = 0;

	private usersSubscription: Subscription;
	private pdfSubscription: Subscription;

	constructor(private propertyService: PropertyService,
							private usersService: UsersService) {}

	public ngOnInit() {
		this.usersSubscription = this.usersService.getAllUsers().subscribe(users => {
			this.users = users;
		});
	}

	public ngOnDestroy() {
		if (this.usersSubscription) {
			this.usersSubscription.unsubscribe();
			this.usersSubscription = null;
		}

		if (this.pdfSubscription) {
			this.pdfSubscription.unsubscribe();
			this.pdfSubscription = null;
		}
	}

	public getPdf() {
		if (this.pdfSubscription) {
			this.pdfSubscription.unsubscribe();
		}

		this.loading = true;
		const tab: Window = window.open('/loading');
		this.pdfSubscription = this.propertyService.getListingSummaryPdf(this.selectedUserId, this.showInactive)
			.finally(() => {
				this.loading = false;
			})
			.subscribe((data) => {
				const file = new Blob([data], {type: 'application/pdf'});
				tab.location.replace(URL.createObjectURL(file));
				setTimeout(() => tab.print(), 1500);
			});
	}
}
