import { Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ProspectListModel, ProspectModel, ProspectSearchDto } from '@thomas-duke-co/reis-shared';
import { ProspectSearchService } from '../../service/prospect-search.service';
import { UserService } from '../../../../service/user.service';
import { ProspectService } from '../../service/prospect.service';
import { ProspectPage } from '../../page/prospect/prospect.page';
import { Subscription } from 'rxjs';
import { CanComponentDeactivate } from '../../../../guard/can-deactivate.guard';
import { NavigationService } from '../../../../service/navigation.service';

@Component({
	selector: 'prospect-quick-loader',
	templateUrl: './prospect-quick-loader.component.pug',
	styleUrls: ['./prospect-quick-loader.component.scss'],
})
export class ProspectQuickLoaderComponent implements OnInit, CanComponentDeactivate, OnDestroy {

	@ViewChild('prospectPage', {static: true}) protected prospectPage: ProspectPage;
	@Input() protected searchDto: ProspectSearchDto;
	@Input() protected sortFunction: (a: ProspectListModel, b: ProspectListModel) => number;

	public loading: boolean;
	public prospects: ProspectListModel[];
	public selectedProspect: ProspectModel;

	protected navigationSubscription: Subscription;

	constructor(
		protected navigationService: NavigationService,
		protected prospectService: ProspectService,
		protected prospectSearchService: ProspectSearchService,
		protected userService: UserService,
	) {
		this.navigationSubscription = this.navigationService.getObservable().subscribe(() => this.ngOnInit());
	}

	public async ngOnInit(): Promise<void> {
		this.loading = true;

		this.prospects = (await this.getProspects()).sort(this.sortFunction);

		if (this.prospects.length > 0) {
			await this.loadSelectedProspect(this.prospects[0]);
		}
		this.loading = false;
	}

	public ngOnDestroy(): void {
		this.navigationSubscription.unsubscribe();
	}

	public async loadSelectedProspect(selectedProspect: ProspectListModel): Promise<void> {
		const okToSwitch: boolean = await this.checkForUnsavedChanges();
		if (!okToSwitch) {
			return;
		}

		this.selectedProspect = await this.getProspect(selectedProspect.id);
	}

	public getProspectLabel(prospect: ProspectListModel): string {
		if (prospect.firstName || prospect.lastName) {
			return `${prospect.firstName} ${prospect.lastName}`;
		}
		return prospect.company;
	}

	public async prospectSaved(prospectId: number) {
		const prospectIds: number[] = (await this.getProspects()).map(prospect => prospect.id);

		let prospectIndex: number;
		this.prospects.forEach(async (prospect, index) => {
			prospect.completed = !prospectIds.includes(prospect.id);

			if (prospect.id === prospectId && prospect.completed) {
				prospectIndex = index;
			}

			if (prospectIndex != null && prospectIndex < index && !prospect.completed) {
				prospectIndex = null;
				await this.loadSelectedProspect(prospect);
			}
		});
	}

	public async prospectDeleted(prospectId: number): Promise<void> {
		const prospectIds: number[] = (await this.getProspects()).map(prospect => prospect.id);

		let prospectIndex: number;
		let deleteProspect: number;

		this.prospects.forEach(async (prospect, index) => {
			if (prospect.id === prospectId) {
				prospectIndex = index;
				deleteProspect = index;
			}

			prospect.completed = !prospectIds.includes(prospect.id);

			if (prospectIndex != null && prospectIndex < index && !prospect.completed) {
				prospectIndex = null;
				await this.loadSelectedProspect(prospect);
			}
		});

		this.prospects.splice(deleteProspect, 1);
	}

	protected getProspects(): Promise<ProspectListModel[]> {
		return new Promise<ProspectListModel[]>((resolve: Function, reject: Function): void => {
			this.prospectSearchService.search(this.searchDto, 0, null)
				.subscribe(
					result => resolve(result.data),
					error => reject(error),
				);
		});
	}

	protected getProspect(id: number): Promise<ProspectModel> {
		return new Promise<ProspectModel>((resolve: Function, reject: Function): void => {
			this.prospectService.getProspect(id)
				.subscribe(
					prospect => resolve(prospect),
					error => reject(error),
				);
		});
	}

	protected async checkForUnsavedChanges(): Promise<boolean> {
		if (!this.prospectPage) {
			return true;
		}

		return new Promise<boolean>((resolve: Function): void => {
			const canDeactivate: boolean | Promise<boolean> = this.prospectPage.canDeactivate();
			if (canDeactivate instanceof Promise) {
				canDeactivate.then(result => resolve(result));
			} else {
				resolve(canDeactivate);
			}
		});
	}

	@HostListener('window:beforeunload', ['$event'])
	public onBeforeUnload($event: BeforeUnloadEvent): void {
		if (this.prospectPage) {
			this.prospectPage.onBeforeUnload($event);
		}
	}

	public canDeactivate(): Promise<boolean> {
		return this.checkForUnsavedChanges();
	}

}
