import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { UserModel, UserPreferencesModel, ValueInterface } from '@thomas-duke-co/reis-shared';
import { UserService } from '../../../../service/user.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { UnsavedChangesDialogComponent } from '../../../../component/unsaved-changes-dialog/unsaved-changes-dialog.component';
import { CanComponentDeactivate } from '../../../../guard/can-deactivate.guard';
import { FormBuilderService } from '../../../property/service/form-builder.service';
import { ValuesService } from '../../../../service/values.service';
import { ValuesModel } from '@thomas-duke-co/reis-shared';

@Component({
	templateUrl: './preferences.page.pug',
	styleUrls: ['./preferences.page.scss'],
})
export class PreferencesPage implements OnInit, CanComponentDeactivate {

	protected user: UserModel;
	protected fb: FormBuilder;

	public formGroup: FormGroup;
	public emailSignature: string;
	public dashboards: ValueInterface[];
	public hasAdminRights: boolean = false;

	constructor(
		protected userService: UserService,
		protected formBuilderService: FormBuilderService,
		protected toastrService: ToastrService,
		protected unsavedChangesDialog: MatDialog,
		protected valuesService: ValuesService,
	) {
		this.fb = formBuilderService.getFormBuilder();
	}

	public ngOnInit(): void {
		this.user = this.userService.getUser();
		this.createFormGroup();
		this.valuesService.getValues().subscribe((values: ValuesModel) => this.dashboards = values.dashboards);
		this.hasAdminRights = (this.userService.hasRole('Admin') || this.userService.hasRole('Secretary'));
	}

	public saveButtonClicked(): void {
		this.save().subscribe();
	}

	public emailSignatureFocused(): void {
		this.setEmailSignature(this.emailSignature);
	}

	public emailSignatureBlurred(): void {
		const control = this.formGroup.controls.emailSignature;
		if (this.emailSignature === control.value) {
			return;
		}

		control.setValue(this.emailSignature);
		control.markAsDirty();
	}

	public getDefaultEmailSignature(): void {
		this.userService.getDefaultEmailSignature().subscribe(signature => {
			this.setEmailSignature(signature);
		});
	}

	protected setEmailSignature(signature: string): void {
		this.emailSignature = signature;
		const control = this.formGroup.controls.emailSignature;
		control.setValue(this.emailSignature);
		control.markAsTouched();
	}

	protected save(): Observable<boolean> {
		return new Observable<boolean>(observer => {
			if (this.formGroup.invalid) {
				this.formBuilderService.touchAll(this.formGroup);
				this.toastrService.error('Please resolve errors and try again.');
				observer.next(false);
				observer.complete();
				return;
			}

			const preferences: UserPreferencesModel = new UserPreferencesModel(this.formGroup.value);
			this.userService.setPreferences(preferences).subscribe(() => {
				this.toastrService.success('Preferences saved.');
				this.formGroup.markAsPristine();
				observer.next(true);
				observer.complete();
			});
		});
	}

	protected createFormGroup(): void {
		if (!this.user.preferences) {
			this.user.preferences = new UserPreferencesModel();
		}

		this.emailSignature = this.user.preferences.emailSignature;

		this.formGroup = this.fb.group({
			propertySharingMessage: this.fb.control(this.user.preferences.propertySharingMessage, Validators.maxLength(4000)),
			propertiesPerPage: this.fb.control(this.user.preferences.propertiesPerPage, Validators.min(1)),
			prospectsPerPage: this.fb.control(this.user.preferences.prospectsPerPage, Validators.min(1)),
			shouldAddSignature: this.fb.control(this.user.preferences.shouldAddSignature),
			emailSignature: this.fb.control(this.user.preferences.emailSignature),
			shouldShowFollowups: this.fb.control(this.user.preferences.shouldShowFollowups),
			defaultProspectTab: this.fb.control(this.user.preferences.defaultProspectTab),
			defaultDashboard: this.fb.control(this.user.preferences.defaultDashboard),
		});
	}

	@HostListener('window:beforeunload', ['$event'])
	public onBeforeUnload($event: BeforeUnloadEvent): void {
		if (this.formGroup.dirty) {
			$event.returnValue = true;
		}
	}

	public canDeactivate(): Observable<boolean> {
		return new Observable<boolean>(observer => {
			if (!this.formGroup.dirty) {
				observer.next(true);
				observer.complete();
				return;
			}

			this.unsavedChangesDialog
				.open(UnsavedChangesDialogComponent, {width: '400px'})
				.afterClosed()
				.subscribe(choice => {
					if (choice === 'Cancel') {
						observer.next(false);
					} else if (choice === 'Save') {
						this.save().subscribe(() => {
							observer.next(true);
							observer.complete();
						});
						return;
					} else {
						observer.next(true);
					}
					observer.complete();
				});
		});
	}

}
