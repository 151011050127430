import { Component, OnInit } from '@angular/core';
import { ValueInterface } from '@thomas-duke-co/reis-shared';
import { MatDialogRef } from '@angular/material';
import { FormBuilder } from '@angular/forms';

@Component({
	templateUrl: './archive-data-dialog.component.pug',
	styleUrls: ['./archive-data-dialog.component.scss']
})

export class ArchiveDataDialogComponent implements OnInit {

	public dataToArchive: any[];
	public archiveOptions: ValueInterface[];
	public deleteOptions: ValueInterface[];

	constructor(
		protected dialogRef: MatDialogRef<ArchiveDataDialogComponent>,
		protected fb: FormBuilder,
	) {}

	public ngOnInit(): void {
		this.archiveOptions = this.getArchiveOptions();
		this.deleteOptions = this.getDeleteOptions();
	}

	public getArchiveOptions(): ValueInterface[] {
		return [
			{id: 'showns', name: 'Showns', selected: false},
			{id: 'mailings', name: 'Mailings', selected: false},
			{id: 'tasks', name: 'Tasks', selected: false},
		];
	}

	public getDeleteOptions(): ValueInterface[] {
		return [
			{id: 'closing', name: 'Closing', selected: false},
		];
	}

	public getSelection(): void {
		const archive = this.archiveOptions.filter(x => x.selected === true).map(x => x.id);
		const deleted = this.deleteOptions.filter(x => x.selected === true).map(x => x.id);
		this.dataToArchive = archive.concat(deleted);
	}

	public archive(): void {
		this.dialogRef.close(this.dataToArchive);
	}

}
