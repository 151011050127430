import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {LatLng} from '@agm/core';
import {PropertyModel} from '@thomas-duke-co/reis-shared';
import {PropertyService} from '../../service/property.service';
import {MediaChange, MediaObserver} from '@angular/flex-layout';
import {Marker, PropertyMapComponent} from '../../component/search-map/property-map/property-map.component';
import {PropertySearchMapComponent} from '../../component/search-map/property-search-map/property-search-map.component';
import {Subscription} from 'rxjs/Subscription';
import {NavigationService} from '../../../../service/navigation.service';
import {NavigationEnd, Router} from '@angular/router';

@Component({
	templateUrl: './search.page.pug',
	styleUrls: ['./search.page.scss'],
})
export class SearchPage implements OnDestroy, OnInit {

	@ViewChild('search', {static: false})
	private propertySearch: PropertySearchMapComponent;

	@ViewChild('map', {static: true})
	private propertyMap: PropertyMapComponent;

	public previewProperty: PropertyModel;
	public isExtraSmall: boolean;
	public showClosedListings: boolean = false;
	public groupMarkers: boolean;

	private storedZoom: number;
	private storedCenter: LatLng;
	private previousMarkers: Marker[];
	private previewSubscription: Subscription;
	private navigationSubscription: Subscription;

	constructor(private propertyService: PropertyService,
							private navigationService: NavigationService,
							private mediaObserver: MediaObserver,
							private router: Router) {
		this.mediaObserver.media$.subscribe((change: MediaChange) => {
			this.isExtraSmall = change.mqAlias === 'xs';
		});

		this.navigationSubscription = this.navigationService.getObservable().subscribe(() => {
			this.propertySearch.clearSearchResults();
			this.closePreview();
		});
	}

	public ngOnInit(): void {
		this.router.events.filter(event => event instanceof NavigationEnd).subscribe(() => {
			this.propertySearch.currentSearchPreset = null;
			this.propertySearch.totalItems = null;

			if (this.propertySearch.searchFilters && this.propertySearch.searchFilters.searchForm) {
				this.propertySearch.searchFilters.searchForm.reset();
			}
		});
	}

	public ngOnDestroy() {
		if (this.previewSubscription) {
			this.previewSubscription.unsubscribe();
			this.previewSubscription = null;
		}

		if (this.navigationSubscription) {
			this.navigationSubscription.unsubscribe();
			this.navigationSubscription = null;
		}
	}

	public onMapMove() {
		this.propertySearch.onMapMove();
	}

	public showPreview(id: number): void {
		if (this.previewSubscription) {
			this.previewSubscription.unsubscribe();
		}

		this.previewSubscription = this.propertyService.getDetail(id).subscribe((property: PropertyModel) => {
			this.previewProperty = property;
			const markers = [{
				id: property.id,
				name: property.details.name,
				latitude: property.location.latitude,
				longitude: property.location.longitude
			}];

			if (this.propertyMap !== undefined) {
				this.storedZoom = this.propertyMap.getZoom();
				this.storedCenter = this.propertyMap.getCenter();
				this.previousMarkers = this.propertyMap.getMarkers();
				this.propertyMap.setMarkers(markers, true);
				this.propertyMap.setZoom(17);
			}
		});
	}

	public toggleClosedListings(event: boolean): void {
		this.showClosedListings = event;
	}

	public closePreview() {
		if (this.previewSubscription) {
			this.previewSubscription.unsubscribe();
		}

		if (this.previewProperty) {
			this.previewProperty = null;
			if (this.propertyMap !== undefined) {
				this.propertyMap.setZoom(this.storedZoom);
				this.propertyMap.setCenter(this.storedCenter);
				this.propertyMap.setMarkers(this.previousMarkers);
			}
		}

	}

	public toggleGroupMarkers(event: boolean): void {
		this.groupMarkers = event;
	}
}
