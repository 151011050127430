import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { TableColumnInterface } from './interface/table-column.interface';
import { MatCheckboxChange, MatPaginator, MatSort } from '@angular/material';
import { RowClassInterface } from '../../interface/row-class.interface';
import { IdInterface } from '@thomas-duke-co/reis-shared';
import { SelectionModel } from '@angular/cdk/collections';
import { TableFunctionalityComponent } from '../table-functionality/table-functionality.component';

@Component({
	selector: 'app-table',
	templateUrl: './table.component.pug',
	styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, OnChanges, AfterViewInit {

	@Input() public bulkEdit: boolean;
	@Input() public selectAllExclusions: boolean;
	@Input() public selectAllMode: boolean;
	@Input() public dataSource: any;
	@Input() public columns: TableColumnInterface[];
	@Input() public clickable = false;
	@Input() public layout: string;
	@Input() public paginated = false;
	@Input() public printMode: boolean;
	@Input() public phoneLinks: {phone: string, label: string}[];

	@Input() protected maxHeight: number;
	@Input() protected rowHeight: number;
	@Input() protected headerHeight: number;
	@Input() protected totalItems?: number;
	@Input() protected rowClass: RowClassInterface;

	@Output() public rowEvent: EventEmitter<any> = new EventEmitter();
	@Output() public masterToggleEvent: EventEmitter<void> = new EventEmitter();
	@Output() public toggleRowEvent: EventEmitter<{event: MatCheckboxChange, item: IdInterface}> = new EventEmitter();
	@Output() public sortData: EventEmitter<MatSort> = new EventEmitter();

	@ViewChild(MatPaginator, {static: true})
	public paginator: MatPaginator;

	@ViewChild('table', {static: false})
	public tableFunctionality: TableFunctionalityComponent;

	public mobile: boolean = false;
	public sort: MatSort;
	public viewportHeight: number;
	public selection: SelectionModel<IdInterface>;

	public ngOnInit() {
		this.mobile = (window.innerWidth < 960); // sm
		this.setCDKVirtualScrollHeight();
	}

	public ngOnChanges(): void {
		this.setCDKVirtualScrollHeight();
	}

	public ngAfterViewInit(): void {
		if (this.tableFunctionality.sort) {
			this.sort = this.tableFunctionality.sort;
		}

		if (this.tableFunctionality.selection) {
			this.selection = this.tableFunctionality.selection;
		}
	}

	public setCDKVirtualScrollHeight(): void {
		if (this.totalItems) {
			this.viewportHeight = (this.totalItems * this.rowHeight) + this.headerHeight;
		} else {
			if (this.dataSource && this.dataSource.data) {
				this.viewportHeight = (this.dataSource.data.length * this.rowHeight) + this.headerHeight;
			}
		}

		if (this.viewportHeight > this.maxHeight) {
			this.viewportHeight = this.maxHeight;
		}
	}

	public emitRowEvent(event: any): void {
		this.rowEvent.emit(event);
	}

	public emitMasterToggleEvent(): void {
		this.masterToggleEvent.emit();
	}

	public emitToggleRowEvent(event: {event: MatCheckboxChange, item: IdInterface}): void {
		this.toggleRowEvent.emit(event);
	}

	public emitSortDataEvent(event: MatSort): void {
		this.sortData.emit(event);
	}

}
