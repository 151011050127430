import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TaskGroupTemplateModel, UserModel, UserListModel, TaskTemplateModel, ValueInterface } from '@thomas-duke-co/reis-shared';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material';
import { UserService } from '../../../../service/user.service';
import { ValuesService } from '../../../../service/values.service';
import { TaskService } from '../../../../service/task.service';
import { TaskTemplateDialogComponent } from '../../../../component/task-template-dialog/task-template-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { DeleteDialogComponent } from '../../../../component/deleteDialog/delete-dialog.component';
import { TaskTemplateListComponent } from '../../../../component/task-template-list/task-template-list.component';
import { UsersService } from '../../service/users.service';

@Component({
	templateUrl: './task-template-detail.page.pug',
	styleUrls: ['./task-template-detail.page.scss'],
})

export class TaskTemplateDetailPage implements OnInit {

	public createMode: boolean = false;
	public loading: boolean = false;
	public error: boolean = false;
	public totalItems: number = 0;
	public taskGroupFormGroup: FormGroup;
	public taskGroupTemplate: TaskGroupTemplateModel;
	public currentDate = new Date();
	public users: UserListModel[];
	public labelNames: ValueInterface[];

	@ViewChild(TaskTemplateListComponent, {static: true}) protected taskTemplateList: TaskTemplateListComponent;

	constructor(
		protected route: ActivatedRoute,
		protected fb: FormBuilder,
		protected valuesService: ValuesService,
		protected toastrService: ToastrService,
		protected userService: UserService,
		protected taskService: TaskService,
		protected deleteDialog: MatDialog,
		protected taskTemplateDialog: MatDialog,
		protected router: Router,
		protected usersService: UsersService,
	) {
	}

	public ngOnInit(): void {
		this.loading = true;

		combineLatest([this.usersService.getAllUsers('templateMode'), this.route.data])
			.subscribe(async ([users, resolve]) => {
				this.users = users.filter(user => user.active);
				this.addUsersToConvert();
				this.taskGroupTemplate = resolve.template;
				this.createTaskGroupFormGroup();
				this.loading = false;
			}, () => {
				this.toastrService.error(`There was a problem getting the Task Group Details`);
				this.error = true;
				this.loading = false;
			});

		this.taskService.getLabels().subscribe((result: ValueInterface[]) => {
			this.labelNames = result;
		});
	}

	public addUsersToConvert(): void {
		this.users.unshift({
				userId: null,
				username: null,
				roles: null,
				position: 'agent\'sAdmin',
				firstName: 'Agent\'s',
				middleInitial: null,
				lastName: 'Admin',
				initials: null,
				active: null,
				email: null,
			},
			{
				userId: null,
				username: null,
				roles: null,
				position: 'agent1',
				firstName: 'Agent',
				middleInitial: null,
				lastName: '1',
				initials: null,
				active: null,
				email: null,
			});
	}

	public getTaskGroupTemplate(): void {
		this.taskService.getTaskGroupTemplateById(this.taskGroupTemplate.id).subscribe((result: TaskGroupTemplateModel) => {
			this.taskGroupTemplate = result;
			this.createTaskGroupFormGroup();
		});
	}

	public createTaskGroupFormGroup(): void {
		if (!this.taskGroupTemplate) {
			this.taskGroupTemplate = new TaskGroupTemplateModel();
		}

		this.taskGroupFormGroup = this.fb.group({
			id: this.taskGroupTemplate.id,
			title: [this.taskGroupTemplate.title, [Validators.required]],
			description: this.taskGroupTemplate.description,
			createdBy: this.taskGroupTemplate.createdBy,
			createDatetime: this.taskGroupTemplate.createDatetime,
			applyOnCreate: this.taskGroupTemplate.applyOnCreate,
		});
	}

	public deleteTemplate(): void {
		this.deleteDialog.open(DeleteDialogComponent, {
			width: '400px',
			data: {
				text: 'this Task Group Template',
				type: 'Task Group Template',
			},
		}).afterClosed().subscribe((result) => {
			if (result === 'Delete') {
				this.taskService.deleteTaskGroupTemplate(this.taskGroupFormGroup.value.id).subscribe(() => {
					// noinspection JSIgnoredPromiseFromCall
					this.router.navigateByUrl('/admin/task/templates');
					this.toastrService.success(`Task Group Template Successfully Deleted`);
				}, (error: HttpErrorResponse) => {
					this.toastrService.error(error.error.message);
				});
			}
		}, (error: HttpErrorResponse) => {
			this.toastrService.error(error.error.message);
		});

	}

	public saveTemplate(): void {
		const taskGroup: TaskGroupTemplateModel = new TaskGroupTemplateModel(this.taskGroupFormGroup.value);
		if (taskGroup.id) {
			this.taskService.updateTaskGroupTemplate(taskGroup).subscribe((result) => {
				if (result) {
					this.toastrService.success(`This Task Group Template has successfully been updated`);
				}
			}, (error: HttpErrorResponse) => {
				this.toastrService.error(error.error.message);
			});
		} else {
			this.taskService.createTaskGroupTemplate(taskGroup).subscribe((result) => {
				if (result) {
					this.toastrService.success(`Task Group Successfully Created`);
					this.taskGroupTemplate.id = result;
					this.getTaskGroupTemplate();
				}
			}, (error: HttpErrorResponse) => {
				this.toastrService.error(error.error.message);
			});
		}
	}

	public addTaskTemplate(): void {
		this.createMode = true;
		this.taskTemplateDialog.open(TaskTemplateDialogComponent, {
			width: '1000px',
			data: {
				taskTemplate: new TaskTemplateModel({
					id: null,
					title: null,
					description: null,
					isPrivate: null,
					createdBy: null,
					createDatetime: null,
					rRuleSet: null,
					relativeStartDate: null,
					duration: null,
					defaultAssignee: new UserModel(),
					convertAssignee: null,
				}),
				createMode: this.createMode,
				users: this.users,
			},
		}).afterClosed().subscribe((result: {data: TaskTemplateModel, action: string}) => {
			if (!result) {
				return;
			}

			if (result.action === 'Save') {
				this.taskService.createTaskTemplate(result.data, this.taskGroupTemplate.id).subscribe((id: number) => {
					if (id) {
						this.toastrService.success(`Task Template Successfully Created`);
						this.getTaskGroupTemplate();
					}
				});
			}
		});
	}

}
