import { Component, Input, OnInit } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { FormGroup } from '@angular/forms';
import { ValuesModel } from '@thomas-duke-co/reis-shared';

@Component({
	selector: 'property-location',
	templateUrl: './location.component.pug',
	styleUrls: ['./location.component.scss'],
})

export class LocationComponent implements OnInit {

	public icon: object = {
		url: 'assets/TD-icon.png',
		scaledSize: {
			height: 40,
			width: 28
		}
	};

	@Input() public values: ValuesModel;
	@Input() public locationFormGroup: FormGroup;
	@Input() public createMode: boolean;
	@Input() public hasAdminRights = false;

	constructor(private mapsAPILoader: MapsAPILoader) {}

	public updateLocation(event: MouseEvent): void {
		this.locationFormGroup.patchValue({
			latitude: event.coords.lat.toFixed(6),
			longitude: event.coords.lng.toFixed(6),
			override: true
		});
	}

	public ngOnInit(): void {
		const {mapAddress, mapCity, mapZip} = this.locationFormGroup.controls;
		if (!this.locationFormGroup.value.override && mapZip.value) {
			this.getGoogleLatLong(mapAddress.value, mapCity.value, mapZip.value);
		}
	}

	public updateCoordsOnAddress(): void {
		if (!this.locationFormGroup.value.override) {
			const mapAddress = this.locationFormGroup.get('mapAddress').value ? this.locationFormGroup.get('mapAddress').value : '';
			const mapCity = this.locationFormGroup.get('mapCity').value ? this.locationFormGroup.get('mapCity').value : '';
			const mapZip = this.locationFormGroup.get('mapZip').value;

			if (mapZip) {
				this.getGoogleLatLong(mapAddress, mapCity, mapZip);
			}
		}
	}

	private getGoogleLatLong(mapAddress: string, mapCity: string, mapZip: string) {
		this.mapsAPILoader.load().then(() => {
			const geocoder = new window['google'].maps.Geocoder();
			geocoder.geocode({'address': `${mapAddress}, ${mapCity}, MI, ${mapZip}`}, (results, status) => {
				if (status === 'OK') {
					this.locationFormGroup.patchValue({
						latitude: +results[0].geometry.location.lat().toFixed(6),
						longitude: +results[0].geometry.location.lng().toFixed(6)
					});
				}
			});
		});
	}

	onToggleChange(): void {
		if (!this.locationFormGroup.get('override').value) {
			this.updateCoordsOnAddress();
		}
	}

}
