import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../shared.module';
import { NavigationService } from '../../service/navigation.service';
import { ProspectComponent } from './prospect.component';
import { ProspectPage } from './page/prospect/prospect.page';
import { ProspectResolve } from './resolve/prospect.resolve';
import { ProspectService } from './service/prospect.service';
import { ProspectSearchPage } from './page/prospect-search/prospect-search.page';
import { ProspectSearchService } from './service/prospect-search.service';
import { ProspectWantsComponent } from './component/prospect-page/prospect-wants/prospect-wants.component';
import { ProspectDetailsComponent } from './component/prospect-page/prospect-details/prospect-details.component';
import { ProspectHasComponent } from './component/prospect-page/prospect-has/prospect-has.component';
import { ProspectNavbarComponent } from './component/prospect-page/prospect-navbar/prospect-navbar.component';
import { ProspectRemarksComponent } from './component/prospect-page/prospect-remarks/prospect-remarks.component';
import { RemarkDialogComponent } from './component/prospect-page/remark-dialog/remark-dialog.component';
import { ProspectRemarkService } from './service/prospect-remark.service';
import { ProspectMiscComponent } from './component/prospect-page/prospect-misc/prospect-misc.component';
import { DeleteExplanationDialogComponent } from './component/delete-explanation-dialog/delete-explanation-dialog.component';
import { CategoryOptionsPipe } from '../../pipe/category-options.pipe';
import { WantDialogComponent } from './component/prospect-page/want-dialog/want-dialog.component';
import { HasDialogComponent } from './component/prospect-page/has-dialog/has-dialog.component';
import { CanDeactivateGuard } from '../../guard/can-deactivate.guard';
import { ProspectQuickLoaderComponent } from './component/prospect-quick-loader/prospect-quick-loader.component';
import { PastDueContactsPage } from './page/past-due-contacts/past-due-contacts.page';
import { NextContactsPage } from './page/next-contacts/next-contacts.page';
import { ProspectSelectionDialogComponent } from './component/prospect-selection-dialog/prospect-selection-dialog.component';
import { ProspectRestrictionDialogComponent } from './component/prospect-restriction-dialog/prospect-restriction-dialog.component';
import { UserService } from '../../service/user.service';
import { UserModel, UserPreferencesModel } from '@thomas-duke-co/reis-shared';
import { NavModel } from '../../model/nav.model';
import { SharePropertySearchComponent } from './page/share-property-search/share-property-search.component';
import { PropertyEmailEditorComponent } from './component/property-email-editor/property-email-editor.component';
import { ProspectCallsComponent } from './component/prospect-page/prospect-calls/prospect-calls.component';
import { ProspectHasService } from './service/prospect-has.service';
import { ProspectDuplicateDialogComponent } from './component/prospect-duplicate-dialog/prospect-duplicate-dialog.component';
import { ProspectCreateComponent } from './component/prospect-create/prospect-create.component';
import { ProspectEditComponent } from './component/prospect-edit/prospect-edit.component';
import { ProspectWantService } from './service/prospect-want.service';

const appRoutes: Routes = [
	{
		path: 'prospect',
		component: ProspectComponent,
		children: [
			{
				path: '',
				redirectTo: '/prospect/search',
				pathMatch: 'full',
			},
			{
				path: 'search',
				component: ProspectSearchPage,
			},
			{
				path: 'detail/:id',
				component: ProspectEditComponent,
				canDeactivate: [CanDeactivateGuard],
				resolve: {
					prospect: ProspectResolve,
				},
			},
			{
				path: 'create',
				component: ProspectCreateComponent,
				canDeactivate: [CanDeactivateGuard],
			},
			{
				path: 'next-contacts',
				component: NextContactsPage,
				canDeactivate: [CanDeactivateGuard],
			},
			{
				path: 'past-due-contacts',
				component: PastDueContactsPage,
				canDeactivate: [CanDeactivateGuard],
			},
			{
				path: 'share/:id',
				component: SharePropertySearchComponent,
			},
		],
	},
];

@NgModule({
	declarations: [
		ProspectComponent,
		ProspectPage,
		ProspectSearchPage,
		PastDueContactsPage,
		NextContactsPage,
		ProspectWantsComponent,
		ProspectDetailsComponent,
		ProspectMiscComponent,
		ProspectHasComponent,
		ProspectNavbarComponent,
		ProspectRemarksComponent,
		RemarkDialogComponent,
		WantDialogComponent,
		HasDialogComponent,
		ProspectQuickLoaderComponent,
		ProspectSelectionDialogComponent,
		ProspectRestrictionDialogComponent,
		ProspectDuplicateDialogComponent,
		DeleteExplanationDialogComponent,
		SharePropertySearchComponent,
		PropertyEmailEditorComponent,
		ProspectCallsComponent,
		ProspectCreateComponent,
		ProspectEditComponent,
	],
	entryComponents: [
		RemarkDialogComponent,
		WantDialogComponent,
		HasDialogComponent,
		ProspectSelectionDialogComponent,
		ProspectRestrictionDialogComponent,
		ProspectDuplicateDialogComponent,
		DeleteExplanationDialogComponent,
	],
	imports: [
		RouterModule.forChild(appRoutes),
		SharedModule.forRoot(),
	],
	providers: [
		ProspectResolve,
		ProspectService,
		ProspectRemarkService,
		ProspectSearchService,
		ProspectHasService,
		CategoryOptionsPipe,
		ProspectWantService,
	],
	exports: [
		ProspectCreateComponent,
		ProspectPage,
	],
})
export class ProspectModule {

	constructor(navigationService: NavigationService, userService: UserService) {
		let user: UserModel = userService.getUser();
		if (!user) {
			user = new UserModel();
		}

		if (!user.preferences) {
			user.preferences = new UserPreferencesModel();
		}

		userService.userUpdates.subscribe(newUser => this.setDefaultTab(navigationService, newUser));

		navigationService.addMainNavigation({
			label: 'Prospects',
			path: ['prospect', user.preferences.defaultProspectTab],
			pathToMatch: ['prospect'],
			ordinal: 2,
		});

		navigationService.addSubNavigation('/prospect', {
			label: 'Search',
			path: ['prospect', 'search'],
		});

		navigationService.addSubNavigation('/prospect', {
			label: 'Create',
			path: ['prospect', 'create'],
		});

		navigationService.addSubNavigation('/prospect', {
			label: 'Next Contacts',
			path: ['prospect', 'next-contacts'],
		});

		navigationService.addSubNavigation('/prospect', {
			label: 'Past Due Contacts',
			path: ['prospect', 'past-due-contacts'],
		});
	}

	protected setDefaultTab(navigationService: NavigationService, user: UserModel): void {
		if (!user.preferences) {
			user.preferences = new UserPreferencesModel();
		}

		const mainNav: NavModel[] = navigationService.getMainNavigation();
		mainNav.forEach(nav => {
			if (nav.label !== 'Prospects') {
				return;
			}

			nav.path = ['prospect', user.preferences.defaultProspectTab];
			nav.href = '/' + nav.path.join('/');
			navigationService.mainNavigationUpdated();
		});
	}

}
