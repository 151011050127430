import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { ProspectListModel } from '@thomas-duke-co/reis-shared';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ProspectSelectionEventInterface } from '../../interface/prospect-selection-event.interface';
import { ProspectSearchComponent } from '../prospect-search/prospect-search.component';

@Component({
	templateUrl: './prospect-search-dialog.component.pug',
	styleUrls: ['./prospect-search-dialog.component.scss'],
})
export class ProspectSearchDialogComponent implements AfterViewInit {

	@ViewChild('prospectSearch', {static: true}) protected prospectSearch: ProspectSearchComponent;

	public data: {
		multiple: boolean,
		advancedExpanded: boolean,
	};
	public disableAnimation: boolean = true;

	protected selectedProspects: ProspectListModel[] = [];

	constructor(public dialogRef: MatDialogRef<ProspectSearchDialogComponent>, @Inject(MAT_DIALOG_DATA) data) {
		this.data = data;
	}

	public ngAfterViewInit(): void {
		setTimeout(() => this.disableAnimation = false);
	}

	public close(): void {
		this.dialogRef.close(this.selectedProspects);
	}

	public prospectSelected(event: ProspectSelectionEventInterface) {
		if (!this.data.multiple) {
			this.dialogRef.close(event.prospect);
			return;
		}

		if (event.selected) {
			this.selectedProspects.push(event.prospect);
		} else {
			const index: number = this.selectedProspects.findIndex(p => p.id === event.prospect.id);
			if (index !== -1) {
				this.selectedProspects.splice(index, 1);
			}
		}
	}

}
