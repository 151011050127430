import { Component, Input } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { LeaseSpaceModel, ValueInterface } from '@thomas-duke-co/reis-shared';
import { DeleteDialogComponent } from '../../../../../component/deleteDialog/delete-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
	selector: 'property-lease-spaces',
	templateUrl: './lease-spaces.component.pug',
	styleUrls: ['./lease-spaces.component.scss']
})

export class LeaseSpacesComponent {

	constructor(private fb: FormBuilder, private deleteDialog: MatDialog) {}

	@Input() public units: ValueInterface[];
	@Input() public leaseTypes: ValueInterface[];
	@Input() protected leaseStatusValues: ValueInterface[];
	@Input() public leaseSpacesFormGroup: FormGroup;
	@Input() public addMode = false;
	@Input() public hasAdminRights = false;
	@Input() public createMode: boolean;

	public focusedSpace: number;

	public getLeaseSpaces(): FormGroup[] {
		const leaseSpaces: FormArray = this.leaseSpacesFormGroup.get('leaseSpaces') as FormArray;
		return <FormGroup[]>leaseSpaces.controls;
	}

	public addLease(event) {
		event.preventDefault();
		event.stopPropagation();

		const leaseSpace = new LeaseSpaceModel();
		const leaseSpaces: FormArray = this.leaseSpacesFormGroup.get('leaseSpaces') as FormArray;
		leaseSpaces.insert(0, this.fb.group({
			id: [leaseSpace.id],
			size: [leaseSpace.size, [Validators.pattern('^\\d*(\\.\\d{1,2})?$'), Validators.required]],
			sizeUnitId: [leaseSpace.sizeUnitId],
			minDivisible: [leaseSpace.minDivisible, [Validators.pattern('^\\d*(\\.\\d{1,2})?$'), Validators.required]],
			maxContiguous: [leaseSpace.maxContiguous, [Validators.pattern('^\\d*(\\.\\d{1,2})?$'), Validators.required]],
			leaseRate: [leaseSpace.leaseRate, [Validators.pattern('^\\d*(\\.\\d{1,2})?$'), Validators.required]],
			leaseRateType: [leaseSpace.leaseRateType, Validators.maxLength(200)],
			pricePerAmount: [leaseSpace.pricePerAmount],
			pricePerUnitId: [leaseSpace.pricePerUnitId],
			suiteOrBuilding: [leaseSpace.suiteOrBuilding, [Validators.maxLength(255), Validators.required]],
			floor: [leaseSpace.floor, Validators.maxLength(255)],
			leaseTypeId: [leaseSpace.leaseTypeId],
			statusId: [leaseSpace.statusId],
			notes: [leaseSpace.statusId, Validators.maxLength(255)]
		}));
		this.focusedSpace = leaseSpaces.length - 1;
	}

	public removeLease(index: number) {
		const leaseSpaces: FormArray = this.leaseSpacesFormGroup.get('leaseSpaces') as FormArray;
		const dialog = this.deleteDialog.open(DeleteDialogComponent, {
			width: '300px',
			data: {
				text: 'this lease space',
				type: 'Lease Space'
			}
		});

		dialog.afterClosed().subscribe(result => {
			if (result === 'Delete') {
				leaseSpaces.removeAt(index);
				this.leaseSpacesFormGroup.markAsDirty();
			}
		});
	}

	public saveSpaces() {
		if (this.leaseSpacesFormGroup.invalid) {
			this.touchAll(this.leaseSpacesFormGroup);
			return;
		}
		this.addMode = false;
	}

	public getSizeUnits(): ValueInterface[] {
		if (this.units == null) {
			return [];
		}

		return this.units.filter(unit => [1, 2, 3, 4, 5, 6].includes(<number>unit.id));
	}

	public getRateUnits(): ValueInterface[] {
		if (this.units == null) {
			return [];
		}

		return this.units.filter(unit => [8, 9, 10, 11].includes(<number>unit.id));
	}

	public calculatePricePerYear(formGroup: FormGroup): void {
		let pricePerAmount: number | undefined = LeaseSpaceModel.calculatePricePerAmount(formGroup.value);

		if (pricePerAmount != null) {
			pricePerAmount = +pricePerAmount.toFixed(2);
		}

		formGroup.controls.pricePerAmount.setValue(pricePerAmount);
	}

	private touchAll(formGroup: FormGroup | FormArray, func = 'markAsDirty', opts = {onlySelf: false}): void {
		Object.keys(formGroup.controls).forEach(key => {
			if (formGroup.controls[key] instanceof FormGroup || formGroup.controls[key] instanceof FormArray) {
				this.touchAll(formGroup.controls[key], func, opts);
			} else {
				formGroup.controls[key][func](opts);
			}
		});
	}
}
