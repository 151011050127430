import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { TableComponent } from '../../../../../component/table/table.component';
import { TableColumnInterface } from '../../../../../component/table/interface/table-column.interface';
import { IconService } from '../../../../../service/icon.service';
import { CallsByProspectModel } from '@thomas-duke-co/reis-shared';

@Component({
	selector: 'prospect-calls',
	styleUrls: ['./prospect-calls.component.scss'],
	templateUrl: './prospect-calls.component.pug',
})
export class ProspectCallsComponent implements OnInit {

	@ViewChild(MatPaginator, {static: true}) protected paginator: MatPaginator;

	@ViewChild(TableComponent, {static: true}) protected table: TableComponent;

	@Input('calls')
	set calls(calls) {
		this._calls = calls;
		this.ngOnInit();
	}

	get calls() {
		return this._calls;
	}

	public dataSource;
	public totalItems: number;
	public tableLoading: boolean = false;
	public displayedColumns: TableColumnInterface[] = [{
		allowHtml: true,
		columnDef: 'type',
		title: 'Type',
	}, {
		columnDef: 'date',
		pipe: 'datetime',
		title: 'Date',
	}, {
		columnDef: 'duration',
		pipe: 'duration',
		title: 'Duration',
	}, {
		columnDef: 'name',
		title: 'Name',
	}];

	protected _calls: any;

	constructor(protected iconService: IconService) {}

	public ngOnInit() {
		const calls = this.calls;
		this.dataSource = new MatTableDataSource(this.mapCallData(calls));
		this.dataSource.paginator = this.paginator;
		setTimeout(() => this.totalItems = this.paginator.pageSize);
	}

	protected mapCallData(calls: CallsByProspectModel[]) {
		const inboundCallIcon = this.iconService.getInboundCallIcon();
		const outboundCallIcon = this.iconService.getOutboundCallIcon();
		return calls.map(call => {
			if (call.type === 'INBOUND') {
				call.type = inboundCallIcon;
			} else if (call.type === 'OUTBOUND') {
				call.type = outboundCallIcon;
			}

			return call;
		});
	}

}
