import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { MailingModel, PaginationDto } from '@thomas-duke-co/reis-shared';

@Injectable()
export class MailingService {

	constructor(protected http: HttpClient) {}

	public findByPropertyId(propertyId: number, currentPage: number, pageSize: number, showArchived?: boolean): Observable<PaginationDto<MailingModel[]>> {
		let params = new HttpParams()
			.append('currentPage', `${currentPage}`)
			.append('pageSize', `${pageSize}`);

		if (showArchived) {
			params = params.append('showArchived', `${showArchived}`);
		}

		return this.http.get<PaginationDto<MailingModel[]>>(`${environment.apiUrl}/mailing/property/${propertyId}`, {params});
	}

	public findByProspectId(prospectId: number, currentPage: number, pageSize: number, showArchived?: boolean): Observable<PaginationDto<MailingModel[]>> {
		let params = new HttpParams()
			.append('currentPage', `${currentPage}`)
			.append('pageSize', `${pageSize}`);

		if (showArchived) {
			params = params.append('showArchived', `${showArchived}`);
		}

		return this.http.get<PaginationDto<MailingModel[]>>(`${environment.apiUrl}/mailing/prospect/${prospectId}`, {params});
	}

	public create(mailing: MailingModel): Observable<MailingModel> {
		return this.http.post<MailingModel>(`${environment.apiUrl}/mailing`, mailing);
	}

	public update(mailing: MailingModel): Observable<void> {
		return this.http.put<void>(`${environment.apiUrl}/mailing/${mailing.id}`, mailing);
	}

	public updateArchiveStatus(mailing: MailingModel): Observable<void> {
		return this.http.put<void>(`${environment.apiUrl}/mailing/update-archive/${mailing.id}`, mailing);
	}

	public delete(id: number): Observable<string> {
		return this.http.delete<string>(`${environment.apiUrl}/mailing/${id}`);
	}

}
