import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PropertyPhoto } from '@thomas-duke-co/reis-shared';
import { ToastrService } from 'ngx-toastr';
import { UploaderService } from '../../service/uploader.service';
import { Subscription } from 'rxjs/Rx';
import { Uploader } from '../../class/uploader.class';


@Component({
	selector: 'add-images',
	styleUrls: ['./add-images.component.scss'],
	templateUrl: './add-images.component.pug',
})

export class AddImagesComponent implements OnInit, OnDestroy {

	@ViewChild('inputFile', {static: true}) inputFile: ElementRef;

	@Output() photos: EventEmitter<any> = new EventEmitter<any>();
	@Output() loadingChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Input() loading = false;
	@Input() public hasAdminRights = false;
	@Input() public token: string = null;

	public uploader: Uploader;

	private subscription: Subscription = null;

	constructor(private sanitizer: DomSanitizer, private toastrService: ToastrService, private uploaderService: UploaderService) {}

	public ngOnInit() {
		this.uploader = this.uploaderService.createUploader(this.token);
		this.subscription = this.uploader.subscribe((data) => {
			const { event, value } = data;
			switch (event) {
				case Uploader.EVENT.SUCCESS_ITEM: {
					const {item, response, status, headers} = value;
					const newPhoto: PropertyPhoto = JSON.parse(response);
					this.photos.emit(newPhoto);
					break;
				}
				case Uploader.EVENT.ERROR_ITEM: {
					const {item, response, status, headers} = value;
					this.toastrService.error('Failed to upload image');
					break;
				}
				case Uploader.EVENT.AFTER_ADDING_FILE: {
					const {item} = value;
					item.withCredentials = false;
					this.loadingChange.emit(this.loading = true);
					break;
				}
				case Uploader.EVENT.COMPLETE_ALL:
					this.loadingChange.emit(this.loading = false);
					break;
			}
		});
	}

	public ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
			this.subscription = null;
		}
	}

	public selectFiles(): void {
		if (this.loading) {
			return;
		}
		this.inputFile.nativeElement.click();
	}

}
