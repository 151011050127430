import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { PaginationDto, ProspectWantsModel } from '@thomas-duke-co/reis-shared';

@Injectable()
export class ProspectWantService {
	constructor(protected http: HttpClient) {
	}

	public getWantsByProspectId(
		id: number,
		currentPage: number,
		pageSize: number,
	): Observable<PaginationDto<ProspectWantsModel>> {
		const params = new HttpParams()
			.append('currentPage', `${currentPage}`)
			.append('pageSize', `${pageSize}`);

		return this.http.get<PaginationDto<ProspectWantsModel>>(`${environment.apiUrl}/wants/${id}`, {params});
	}

	public createWant(want: ProspectWantsModel, prospectId: number): Observable<any> {
		return this.http.post(`${environment.apiUrl}/wants/${prospectId}/create`, want);
	}

	public deleteWant(prospectId: number, wantId: number): Observable<any> {
		return this.http.delete(`${environment.apiUrl}/wants/${wantId}/delete`);
	}

	public updateWant(want: ProspectWantsModel): Observable<any> {
		return this.http.put(`${environment.apiUrl}/wants/${want.id}/update`, want);
	}


}
