import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PropertyListModel, UserListModel, ValueInterface } from '@thomas-duke-co/reis-shared';
import { MatTableDataSource } from '@angular/material';
import { TableColumnInterface } from '../table/interface/table-column.interface';
import { IconService } from '../../service/icon.service';
import { PropertySelectionEventInterface } from '../../interface/property-selection-event.interface';
import { ValuesService } from '../../service/values.service';

@Component({
	selector: 'property-list',
	templateUrl: './property-list.component.pug',
	styleUrls: ['./property-list.component.scss'],
})
export class PropertyListComponent {

	protected _properties: PropertyListModel[];
	@Input() set properties(properties: PropertyListModel[]) {
		this._properties = properties;
		if (properties && this.cities) {
			this.setDataSource();
		} else {
			this.dataSource = undefined;
		}
	}

	get properties(): PropertyListModel[] {
		return this._properties;
	}

	protected _cities: ValueInterface[];
	@Input() set cities(cities: ValueInterface[]) {
		this._cities = cities;
		if (this.properties && !this.dataSource) {
			this.setDataSource();
		}
	}

	get cities(): ValueInterface[] {
		return this._cities;
	}

	@Input() protected match: boolean;
	@Input() protected agents: UserListModel[];
	@Input() protected dataTransformation: (property: PropertyListModel) => void;

	@Input() public rowHeight: number;

	@Output() protected propertySelect: EventEmitter<PropertySelectionEventInterface> = new EventEmitter();

	protected selectedProperties: any[] = [];

	public dataSource: MatTableDataSource<PropertyListModel>;
	public columns: TableColumnInterface[] = [
		{columnDef: 'name', title: 'Name'},
		{columnDef: 'city', title: 'City'},
		{columnDef: 'zipcode', title: 'Zip'},
		{columnDef: 'broker', title: 'Broker'},
		{columnDef: 'statusText', title: 'Status', allowHtml: true},
	];

	constructor(
		protected iconService: IconService,
		protected valuesService: ValuesService,
	) {
	}

	public getColumns(): TableColumnInterface[] {
		return this.columns;
	}

	public toggleProperty(property: PropertyListModel): void {
		const index: number = this.selectedProperties.findIndex(p => p.id === property.id);
		const selected: boolean = (index === -1);

		if (!selected) {
			this.selectedProperties.splice(index, 1);
		} else {
			this.selectedProperties.push(property);
		}

		this.propertySelect.emit({property: property, selected: selected});
	}

	protected setDataSource(): void {
		this.properties.forEach((property) => {
			let city;

			if (typeof property.mapCity === 'number') {
				city = this.cities.find(x => x.id === property.mapCity);
			}

			if (city) {
				(property as any).city = city.name;
			} else {
				(property as any).city = property.mapCity;
			}

			(property as any).broker = property.initials;

			(property as any).zipcode = property.mapZip;

			(property as any).statusText = this.iconService.getPropertyStatusIcon(property.status);

			if (this.dataTransformation) {
				this.dataTransformation(property);
			}

		});
		this.dataSource = new MatTableDataSource<PropertyListModel>(this.properties);
	}

}
