import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs/Observable';
import {UserModel, UserListModel} from '@thomas-duke-co/reis-shared';

@Injectable()
export class UsersService {
	constructor(protected http: HttpClient) {
	}

	getAllUsers(taskTemplateMode?: string): Observable<UserListModel[]> {
		const params = new HttpParams()
			.append('taskTemplateMode', taskTemplateMode);
		return this.http.get<UserListModel[]>(`${environment.apiUrl}/user`, { params });
	}

	getUser(id: string): Observable<UserModel> {
		return this.http.get<UserModel>(`${environment.apiUrl}/user/${id}`);
	}

	saveUser(user: UserModel): Observable<UserModel> {
		return this.http.put<UserModel>(`${environment.apiUrl}/user/${user.userId}`, user);
	}

	createUser(user: UserModel): Observable<UserModel> {
		return this.http.post<UserModel>(`${environment.apiUrl}/user`, user);
	}

	deleteUser(id: number): Observable<string> {
		return this.http.delete<string>(`${environment.apiUrl}/user/${id}`);
	}

	getBrokerListPdf(): Observable<ArrayBuffer> {
		return this.http.get(`${environment.apiUrl}/summary/brokerlistpdf`, {
			headers: {'Accept': 'application/pdf'},
			responseType: 'arraybuffer'
		});
	}

	getUserListPdf(): Observable<ArrayBuffer> {
		return this.http.get(`${environment.apiUrl}/summary/userlistpdf`, {
			headers: {'Accept': 'application/pdf'},
			responseType: 'arraybuffer'
		});
	}

	getPhoneListPdf(): Observable<ArrayBuffer> {
		return this.http.get(`${environment.apiUrl}/summary/phonelistpdf`, {
			headers: {'Accept': 'application/pdf'},
			responseType: 'arraybuffer'
		});
	}
}
