import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatExpansionPanel, MatInput } from '@angular/material';
import { PaginationDto, ProspectListModel, ProspectSearchDto, SavedSearchModel, UserListModel } from '@thomas-duke-co/reis-shared';
import { ProspectSelectionEventInterface } from '../../interface/prospect-selection-event.interface';
import { TableColumnInterface } from '../table/interface/table-column.interface';
import { ProspectSearchService } from '../../module/prospect/service/prospect-search.service';
import { ProspectSavedSearchComponent } from '../prospect-saved-search/prospect-saved-search.component';
import { ProspectAdvancedSearchComponent } from '../prospect-advanced-search/prospect-advanced-search.component';
import { StoredSearchInterface } from '../../interface/stored-search.interface';
import { Observable } from 'rxjs';
import { ProspectPaginationListComponent } from '../prospect-pagination-list/prospect-pagination-list.component';
import { ValuesModel } from '@thomas-duke-co/reis-shared';
import { UsersService } from '../../module/admin/service/users.service';
import { ValuesService } from '../../service/values.service';

@Component({
	selector: 'prospect-search',
	templateUrl: './prospect-search.component.pug',
	styleUrls: ['./prospect-search.component.scss'],
})
export class ProspectSearchComponent implements AfterViewInit {

	@Input() public multiple: boolean = false;
	@Input() public advancedExpanded: boolean = true;
	@Input() public dataTransformation: (prospect: ProspectListModel) => void;
	@Input() public keywordFocus: boolean = true;
	@Input() protected initialSearch: StoredSearchInterface<Partial<ProspectSearchDto>, ProspectListModel>;

	@Output() protected prospectSelect: EventEmitter<ProspectSelectionEventInterface> = new EventEmitter();
	@Output() protected search: EventEmitter<StoredSearchInterface<Partial<ProspectSearchDto>, ProspectListModel>> = new EventEmitter();
	@Output() protected toggleBulkEditEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() protected itemsSelected: EventEmitter<boolean> = new EventEmitter<boolean>();

	@ViewChild('keywordSearchExpansionPanel', {static: true}) protected keywordSearchExpansionPanel: MatExpansionPanel;
	@ViewChild('savedSearchExpansionPanel', {static: true}) protected savedSearchExpansionPanel: MatExpansionPanel;
	@ViewChild('advancedSearchExpansionPanel', {static: true}) protected advancedSearchExpansionPanel: MatExpansionPanel;
	@ViewChild('savedSearchComponent', {static: true}) protected savedSearchComponent: ProspectSavedSearchComponent;
	@ViewChild('advancedSearchComponent', {static: true}) protected advancedSearchComponent: ProspectAdvancedSearchComponent;
	@ViewChild('keywordInput', {static: true}) protected keywordInput: MatInput;
	@ViewChild('prospectPaginationList', {static: true}) protected prospectPaginationListComponent: ProspectPaginationListComponent;
	@ViewChild('keywordSearchInput', {static: true}) public keywordSearchInput: ElementRef;

	public showInactive: boolean = false;
	public loading: boolean = false;
	public currentSavedSearch: SavedSearchModel<Partial<ProspectSearchDto>>;
	public valuesModel: ValuesModel;
	public agents: UserListModel[];

	protected searchDto: Partial<ProspectSearchDto>;

	constructor(
		protected prospectSearchService: ProspectSearchService,
		protected usersService: UsersService,
		protected valuesService: ValuesService,
		protected changeDetectionRef: ChangeDetectorRef
	) {
		usersService.getAllUsers().subscribe((users: UserListModel[]) => {
			this.agents = users;
		});
	}

	public ngAfterViewInit(): void {
		this.valuesService.getValues().subscribe((values: ValuesModel) => {
			this.valuesModel = values;
			this.restoreSearchIfReady();
		});
		if (this.keywordFocus) {
			this.keywordSearchExpansionPanel.open();
			setTimeout(() => this.keywordSearchInput.nativeElement.focus(), 500);
		}
	}

	public focusOnKeywordInput(): void {
		if (this.keywordFocus) {
			this.keywordSearchInput.nativeElement.focus();
		}
	}

	public focusOnFirstNameInput(): void {
		this.advancedSearchComponent.firstNameInput.nativeElement.focus();
	}

	public advancedSearchReset(): void {
		this.keywordSearchExpansionPanel.open();
		setTimeout(() => this.keywordSearchInput.nativeElement.focus(), 500);
	}

	public topResetFiltersBtn(event): void {
		if (event) {
			event.stopPropagation();
			event.preventDefault();
		}
		this.advancedSearchComponent.resetFilters();
	}

	public keywordSearch(keywords: string): void {
		this.currentSavedSearch = undefined;
		this.searchDto = {
			keywords,
			details: {
				statuses: this.getStatusFilter(),
			},
		};
		this.doSearch();
	}

	public advancedSearch(searchDto: ProspectSearchDto): void {
		this.searchDto = searchDto;
		this.doSearch();
	}

	public runSavedSearch(savedSearch: SavedSearchModel<ProspectSearchDto>): void {
		this.loadSavedSearch(savedSearch);
		this.searchDto = savedSearch.filters;
		this.doSearch();
	}

	public clearSearchResults(): void {
		this.keywordInput.value = '';
		this.advancedSearchComponent.resetFilters();
		this.currentSavedSearch = undefined;
		this.initialSearch = undefined;
		this.searchDto = undefined;
		this.prospectPaginationListComponent.clear();
		this.expandDefaultPanels();
		setTimeout(() => this.keywordInput.focus(), 200); // Angular 7 will have an 'afterExpanded' event that should be used instead of this.
	}

	public toggleInactiveSearch(): void {
		this.showInactive = !this.showInactive;

		if (!this.searchDto) {
			return;
		}

		this.searchDto.details.statuses = this.getStatusFilter();
		this.doSearch();
	}

	public searchSaved(): void {
		// noinspection JSIgnoredPromiseFromCall
		this.savedSearchComponent.getSavedSearches();
		this.closeExpansionPanels(this.savedSearchExpansionPanel);
	}

	public editSavedSearchClicked(savedSearch: SavedSearchModel<ProspectSearchDto>): void {
		this.loadSavedSearch(savedSearch);
		this.closeExpansionPanels(this.advancedSearchExpansionPanel);
	}

	public getPreviouslySelectedId(): number {
		if (!this.initialSearch) {
			return;
		}

		return this.initialSearch.previouslySelectedId;
	}

	public prospectSelected(event: ProspectSelectionEventInterface): void {
		this.prospectSelect.emit(event);
	}

	public toggleBulkEdit(event: boolean): void {
		this.toggleBulkEditEvent.emit(event);
	}

	public itemsSelectedEvent(event: boolean): void {
		this.itemsSelected.emit(event);
	}

	public getResultsColumns(): TableColumnInterface[] {
		return this.prospectPaginationListComponent.getColumns();
	}

	public searchFunction = (pageIndex: number, pageSize: number, sortColumn: string, sortDirection: string): void | Observable<PaginationDto<ProspectListModel>> => {
		if (!this.searchDto) {
			return;
		}

		this.loading = true;
		this.changeDetectionRef.detectChanges();
		return this.prospectSearchService.search(this.searchDto, pageIndex, pageSize, sortColumn, sortDirection)
			.finally(() => {
				this.loading = false;
				const element: HTMLElement = document.getElementById('prospectPaginationList');
				if (element) {
					element.scrollIntoView({behavior: 'smooth'});
				}
			});
	};

	public searched(results: PaginationDto<ProspectListModel>): void {
		this.search.emit({dto: this.searchDto, results, previouslySelectedId: this.getPreviouslySelectedId()});
	}

	public toggleBulkEditMode(event: MouseEvent, currentSearch: ProspectSearchDto): void {
		this.prospectPaginationListComponent.toggleBulkEditMode(event);
		this.prospectPaginationListComponent.setCurrentSearch(currentSearch);
	}

	public editSelectedRows(event: MouseEvent): void {
		this.prospectPaginationListComponent.editSelectedRows(event);
	}

	protected doSearch(): void {
		this.closeExpansionPanels();
		this.prospectPaginationListComponent.doSearch();
	}

	protected getStatusFilter(): string[] {
		if (this.showInactive) {
			return ['ACTIVE', 'DELETED'];
		}
		return ['ACTIVE'];
	}

	protected closeExpansionPanels(except?: MatExpansionPanel): void {
		const expansionPanels = [
			this.keywordSearchExpansionPanel,
			this.savedSearchExpansionPanel,
			this.advancedSearchExpansionPanel,
		];

		expansionPanels.forEach(panel => {
			if (panel === except) {
				panel.open();
			} else {
				panel.close();
			}
		});
	}

	protected expandDefaultPanels(): void {
		this.keywordSearchExpansionPanel.open();
		this.savedSearchExpansionPanel.close();
		this.advancedSearchExpansionPanel.open();
	}

	protected loadSavedSearch(savedSearch: SavedSearchModel<ProspectSearchDto>): void {
		this.currentSavedSearch = savedSearch;
		this.setAdvancedSearch(savedSearch.filters);
	}

	protected setAdvancedSearch(searchDto: Partial<ProspectSearchDto>): void {
		this.advancedSearchComponent.prospectSearchFormGroup.reset();
		this.advancedSearchComponent.prospectSearchFormGroup.patchValue(Object.assign({}, searchDto));
	}

	protected restoreSearchIfReady(): void {
		if (!this.initialSearch || !this.valuesModel || !this.keywordSearchExpansionPanel) {
			return;
		}

		this.searchDto = this.initialSearch.dto;
		this.showInactive = this.searchDto.details.statuses.includes('DELETED');

		if (this.searchDto.keywords) {
			this.keywordInput.value = this.searchDto.keywords;
		} else {
			this.setAdvancedSearch(this.searchDto);
		}

		this.closeExpansionPanels();
		this.prospectPaginationListComponent.setSearchResults(this.initialSearch.results);
	}

}
