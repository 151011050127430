import { Component, OnInit } from '@angular/core';
import { UserModel, ValueInterface, ValuesModel } from '@thomas-duke-co/reis-shared';
import { UserService } from '../../../../service/user.service';
import { ValuesService } from '../../../../service/values.service';
import { MatSelectChange } from '@angular/material';
import { FormControl, FormGroup } from '@angular/forms';
import { TaskService } from '../../../../service/task.service';

@Component({
	templateUrl: './dashboard.page.pug',
	styleUrls: ['./dashboard.page.scss'],
})
export class DashboardPage implements OnInit {

	public user: UserModel;
	public dashboards: ValueInterface[];
	public defaultDashboard: number = 2;
	public formGroup: FormGroup = new FormGroup({
		dashboard: new FormControl(''),
	});
	public hasAdminRights: boolean = false;
	public labelNames: ValueInterface[];

	constructor(
		protected userService: UserService,
		protected valuesService: ValuesService,
		protected taskService: TaskService,
	) {
		this.user = this.userService.getUser();
		this.valuesService.getValues().subscribe((values: ValuesModel) => this.dashboards = values.dashboards);
	}

	public ngOnInit(): void {
		if (this.user.preferences.defaultDashboard) {
			this.defaultDashboard = this.user.preferences.defaultDashboard;
		}
		this.hasAdminRights = (this.userService.hasRole('Admin') || this.userService.hasRole('Secretary'));
		this.formGroup.controls['dashboard'].setValue(this.defaultDashboard);

		this.taskService.getLabels().subscribe((result) => {
			this.labelNames = result;
		});
	}

	public toggleDashboard(event: MatSelectChange): void {
		this.defaultDashboard = event.value;
	}

}
