import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PaginationDto, ShownModel } from '@thomas-duke-co/reis-shared';
import { environment } from '../../environments/environment';

@Injectable()
export class ShownService {

	constructor(protected http: HttpClient) {}

	public findByProspectId(prospectId: number, currentPage: number, pageSize: number, showArchived: boolean): Observable<PaginationDto<ShownModel[]>> {
		let params = new HttpParams()
			.append('currentPage', `${currentPage}`)
			.append('pageSize', `${pageSize}`);

		if (showArchived) {
			params = params.append('showArchived', `${showArchived}`);
		}

		return this.http.get<PaginationDto<ShownModel[]>>(`${environment.apiUrl}/shown/prospect/${prospectId}`, {params});
	}

	public findByPropertyId(propertyId: number, currentPage: number, pageSize: number, showArchived: boolean): Observable<PaginationDto<ShownModel[]>> {
		let params = new HttpParams()
			.append('currentPage', `${currentPage}`)
			.append('pageSize', `${pageSize}`);

		if (showArchived) {
			params = params.append('showArchived', `${showArchived}`);
		}

		return this.http.get<PaginationDto<ShownModel[]>>(`${environment.apiUrl}/shown/property/${propertyId}`, {params});
	}

	public create(shown: ShownModel): Observable<ShownModel> {
		return this.http.post<ShownModel>(`${environment.apiUrl}/shown`, shown);
	}

	public update(shown: ShownModel): Observable<void> {
		return this.http.put<void>(`${environment.apiUrl}/shown/${shown.id}`, shown);
	}

	public updateArchiveStatus(shown: ShownModel): Observable<void> {
		return this.http.put<void>(`${environment.apiUrl}/shown/update-archive/${shown.id}`, shown);
	}

	public delete(ids: number | number[]): Observable<void> {
		if (!Array.isArray(ids)) {
			ids = [ids];
		}

		return this.http.delete<void>(`${environment.apiUrl}/shown/${ids.join(',')}`);
	}

	public bulkDelete(ids: number | number[], prospectId?: number, propertyId?: number): Observable<void> {
		const params = new HttpParams()
			.append('prospectId', `${prospectId}`)
			.append('propertyId', `${propertyId}`);

		if (!Array.isArray(ids)) {
			ids = [ids];
		}

		return this.http.delete<void>(`${environment.apiUrl}/shown/bulk/${ids.join(',')}`, {params});
	}

}
