import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';
import { TaskGroupModel, TaskGroupTemplateModel, TaskGroupPropertyModel, TaskGroupProspectModel } from '@thomas-duke-co/reis-shared';
import { UserService } from '../../service/user.service';
import { TaskService } from '../../service/task.service';

@Component({
	templateUrl: './task-group-dialog.component.pug',
	styleUrls: ['./task-group-dialog.component.scss'],
})

export class TaskGroupDialogComponent implements OnInit {

	public data: {
		propertyId: number,
		prospectId: number,
		taskGroup: TaskGroupModel;
		action: string,
		hasEditRights: boolean,
		hasAdminRights: boolean,
	};
	public taskGroupFormGroup: FormGroup;
	public templates: TaskGroupTemplateModel[];
	public createMode: boolean = false;

	constructor(
		protected dialogRef: MatDialogRef<TaskGroupDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data,
		protected fb: FormBuilder,
		protected userService: UserService,
		protected taskService: TaskService,
	) {
		this.data = data;
	}

	public ngOnInit(): void {
		this.createFormGroup();
		if (this.data.action === 'Add') {
			this.createMode = true;
		}
		this.taskService.getAllTaskGroupTemplates().subscribe((result: TaskGroupTemplateModel[]) => this.templates = result);
	}

	public createFormGroup(): void {
		this.taskGroupFormGroup = this.fb.group({
			id: this.data.taskGroup.id,
			title: {value: this.data.taskGroup.title, disabled: this.getPermissions()},
			description: {value: this.data.taskGroup.description, disabled: this.getPermissions()},
			createDatetime: this.data.taskGroup.createDatetime,
			createdBy: this.data.taskGroup.createdBy,
			template: [],
		});
	}

	public saveTaskGroup(): void {
		const templateId: number = this.taskGroupFormGroup.get('template').value;
		const taskGroup = new TaskGroupModel(this.taskGroupFormGroup.value);

		if (this.data.propertyId) {
			const propertyId = new TaskGroupPropertyModel({
				propertyId: this.data.propertyId,
			});
			const newTaskPropertyGroup = Object.assign(propertyId, taskGroup);
			this.dialogRef.close({group: newTaskPropertyGroup, templateId: templateId, action: this.data.action});
		} else if (this.data.prospectId) {
			const prospectId = new TaskGroupProspectModel({
				prospectId: this.data.prospectId,
			});
			const newTaskProspectGroup = Object.assign(prospectId, taskGroup);
			this.dialogRef.close({group: newTaskProspectGroup, templateId: templateId, action: this.data.action});
		} else {
			this.dialogRef.close({group: taskGroup, templateId: templateId, action: this.data.action});
		}
	}

	public getPermissions(): boolean {
		if (this.data.hasAdminRights) {
			return false;
		} else {
			return !this.data.hasEditRights;
		}
	}

	public deleteTaskGroup(): void {
		this.dialogRef.close({group: this.data.taskGroup, templateId: null, action: 'Delete'});
	}

}
