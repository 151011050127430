import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { MailingModel, UserModel, ValueInterface, ValuesModel } from '@thomas-duke-co/reis-shared';
import { DeleteDialogComponent } from '../deleteDialog/delete-dialog.component';
import { ValuesService } from '../../service/values.service';
import { UsersService } from '../../module/admin/service/users.service';

@Component({
	templateUrl: './mailing-dialog.component.pug',
	styleUrls: ['./mailing-dialog.component.scss'],
})
export class MailingDialogComponent {

	public data: {
		mailing: MailingModel,
		action: string,
		mailingTypes: ValueInterface[],
		canEdit: boolean,
	};
	public propertyNames: string;
	public agents: ValueInterface[];
	public currentDate = new Date();
	public loading: boolean = false;

	constructor(protected dialogRef: MatDialogRef<MailingDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data,
		protected deleteDialog: MatDialog,
		protected valueService: ValuesService,
		protected usersService: UsersService) {
		this.data = data;
		this.loading = true;
		if (this.data.mailing.properties && this.data.mailing.properties.length > 0) {
			this.propertyNames = this.data.mailing.properties.map(property => property.name).join('\n');
		}
		if (this.data.mailing.prospects && this.data.mailing.prospects.length > 0 && !this.data.mailing.count) {
			this.data.mailing.count = this.data.mailing.prospects.length;
		}

		valueService.getValues().subscribe((values: ValuesModel) => {
			this.agents = values.activeAgent;
			this.findInactiveAgents();
		});
	}

	public findInactiveAgents(): void {
		const agent1: number = this.data.mailing.agent1;
		const agent2: number = this.data.mailing.agent2;

		if (agent1 && !this.agentInValues(agent1)) {
			this.usersService.getUser(agent1.toString()).subscribe((user: UserModel) => {
				this.agents.push({id: agent1, name: `${user.firstName} ${user.lastName} (INACTIVE)`});
			});
		}

		if (agent2 && !this.agentInValues(agent2)) {
			this.usersService.getUser(agent2.toString()).subscribe((user: UserModel) => {
				this.agents.push({id: agent2, name: `${user.firstName} ${user.lastName} (INACTIVE)`});
			});
		}

		this.loading = false;
	}

	public confirmDelete(): void {
		const dialog = this.deleteDialog.open(DeleteDialogComponent, {
			width: '300px',
			data: {
				text: 'this mailing record',
				type: 'Mailing Record',
			},
		});

		dialog.afterClosed().subscribe(result => {
			if (result === 'Delete') {
				this.dialogRef.close('Delete');
			}
		});
	}

	public toggleArchiveStatus(): void {
		this.dialogRef.close('Toggle');
	}

	public shouldShowDeleteButton(): boolean {
		return this.data.action === 'Edit' && this.data.canEdit;
	}

	protected agentInValues(id: number): boolean {
		return !!this.agents.find((agent: ValueInterface) => agent.id === id);
	}

}
