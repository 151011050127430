import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ValueInterface} from '@thomas-duke-co/reis-shared';
import {ValuesService} from '../../../../service/values.service';
import {combineLatest} from 'rxjs/observable/combineLatest';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material';
import {CategoryModel} from '@thomas-duke-co/reis-shared/dist/model/category.model';
import {CodesService} from '../../service/codes.service';
import {HttpErrorResponse} from '@angular/common/http';
import {DeleteDialogComponent} from '../../../../component/deleteDialog/delete-dialog.component';
import {RouterService} from '../../../../service/router.service';

@Component({
	templateUrl: './category-detail.page.pug',
	styleUrls: ['./category-detail.page.scss'],
})

export class CategoryDetailPage implements OnInit {

	public loading = true;
	public category: CategoryModel;
	public categoryFormGroup: FormGroup;
	public units: ValueInterface[];
	public createMode = false;
	public error = false;

	constructor(private route: ActivatedRoute,
	            private fb: FormBuilder,
	            private toastrService: ToastrService,
	            private valuesService: ValuesService,
							private codesService: CodesService,
							private deleteDialog: MatDialog,
							private routerService: RouterService) {
	}

	public ngOnInit(): void {
		this.loading = true;
		combineLatest([this.valuesService.getValues(), this.route.data])
			.subscribe(([values, resolve]) => {
				this.units = values.sizeUnit;
				this.category = resolve.category;
				this.createFormGroup();
				this.loading = false;
			}, () => {
				this.toastrService.error(`There was a problem getting the Category Details`);
				this.error = true;
				this.loading = false;
			});
	}

	public getNewData() {
		this.codesService.getCategoryById(this.category.id)
			.subscribe((result: CategoryModel) => {
				this.category = result;
				this.createFormGroup();
			});
	}

	private createFormGroup() {
		if (!this.category) {
			this.category = new CategoryModel();
			this.createMode = true;
		}
		this.categoryFormGroup = this.fb.group({
			assignedNumber: [this.category.assignedNumber, [Validators.required, Validators.maxLength(2)]],
			name: [this.category.name, [Validators.required, Validators.maxLength(30)]],
			isUPFS: this.category.isUPFS,
			isUPFL: this.category.isUPFL,
			isIPFS: this.category.isIPFS,
			isMH: this.category.isMH,
			isVL: this.category.isVL,
			unitID: this.category.unitID,
			id: this.category.id
		});
	}

	public saveCategory() {
		if (this.categoryFormGroup.valid) {
			this.loading = true;
			const cat = this.categoryFormGroup.value;
			if (cat.id) {
				this.codesService.saveCategory(cat)
					.subscribe((result) => {
						this.toastrService.success(`${cat.name} saved.`);
						this.loading = false;
						this.getNewData();
					}, (error) => {
						this.toastrService.error(error);
						this.loading = false;
					});
			} else {
				this.codesService.createCategory(cat)
					.subscribe((result) => {
						this.toastrService.success(`${cat.name} created.`);
						this.loading = false;
						this.routerService.navigate([`/admin/category/detail/${result[0]}`]);

					}, (error) => {
						this.toastrService.error(error);
						this.loading = false;
					});
			}
		}
	}

	public deleteCategory() {
		const deleteDialog = this.deleteDialog.open(DeleteDialogComponent, {
			width: '400px',
			data: {
				text: this.category.name,
				type: 'Category'
			}
		});
		deleteDialog.afterClosed().subscribe((result) => {
			if (result === 'Delete') {
				this.loading = true;
				this.codesService.deleteCategory(this.category.id)
					.subscribe(() => {
							this.toastrService.success(`${this.category.name} has been deleted.`);
							this.routerService.navigate([`/admin/category`]);
							this.loading = false;
						},
						(error: HttpErrorResponse) => {
							this.toastrService.error(error.error.message);
							this.loading = false;
						}
					);
			}
		});
	}

}
