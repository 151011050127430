import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatExpansionPanel, MatPaginator, MatTableDataSource } from '@angular/material';
import { PaginationDto, PropertyListModel, PropertySearchDto } from '@thomas-duke-co/reis-shared';
import { TableColumnInterface } from '../table/interface/table-column.interface';
import { IconService } from '../../service/icon.service';
import { SearchService } from '../../module/property/service/search.service';
import { PropertySelectionEventInterface } from '../../interface/property-selection-event.interface';
import { PropertyAdvancedSearchComponent } from '../property-advanced-search/property-advanced-search.component';

@Component({
	selector: 'property-search',
	templateUrl: './property-search.component.pug',
	styleUrls: ['./property-search.component.scss'],
})
export class PropertySearchComponent implements OnInit, AfterViewInit {

	@Input() public multiple: boolean = false;

	@Output() protected select: EventEmitter<PropertySelectionEventInterface> = new EventEmitter();
	@Output() protected search: EventEmitter<Partial<PropertySearchDto>> = new EventEmitter();

	@ViewChild('keywordSearchExpansionPanel', {static: true}) protected keywordSearchExpansionPanel: MatExpansionPanel;
	@ViewChild('advancedSearchExpansionPanel', {static: true}) protected advancedSearchExpansionPanel: MatExpansionPanel;
	@ViewChild('advancedPropertySearch', {static: false}) protected advancedPropertySearch: PropertyAdvancedSearchComponent;
	@ViewChild('keywordSearchInput', {static: false}) protected keywordSearchInput: ElementRef;
	@ViewChild(MatPaginator, {static: true}) protected paginator: MatPaginator;

	public showInactive: boolean = false;
	public dataSource: MatTableDataSource<PropertyListModel>;
	public displayedColumns: TableColumnInterface[] = [
		{columnDef: 'name', title: 'Name'},
		{columnDef: 'mapCity', title: 'City'},
		{columnDef: 'mapZip', title: 'Zip'},
		{columnDef: 'initials', title: 'Broker'},
		{columnDef: 'statusText', title: 'Status', allowHtml: true},
	];
	public loading: boolean = false;
	public totalItems: number = 0;

	protected selectedProperties: PropertyListModel[] = [];
	protected searchDto: Partial<PropertySearchDto>;
	protected data: PropertyListModel[];

	constructor(protected propertySearchService: SearchService, protected iconService: IconService) {}

	public ngOnInit(): void {
		if (this.multiple) {
			this.displayedColumns.unshift({columnDef: 'selected', title: '', allowHtml: true});
		}
	}

	public ngAfterViewInit(): void {
		this.paginator.page.subscribe(event => {
			this.totalItems = event.length;
			this.getProperties();
		});

		setTimeout(() => this.focusOnKeywordInput(), 350);
	}

	public focusOnKeywordInput(): void {
		this.keywordSearchInput.nativeElement.focus();
	}

	public focusOnNameInput(): void {
		this.advancedPropertySearch.nameInput.nativeElement.focus();
	}

	public keywordSearch(keywords: string): void {
		this.paginator.pageIndex = 0;
		this.searchDto = {
			keywords,
			statusId: this.getStatusIdFilter(),
		};
		this.getProperties();
	}

	public advancedSearch(searchDto: PropertySearchDto): void {
		this.paginator.pageIndex = 0;
		this.searchDto = searchDto;
		this.getProperties();
	}

	public toggleInactiveSearch(): void {
		this.showInactive = !this.showInactive;

		if (!this.searchDto) {
			return;
		}

		this.searchDto.statusId = this.getStatusIdFilter();
		this.paginator.pageIndex = 0;
		this.getProperties();
	}

	public isSelected(propertyId: number): boolean {
		return !!this.selectedProperties.find(property => property.id === propertyId);
	}

	public toggleProperty(property: PropertyListModel): void {
		const index: number = this.selectedProperties.findIndex(p => p.id === property.id);
		const selected: boolean = (index === -1);

		if (!selected) {
			this.selectedProperties.splice(index, 1);
		} else {
			this.selectedProperties.push(property);
		}

		this.setDataSource();
		this.select.emit({property, selected});
	}

	protected getProperties(): void {
		if (!this.searchDto) {
			return;
		}

		this.loading = true;
		this.propertySearchService.search(this.searchDto, this.paginator.pageIndex, this.paginator.pageSize)
			.finally(() => {
				this.loading = false;
			})
			.subscribe(this.setSearchResults.bind(this));
	}

	protected setSearchResults(results: PaginationDto<PropertyListModel>): void {
		this.closeExpansionPanels();
		this.data = results.data;
		this.setDataSource();
		this.totalItems = results.totalItems;
		this.search.emit(this.searchDto);
	}

	protected setDataSource(): void {
		const checkmarkHtml: string = this.iconService.getCheckmarkIcon();
		this.data.forEach(property => {
			(property as any).selected = this.isSelected(property.id) ? checkmarkHtml : '';
			(property as any).statusText = this.iconService.getPropertyStatusIcon(property.status);
		});
		this.dataSource = new MatTableDataSource<PropertyListModel>(this.data);
	}

	protected getStatusIdFilter(): number[] {
		if (this.showInactive) {
			return [1, 2, 3, 4, 5, 6];
		}
		return SearchService.DEFAULT_STATUSES;
	}

	protected closeExpansionPanels(except?: MatExpansionPanel): void {
		const expansionPanels = [
			this.keywordSearchExpansionPanel,
			this.advancedSearchExpansionPanel,
		];

		expansionPanels.forEach(panel => {
			if (panel === except) {
				panel.open();
			} else {
				panel.close();
			}
		});
	}

}
