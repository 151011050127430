import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {forkJoin} from 'rxjs/observable/forkJoin';
import {debounce} from 'rxjs/operators';
import {timer} from 'rxjs/observable/timer';
import {UserListModel} from '@thomas-duke-co/reis-shared';
import {Subscription} from 'rxjs/Subscription';
import {MatSelect, MatTableDataSource} from '@angular/material';
import {SummaryService} from '../../service/summary.service';
import {UsersService} from '../../../admin/service/users.service';
import {DataSource} from '@angular/cdk/collections';
import * as moment from 'moment';

@Component({
	templateUrl: './prospect-progress.page.pug',
	styleUrls: ['./prospect-progress.page.scss'],
})
export class ProspectProgressPage implements OnInit, OnDestroy {

	public loading = false;
	public progress: any;
	public communicationSummary: CommunicationSummaryInterface[];
	public users: UserListModel[];
	public selectedUser = 0;
	public dataSource: DataSource<CommunicationSummaryInterface>;
	public currentDate = new Date();
	public startDate: Date;
	public endDate: Date;

	public columns = [
		{
			columnDef: 'agent',
			title: 'Agent',
		}, {
			columnDef: 'emailCount',
			title: 'Email Count',
		}, {
			columnDef: 'emailSum',
			title: 'Email Total',
		}, {
			columnDef: 'letterCount',
			title: 'Letter Count',
		}, {
			columnDef: 'letterSum',
			title: 'Letter Total',
		}, {
			columnDef: 'postcardCount',
			title: 'Postcard Count',
		}, {
			columnDef: 'postcardSum',
			title: 'Postcard Total',
		}, {
			columnDef: 'totalCount',
			title: 'Overall Count',
		}, {
			columnDef: 'totalSum',
			title: 'Overall Total',
		},
	];

	@ViewChild('selectedUserControl', {static: true})
	private selectedUserControl: MatSelect;
	private subscription: Subscription;

	constructor(private usersService: UsersService, private summaryService: SummaryService) {}

	public ngOnInit() {
		this.loading = true;
		this.startDate = moment().startOf('month').toDate();
		this.endDate = moment().endOf('month').toDate();
		this.updateData();

		this.selectedUserControl.selectionChange
			.pipe(debounce(() => timer(500)))
			.subscribe(this.updateData.bind(this));
	}

	public ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
			this.subscription = null;
		}
	}

	public updateData() {
		if (this.subscription) {
			this.subscription.unsubscribe();
			this.subscription = null;
		}

		this.loading = true;
		this.subscription = forkJoin(this.usersService.getAllUsers(),
			this.summaryService.getProgress(this.startDate, this.endDate, this.selectedUser),
			this.summaryService.getCommunicationSummary(this.startDate, this.endDate, this.selectedUser))
			.finally(() => {
				this.loading = false;
			})
			.subscribe(([users, progress, communicationSummary]) => {
				this.users = users.filter(user => user.active);
				this.progress = progress;
				this.communicationSummary = communicationSummary;
				this.updateDatasource();
			});
	}

	public printProgress(): void {
		window.print();
	}

	private updateDatasource() {
		this.dataSource = new MatTableDataSource<CommunicationSummaryInterface>(this.communicationSummary);
	}

}

export interface CommunicationSummaryInterface {

	agent: string;
	emailCount: number;
	emailSum: number;
	letterCount: number;
	letterSum: number;
	postcardCount: number;
	postcardSum: number;
	totalCount: number;
	totalSum: number;

}
