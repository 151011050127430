import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { PropertySearchDto, ValuesModel } from '@thomas-duke-co/reis-shared';

@Component({
	selector: 'filter-chips',
	templateUrl: './filter-chips.component.pug',
	styleUrls: ['./filter-chips.component.scss'],
})

export class FilterChipsComponent implements OnChanges {

	public chips: ChipInterface[];

	@Input()
	public editable = true;

	@Input()
	public values: ValuesModel;

	@Input()
	protected filters: Partial<PropertySearchDto>;

	@Output()
	protected filtersChanged: EventEmitter<any> = new EventEmitter<any>();

	protected timer;

	public ngOnChanges(): void {
		if (!this.values) {
			return;
		}

		const relativeTimeValueFields: string[] = [
			'listFromValue',
			'listToValue',
			'expirationFromValue',
			'expirationToValue',
			'closedFromValue',
			'closedToValue',
			'salesPresentationFromValue',
			'salesPresentationToValue',
		];

		const relativeTimeFieldsToIgnore: string[] = [
			'listFromRelation',
			'listToRelation',
			'expirationFromRelation',
			'expirationToRelation',
			'closedFromRelation',
			'closedToRelation',
			'salesPresentationFromRelation',
			'salesPresentationToRelation',
		];

		this.chips = [];

		if (this.filters.statusId != null && this.filters.statusId.length === 6 && !this.filters.statusId.find(element => element === 8)) {
			relativeTimeFieldsToIgnore.push('statusId');
		}

		Object.keys(this.filters).forEach(async (key: keyof PropertySearchDto) => {
			const filter: any = this.filters[key];
			const searchDto = new PropertySearchDto();

			if (relativeTimeValueFields.includes(key)) {
				const field = {
					key: key,
					value: await searchDto.getRelativeTimeFilter(this.filters, key),
					title: searchDto.getTitle(key),
				};
				this.chips.push(field);
			} else if (relativeTimeFieldsToIgnore.includes(key)) {
			} else if (Array.isArray(filter)) {
				filter.forEach((value, index) => {
					this.addChip(key, value, index);
				});
			} else {
				this.addChip(key, this.filters[key] as string | number);
			}
		});
	}

	public removeChip(chip: ChipInterface, index: number) {
		if (!this.editable) {
			return;
		}

		if (chip.index !== undefined) {
			this.filters[chip.key].splice(chip.index, 1);
		} else {
			delete this.filters[chip.key];
		}

		this.chips.splice(index, 1);
		this.ngOnChanges();

		if (this.timer) {
			clearTimeout(this.timer);
		}
		this.timer = setTimeout(() => {
			this.filtersChanged.emit();
		}, 500);
	}

	protected addChip(key: string, value: string | number, index?: number): void {
		const searchDto = new PropertySearchDto();
		const chip: ChipInterface = {
			key,
			value: searchDto.getValue(this.values, key, value),
			title: searchDto.getTitle(key),
		};

		if (index !== undefined) {
			chip.index = index;
		}

		this.chips.push(chip);
	}

}

export interface ChipInterface {

	key: string;
	value: any;
	title: string;
	index?: number;

}
