import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {ValueInterface} from '@thomas-duke-co/reis-shared';

@Pipe({
	name: 'categoryOptions',
})
@Injectable()
export class CategoryOptionsPipe implements PipeTransform {

	public PFS = ['01', '02', '04', '05', '06', '08', '10'];
	public UPFL = ['02', '04', '05', '06', '08', '10'];
	public MH = ['03'];
	public VL = ['01', '07', '09'];

	public transform(items: ValueInterface[], financialClassId: number): ValueInterface[] {
		const list = [];
		let filterList;
		if (!items) {
			return [];
		}
		switch (+financialClassId) {
			case 1: filterList = this.PFS; break;
			case 2: filterList = this.UPFL; break;
			case 3: filterList = this.PFS; break;
			case 4: filterList = this.MH; break;
			case 5: filterList = this.VL; break;
			default: filterList = []; break;
		}

		for (const item of items) {
			const group = item.name.substr(0, 2);
			if (filterList.includes(group)) {
				list.push(item);
			}
		}

		return list;

	}
}
