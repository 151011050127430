import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { TokenService } from '../../../../login/service/token.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { PropertyPhoto } from '@thomas-duke-co/reis-shared';
import { MatDialog } from '@angular/material';
import { SortablejsOptions } from 'angular-sortablejs';
import { CropImageDialogComponent } from '../../../../../component/crop-image-dialog/crop-image-dialog.component';
import { UploaderService } from '../../../../../service/uploader.service';
import { Uploader } from '../../../../../class/uploader.class';

@Component({
	selector: 'property-photos',
	templateUrl: './photos.component.pug',
	styleUrls: ['./photos.component.scss'],
})
export class PhotosComponent implements OnChanges, OnInit, OnDestroy {

	@Input() public photosFormGroup: FormGroup;
	@Input() public hasAdminRights = false;

	@ViewChild('inputFile', {static: true}) inputFile: ElementRef;

	public sortOptions: SortablejsOptions;
	public loading = false;
	public featuredPhoto = null;
	public featuredPhotoIndex = null;

	private featuredImageSubscription = null;

	constructor(
		public tokenService: TokenService,
		private sanitizer: DomSanitizer,
		private toastrService: ToastrService,
		private dialog: MatDialog,
		private uploaderService: UploaderService,
	) {
	}

	public ngOnDestroy() {
		if (this.featuredImageSubscription) {
			this.featuredImageSubscription.unsubscribe();
			this.featuredImageSubscription = null;
		}
	}

	public ngOnInit(): void {
		this.sortOptions = {
			animation: 150,
			disabled: !this.hasAdminRights,
			onUpdate: this.onSort.bind(this),
		};
	}

	public removePhoto(index: number): void {
		if (index < 0 || (!index && index !== 0)) {
			return;
		}

		const photos: FormArray = this.getPhotos();
		const photo = photos.at(index);

		if (photo.value.featured) {
			this.featuredPhoto = null;
			this.featuredPhotoIndex = null;
		}

		if (photo.value.hasOwnProperty('id')) {
			photo.patchValue(Object.assign({}, photo.value, {deleted: true, featured: false}));
		} else {
			photos.removeAt(index);
		}

		this.rebuildPhotoControls(photos);
	}

	public getPhotos(): FormArray {
		return this.photosFormGroup.get('photos') as FormArray;
	}

	private onSort(event: any): void {
		const photos: FormArray = this.getPhotos();
		const photo: AbstractControl = photos.at(event.oldIndex);
		photos.removeAt(event.oldIndex);
		photos.insert(event.newIndex, photo);
		this.rebuildPhotoControls(photos);
	}

	public ngOnChanges(): void {
		this.photosFormGroup.value.photos.map((photo: PropertyPhoto, index) => {
			if (photo.hasOwnProperty('featured') && photo.featured) {
				this.featuredPhoto = photo;
				this.featuredPhotoIndex = index;
			}
			return photo;
		});
	}

	public recieveNewPhoto(photo) {
		const photos: FormArray = this.getPhotos();
		if (photo.hasOwnProperty('featured') && photo.featured) {
			photos.controls.forEach(control => {
				control.patchValue(Object.assign({}, control.value, {featured: false}));
			});
		}
		photos.push(new FormControl(photo));
		if (photo.featured) {
			if (this.featuredPhoto) {
				this.removePhoto(this.featuredPhotoIndex);
			}

			this.featuredPhoto = photo;
			this.featuredPhotoIndex = photos.length - 1;
		}
		this.rebuildPhotoControls(photos);
	}

	public rebuildPhotoControls(photos: FormArray) {
		let ordinal = 0;
		photos.controls.forEach(control => {
			if ((!control.value.hasOwnProperty('deleted') || !control.value.deleted) && (!control.value.hasOwnProperty('featured') || !control.value.featured)) {
				control.patchValue(Object.assign({}, control.value, {ordinal: ordinal++}));
			}
		});
		this.photosFormGroup.setControl('photos', photos);
		this.photosFormGroup.markAsDirty();
	}

	public featurePhoto(photo: PropertyPhoto) {
		if (this.loading) {
			this.toastrService.info('Wait for current image processing to complete before continuing...');
			return;
		}

		const dialog = this.dialog.open(CropImageDialogComponent, {
			width: '450px',
			data: {
				image: photo,
			},
		});

		dialog.afterClosed().subscribe((image) => {

			if (image) {
				const uploader = this.uploaderService.createUploader(this.tokenService.getAccessToken());

				this.featuredImageSubscription = uploader.subscribe((data) => {
					const {event, value} = data;
					switch (event) {
						case Uploader.EVENT.SUCCESS_ITEM: {
							const {response} = value;
							const newPhoto: PropertyPhoto = JSON.parse(response);
							newPhoto.featured = true;
							this.recieveNewPhoto(newPhoto);
							break;
						}
						case Uploader.EVENT.AFTER_ADDING_FILE: {
							const {item} = value;
							item.withCredentials = false;
							this.loading = true;
							break;
						}
						case Uploader.EVENT.COMPLETE_ALL:
							this.loading = false;
							break;
					}

				});
				uploader.getUploader().addToQueue([image]);
			}

		});
	}

}
