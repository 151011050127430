import { Component, Inject, OnInit } from '@angular/core';
import { UserModel, ShownModel, ValueInterface } from '@thomas-duke-co/reis-shared';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { DeleteDialogComponent } from '../deleteDialog/delete-dialog.component';
import { UserService } from '../../service/user.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ShownDialogDataInterface } from '../../interface/shown-dialog-data.interface';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from '../../module/admin/service/users.service';

@Component({
	templateUrl: './shown-dialog.component.pug',
	styleUrls: ['./shown-dialog.component.scss'],
})
export class ShownDialogComponent implements OnInit {

	public canUpdateNextContactDate: boolean = false;
	public currentDate = new Date();
	public shownFormGroup: FormGroup;
	public createMode: boolean = false;

	constructor(
		protected dialogRef: MatDialogRef<ShownDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ShownDialogDataInterface,
		protected deleteDialog: MatDialog,
		userService: UserService,
		protected toastrService: ToastrService,
		protected fb: FormBuilder,
		protected usersService: UsersService,
	) {
		const user: UserModel = userService.getUser();
		this.data.type = this.data.type || 'Shown';
		this.canUpdateNextContactDate = (this.data.shown.prospect.routedToId === user.userId || userService.hasRole('Admin') || userService.hasRole('Secretary'));
	}

	public ngOnInit(): void {
		if (this.data.action === 'Add') {
			this.createMode = true;
		}
		this.createFormGroup();

		const listingAgent: string = this.shownFormGroup.controls['agent'].value['userId'];
		const sender: string = this.shownFormGroup.controls['sender'].value['userId'];

		if (listingAgent && !this.agentInValues(listingAgent)) {
			this.usersService.getUser(listingAgent).subscribe((user: UserModel) => {
				this.data.agents.push({id: listingAgent, name: `${user.firstName} ${user.lastName} (INACTIVE)`});
			});
		}

		if (sender && !this.agentInValues(sender)) {
			this.usersService.getUser(sender).subscribe((user: UserModel) => {
				this.data.agents.push({id: sender, name: `${user.firstName} ${user.lastName} (INACTIVE)`});
			});
		}
	}

	public createFormGroup(): void {
		this.shownFormGroup = this.fb.group({
			id: this.data.shown.id,
			parentId: this.data.shown.parentId,
			prospect: this.fb.group({
				id: [this.data.shown.prospect.id],
				firstName: [this.data.shown.prospect.firstName],
				lastName: [this.data.shown.prospect.lastName],
			}),
			property: this.fb.group({
				id: [this.data.shown.property.id],
				name: [this.data.shown.property.name],
			}),
			date: [this.data.shown.date, [Validators.required]],
			comments: [this.data.shown.comments, [Validators.required]],
			agent: this.fb.group({
				userId: [this.data.shown.agent.userId],
				firstName: [this.data.shown.agent.firstName],
				lastName: [this.data.shown.agent.lastName],
				initials: [this.data.shown.agent.initials],
			}),
			sender: this.fb.group({
				userId: [this.data.shown.sender.userId],
				firstName: [this.data.shown.sender.firstName],
				lastName: [this.data.shown.sender.lastName],
				initials: [this.data.shown.sender.initials],
			}),
			shouldUpdateNextContactDate: this.data.shouldUpdateNextContactDate,
		});
	}

	public confirmDelete(): void {
		this.deleteDialog
			.open(DeleteDialogComponent, {
				width: '300px',
				data: {
					text: `this ${this.data.type.toLowerCase()}`,
					type: this.data.type,
				},
			})
			.afterClosed()
			.subscribe(result => {
				if (result === 'Delete') {
					this.dialogRef.close('Delete');
				}
			});
	}

	public toggleArchiveStatus(): void {
		this.dialogRef.close('Toggle');
	}

	public shouldShowDeleteButton(): boolean {
		return this.data.action === 'Edit' && this.data.canEdit;
	}

	public shouldShowSaveAddButton(): boolean {
		if (this.data.addFromMatch) {
			return false;
		} else {
			return this.data.type === 'Shown' && this.data.canEdit;
		}
	}

	public save(add?: string) {
		if (this.shownFormGroup.invalid) {
			this.shownFormGroup.get('date').markAsTouched();
			this.shownFormGroup.get('comments').markAsTouched();
			this.toastrService.error(`Date and Comments field must be complete`);
			return false;
		}

		if (add) {
			this.data.addAnother = true;
		}

		this.data.shouldUpdateNextContactDate = this.shownFormGroup.get('shouldUpdateNextContactDate').value;
		this.data.shown = new ShownModel(this.shownFormGroup.value);
		this.dialogRef.close(this.data);
	}

	protected agentInValues(id: string): boolean {
		return !!this.data.agents.find((agent: ValueInterface) => agent.id === id);
	}

}
