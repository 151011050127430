import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs/Observable';
import {TeamModel, UserModel} from '@thomas-duke-co/reis-shared';

@Injectable()
export class TeamsService {
	constructor(protected http: HttpClient) {
	}

	getAllTeams(): Observable<TeamModel[]> {
		return this.http.get<TeamModel[]>(`${environment.apiUrl}/team`);
	}

	getAllTeamMembers(teamId: number): Observable<UserModel[]> {
		return this.http.get<UserModel[]>(`${environment.apiUrl}/team-members/${teamId}`);
	}

	getTeam(id: string): Observable<TeamModel> {
		return this.http.get<TeamModel>(`${environment.apiUrl}/team/${id}`);
	}

	saveTeam(team: TeamModel): Observable<TeamModel> {
		return this.http.put<TeamModel>(`${environment.apiUrl}/team/${team.teamId}`, team);
	}

	createTeam(team: TeamModel): Observable<TeamModel> {
		return this.http.post<TeamModel>(`${environment.apiUrl}/team`, team);
	}

	deleteTeam(id: number): Observable<string> {
		return this.http.delete<string>(`${environment.apiUrl}/team/${id}`);
	}

	addTeamMembers(userIds: number[], teamId: number): Observable<number> {
		return this.http.post<number>(`${environment.apiUrl}/member/${teamId}`, userIds);
	}

	removeMember(userId: number): Observable<string> {
		return this.http.delete<string>(`${environment.apiUrl}/member/${userId}`);
	}
}
