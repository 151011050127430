import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { StartupService } from './service/startup.service';
import { ShutdownService } from './service/shutdown.service';
import { NavigationService } from './service/navigation.service';
import { NavModel } from './model/nav.model';
import { UserService } from './service/user.service';
import * as io from 'socket.io-client';
import { environment } from '../environments/environment';
import { UserModel } from '@thomas-duke-co/reis-shared';

@Component({
	selector: 'dvp-root',
	templateUrl: './app.component.pug',
	styleUrls: ['./app.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {

	public loaded = false;
	public right: string = '0';
	public shutdown = false;
	public callEnded: boolean = false;
	public callPopOpen: boolean = false;
	public mainNavigation: NavModel[] = [];
	public user: UserModel;
	public socket;
	public callPopData: any;

	constructor(
		public userService: UserService,
		protected startupService: StartupService,
		protected shutdownService: ShutdownService,
		public navigationService: NavigationService,
	) {
		this.mainNavigation = navigationService.getMainNavigation();
		navigationService.mainNavigationUpdates.subscribe(mainNavigation => {
			this.mainNavigation = mainNavigation;
		});
		this.user = this.userService.getUser();
	}

	public ngOnInit() {
		// Wait to show the application until all the startup triggers have completed their work
		this.startupService.onStartup().subscribe(() => {
			this.loaded = true;

			// if (this.user) {
			// 	this.connectToSocketIo();
			// }

		});

		// Show the shutdown screen if the shutdown trigger is fired
		this.shutdownService.onShutdown().subscribe(() => this.shutdown = true);
	}

	public connectToSocketIo(user?: UserModel): void {
		const currentUser = this.user ? this.user : user;
		this.socket = io.connect(`${environment.apiUrl}?ext=${currentUser.extension}`);

		this.socket.on('callpop', (data) => {
			this.callPopData = JSON.parse(data);
			this.callPopOpen = true;
		});

		this.socket.on('callEnded', () => {
			this.callEnded = true;
			if (!this.callPopOpen) {
				this.callPopAction('close');
			}
		});
	}

	public callPopAction(action: any): void {
		if (action === 'close') {
			this.callPopData = undefined;
			this.callPopOpen = false;
			this.callEnded = false;
			this.right = '0';
			this.socket.emit('close');
		}
	}

	public toggleCallPop(): void {
		if (this.right === '0') {
			this.right = '-405';
			this.callPopOpen = false;
		} else {
			this.right = '0';
			this.callPopOpen = true;
		}
	}

}
