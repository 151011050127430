import { Component, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { ProspectModel, ProspectListModel } from '@thomas-duke-co/reis-shared';
import { ProspectPage } from '../../../prospect/page/prospect/prospect.page';

@Component({
	templateUrl: './create-owner-dialog.component.pug',
	styleUrls: ['./create-owner-dialog.component.scss'],
})

export class CreateOwnerDialogComponent {

	@ViewChild(ProspectPage, {static: true}) protected prospectPage: ProspectPage;

	public owner: ProspectModel;

	constructor(
		public dialogRef: MatDialogRef<CreateOwnerDialogComponent>,
	) {}

	public prospectCreated(event: ProspectModel): void {
		this.owner = event;
		this.dialogRef.close(this.owner);
	}

	public prospectSelected(event: ProspectListModel): void {
		this.dialogRef.close(event);
	}

	public async create(): Promise<void> {
		await this.prospectPage.saveButtonClicked();
	}
}
