import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UserModel, UserPreferencesModel, UserListModel } from '@thomas-duke-co/reis-shared';
import { StorageService } from './storage.service';
import { environment } from '../../environments/environment';
import { Observable, Subject } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';

@Injectable()
export class UserService {

	public userUpdates: Subject<UserModel> = new Subject();

	protected user: UserModel = null;

	constructor(protected http: HttpClient) {
		this.user = StorageService.get('user');
	}

	public hasUser() {
		return !!this.user;
	}

	public setUser(user: UserModel): boolean {

		this.clear();

		this.user = user;
		StorageService.set('user', user);
		this.userUpdates.next(user);

		return true;
	}

	public getUser(): UserModel {
		return this.user;
	}

	public getUsername(): string {
		return (this.user || {username: ''}).username;
	}

	public clear() {
		this.user = null;
		StorageService.remove('user');
	}

	public hasRole(role: string): boolean {
		return this.user && this.user.roles ? this.user.roles.includes(role) : false;
	}

	public setAtDesk(ip: string): void {
		if (ip) {
			StorageService.set('isAtDesk', environment.officeIps.includes(ip));
		}
	}

	public isAtDesk(): boolean {
		return !!StorageService.get('isAtDesk');
	}

	public setPreferences(preferences: UserPreferencesModel): Observable<any> {
		this.user.preferences = preferences;
		this.setUser(this.user);
		return this.http.put(`${environment.apiUrl}/user/${this.user.userId}/preferences`, preferences);
	}

	public getDefaultEmailSignature(): Observable<string> {
		return this.http.get<string>(`${environment.apiUrl}/user/${this.user.userId}/default-email-signature`);
	}

	public checkDuplicateExtension(extension: string, userId?: number): Observable<UserListModel> {
		const params = new HttpParams()
			.append('userId', `${userId}`);

		return this.http.get<UserListModel>(`${environment.apiUrl}/user/check-ext/${extension}`, {params});
	}

	public removePhoneExt(userId: number): Observable<void> {
		return this.http.put<void>(`${environment.apiUrl}/user/remove-ext/${userId}`, userId);
	}

}
