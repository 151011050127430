import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { LatLngBounds, MapsAPILoader } from '@agm/core';
import { ToastrService } from 'ngx-toastr';
import { PaginationDto, PropertyListModel, PropertySearchDto, SavedSearchModel, UserModel, UserPreferencesModel, ValuesModel } from '@thomas-duke-co/reis-shared';
import { SearchService } from '../../../service/search.service';
import { Subscription } from 'rxjs/Subscription';
import { ValuesService } from '../../../../../service/values.service';
import { UserService } from '../../../../../service/user.service';
import { StorageService } from '../../../../../service/storage.service';
import { NavigationService } from '../../../../../service/navigation.service';
import { PropertyMapComponent } from '../property-map/property-map.component';
import { SavedSearchService } from '../../../../../service/saved-search.service';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatPaginator, PageEvent } from '@angular/material';
import { YesNoDialogComponent } from '../../../../../component/yes-no-dialog/yes-no-dialog.component';
import { IconService } from '../../../../../service/icon.service';
import { SearchPresetListComponent } from '../search-preset-list/search-preset-list.component';
import { PropertyService } from '../../../service/property.service';
import { PrintColumnSelectionDialogComponent } from '../../../../../component/print-column-selection-dialog/print-column-selection-dialog.component';
import { SearchPresetDialogComponent } from '../../../../../component/search-preset-dialog/search-preset-dialog.component';
import { SearchFiltersComponent } from '../search-filters/search-filters.component';

@Component({
	selector: 'property-search-map',
	templateUrl: './property-search-map.component.pug',
	styleUrls: ['./property-search-map.component.scss'],
})
export class PropertySearchMapComponent implements OnInit, AfterViewInit, OnDestroy {

	protected static readonly DEFAULT_PAGE_SIZES: number[] = [5, 10, 25, 50];

	@ViewChild('search', {static: false})
	protected searchElementRef: ElementRef;

	@ViewChild('searchPresetList', {static: false})
	protected searchPresetList: SearchPresetListComponent;

	@ViewChild(MatPaginator, {static: true})
	protected paginator: MatPaginator;

	@ViewChild('searchFilters', {static: false})
	public searchFilters: SearchFiltersComponent;

	@Input()
	protected map: PropertyMapComponent;

	@Output()
	public resultClick: EventEmitter<number> = new EventEmitter<number>();

	@Output()
	public toggleClosedListingsEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Output()
	public toggleMarkers: EventEmitter<boolean> = new EventEmitter<boolean>();

	protected searchSubscription: Subscription;
	protected currentSearchDto: Partial<PropertySearchDto>;
	protected paginatorSubscription: Subscription;
	protected user: UserModel;
	protected populateMapTimeout: NodeJS.Timer;

	public searchText: string;
	public searchResults: PaginationDto<PropertyListModel>;
	public currentSearchPreset: SavedSearchModel<PropertySearchDto>;
	public currentSearchType;
	public loading = false;
	public updatingFilters = false;
	public editingPreset = false;
	public values: ValuesModel;
	public creatingPreset = false;
	public presetFilters = {};
	public presetToEdit: SavedSearchModel<PropertySearchDto>;
	public isExtraSmall: boolean;
	public filters: Partial<PropertySearchDto> = {};
	public currentPage = 0;
	public pageSize: number;
	public pageSizes: number[];
	public totalItems = 0;
	public showFilters: boolean = true;
	public showClosedListings: boolean = false;
	public groupMarkers: boolean = true;
	public showAllStatuses: boolean = false;
	public showSavedSearches: boolean = false;

	constructor(
		protected searchService: SearchService,
		protected valuesService: ValuesService,
		protected userService: UserService,
		protected searchPresetService: SavedSearchService<PropertySearchDto>,
		protected navigationService: NavigationService,
		protected mediaObserver: MediaObserver,
		protected mapsAPILoader: MapsAPILoader,
		protected toastrService: ToastrService,
		protected route: ActivatedRoute,
		protected yesNoDialog: MatDialog,
		protected iconService: IconService,
		protected printColumnSelectionDialog: MatDialog,
		protected searchPresetCreateDialog: MatDialog,
	) {
		this.user = this.userService.getUser();

		if (!this.user.preferences) {
			this.user.preferences = new UserPreferencesModel();
		}

		this.pageSize = this.user.preferences.propertiesPerPage || 25;
		this.setPageSizes();
		this.mediaObserver.media$.subscribe((change: MediaChange) => {
			this.isExtraSmall = change.mqAlias === 'xs';
		});
	}

	public async ngOnInit() {
		await this.mapsAPILoader.load();

		this.valuesService.getValues().subscribe((values: ValuesModel) => {
			this.values = values;
		});

		const restore: boolean = !!this.route.snapshot.queryParams.restore;

		if (this.navigationService.backClicked() || restore) {
			this.restorePreviousSearch();
		} else {
			this.setDefaults();
		}

		this.searchElementRef.nativeElement.focus();
		this.toggleGroupMarkers();

		this.databaseSearch(this.searchElementRef.nativeElement.value);
	}

	public ngOnDestroy() {
		if (this.searchSubscription) {
			this.searchSubscription.unsubscribe();
			this.searchSubscription = null;
		}

		if (this.paginatorSubscription) {
			this.paginatorSubscription.unsubscribe();
			this.paginatorSubscription = null;
		}
	}

	public ngAfterViewInit(): void {
		if (this.paginatorSubscription) {
			this.paginatorSubscription.unsubscribe();
		}

		this.paginatorSubscription = this.paginator.page.subscribe(async (event: PageEvent): Promise<void> => {
			this.totalItems = event.length;
			this.currentPage = event.pageIndex;
			await this.setPageSize(event);
			this.onEnter(this.searchText, false);
		});
	}

	public clearSearchResults() {
		this.searchText = null;
		this.searchResults = null;
		this.showSavedSearches = false;
		this.map.setMarkers([]);
		this.setDefaults();
	}

	public async parsePreset(search: SavedSearchModel<PropertySearchDto>): Promise<void> {
		Object.keys(search.filters).forEach(key => {
			if (key === 'keywords') {
				this.searchText = search.filters[key];
			}
		});

		this.groupMarkers = search.groupMarkers;
		this.toggleGroupMarkers();
		await this.applyFilters({search});
	}

	public editPreset(preset: SavedSearchModel<PropertySearchDto>): void {
		this.editingPreset = true;
		this.updatingFilters = true;
		this.presetToEdit = preset;
	}

	public savePreset(search: SavedSearchModel<PropertySearchDto>): void {
		this.searchPresetService.saveSearch(search)
			.subscribe(() => {
				if (this.editingPreset) {
					this.searchPresetList.toggleDelete(search, true);
				}
				this.currentSearchPreset = search;
			});
	}

	public saveSearch() {
		if (this.currentSearchPreset && this.currentSearchPreset.name) {
			return new Promise<void>((resolve: any): void => {
				this.yesNoDialog.open(YesNoDialogComponent, {
					data: {
						title: 'Search Preset',
						question: `Do you want to overwrite ${this.currentSearchPreset.name}?`,
						allowCancel: false,
					},
				}).afterClosed().subscribe((result) => {
					if (result === 'Yes') {
						this.openSearchPresetDialog(true);
					}

					if (result === 'No') {
						this.currentSearchPreset = null;
						this.presetFilters = this.searchText ? Object.assign({}, { filters: Object.assign({keywords: this.searchText}, this.filters)}) : Object.assign({}, this.filters);
						this.openSearchPresetDialog();
					}

					resolve();
				});
			});
		}

		this.presetFilters = this.searchText ? Object.assign({}, { filters: Object.assign({keywords: this.searchText}, this.filters)}) : Object.assign({}, this.filters);
		this.openSearchPresetDialog();
	}

	public openSearchPresetDialog(overWrite?: boolean): void {
		this.searchPresetCreateDialog.open(SearchPresetDialogComponent, {
			data: {
				searchPreset: this.currentSearchPreset ? this.currentSearchPreset : this.presetFilters,
				action: 'Property',
			},
		}).afterClosed().subscribe((result) => {
			if (result === 'Cancel') {
				return;
			}

			const newSearch: SavedSearchModel<PropertySearchDto> = this.createNewSearch(result, overWrite);

			this.searchPresetService.saveSearch(newSearch).subscribe(() => {
				this.toastrService.success('Search Preset successfully saved');
				this.currentSearchPreset = newSearch;
				this.searchPresetList.getPresets();
			});
		});
	}

	public createNewSearch(search, overWrite?: boolean): SavedSearchModel<PropertySearchDto> {
		const name: string = search.name;
		delete search.name;

		const newSearch: SavedSearchModel<PropertySearchDto> = {
			filters: search && search.filters ? search.filters : search,
			isPublic: search.isPublic,
			userID: this.userService.getUser().userId,
			name,
			type: 'Property',
			groupMarkers: this.groupMarkers,
		};

		if (overWrite) {
			newSearch.savedSearchID = this.currentSearchPreset.savedSearchID;
		}

		return newSearch;
	}

	public async applyFilters(data?: {search: SavedSearchModel<PropertySearchDto>, saveChanges?: boolean}): Promise<void> {

		if (data) {
			this.filters = data.search.filters;
			this.currentSearchPreset = null;
			this.currentSearchPreset = data.search;

			if (data.saveChanges) {
				this.savePreset(data.search);
			}
		}

		Object.keys(this.filters).forEach(key => {
			if (Array.isArray(this.filters[key]) && this.filters[key].length === 0) {
				delete this.filters[key];
			}
		});

		const propertySearch = StorageService.has('propertySearch') ? StorageService.get('propertySearch') : {};
		propertySearch.filters = this.filters;
		StorageService.set('propertySearch', propertySearch);
		this.currentPage = 0;
		this.onEnter(this.searchText);
	}

	public toggleFilters(): void {
		this.showFilters = !this.showFilters;
	}

	public toggleGroupMarkers(): void {
		this.toggleMarkers.emit(this.groupMarkers);
	}

	public async toggleFilterStatuses(): Promise<void> {
		this.showAllStatuses = !this.showAllStatuses;

		if (!this.showAllStatuses) {
			this.filters = {statusId: SearchService.DEFAULT_STATUSES.slice()};
		} else {
			this.filters = {statusId: [1, 2, 3, 4, 5, 6]};
		}

		await this.applyFilters();
	}

	public showPreview(id: number, event) {
		event.preventDefault();
		event.stopPropagation();
		this.resultClick.emit(id);
	}

	public exportSearchResults(): Promise<boolean> {
		return this.showColumnSelectionDialog();
	}

	protected showColumnSelectionDialog(): Promise<boolean> {
		return new Promise<boolean>((resolve: any): void => {
			this.printColumnSelectionDialog
				.open(PrintColumnSelectionDialogComponent, {
					minWidth: '800px',
					data: {
						reportType: 'Property',
						currentSearch: this.currentSearchDto,
					},
				})
				.afterClosed()
				.subscribe(() => {
					resolve();
				});
		});
	}

	public exportMeetingAgenda() {
		this.loading = true;
		const tab: Window = window.open('/loading');
		this.searchService.getMeetingAgendaPdf(this.currentSearchDto)
			.finally(() => {
				this.loading = false;
			})
			.subscribe((data) => {
				const file = new Blob([data], {type: 'application/pdf'});
				tab.location.replace(URL.createObjectURL(file));
				setTimeout(() => tab.print(), 1500);
			});
	}

	public showAllProperties(): void {
		this.pageSize = 10000;
		this.filters.statusId = PropertyService.ACTIVE_STATUSES;
		this.databaseSearch(null, true);
	}

	public toggleClosedListings(): void {
		this.showClosedListings = !this.showClosedListings;
		this.toggleClosedListingsEvent.emit(this.showClosedListings);
	}

	public onMapMove() {
		if (!this.currentSearchType || !this.currentSearchDto) {
			return;
		}

		if (this.currentSearchType === 'location' || this.currentSearchType === 'viewport') {
			this.searchText = null;
			this.viewportSearch();
		} else {
			this.currentPage = 0;
			this.doViewportSearch(this.searchText);
		}
	}

	public onEnter(keywords: string, resetPage: boolean = true): void {
		switch (this.currentSearchType) {
			case 'database':
				this.databaseSearch(keywords, resetPage);
				break;
			case 'location':
			case 'viewport':
				if (keywords) {
					// noinspection JSIgnoredPromiseFromCall
					this.locationSearch(keywords, resetPage);
				} else {
					this.viewportSearch(resetPage);
				}
				break;
		}
	}

	public databaseSearch(keywords: string, resetPage: boolean = true): void {
		this.showClosedListings = false;
		this.toggleClosedListingsEvent.emit(this.showClosedListings);

		clearTimeout(this.populateMapTimeout);
		if (this.currentSearchType !== 'database' || resetPage) {
			this.currentPage = 0;
			this.currentSearchType = 'database';
		}

		this.currentSearchDto = keywords ? Object.assign({}, this.filters, {keywords}) : Object.assign({}, this.filters);

		StorageService.set('propertySearch', {
			type: 'database',
			keywords: keywords,
			filters: this.filters,
			dto: this.currentSearchDto,
		});

		if (this.searchSubscription) {
			this.searchSubscription.unsubscribe();
		}

		this.loading = true;
		this.searchSubscription = this.searchService
			.search(this.currentSearchDto, this.currentPage, this.pageSize)
			.subscribe(this.displayResults.bind(this));
	}

	public async locationSearch(keywords: string, resetPage: boolean = true): Promise<void> {
		clearTimeout(this.populateMapTimeout);
		if (this.currentSearchType !== 'location' || resetPage) {
			this.currentPage = 0;
			this.currentSearchType = 'location';
		}

		if (!keywords) {
			this.viewportSearch();
			return;
		}

		try {
			const viewport: LatLngBounds = await this.geocodeLocation(keywords);
			const {south, north, west, east} = viewport.toJSON();
			this.currentSearchDto = Object.assign({}, this.filters, {
				southLatitude: `${south}`,
				northLatitude: `${north}`,
				westLongitude: `${west}`,
				eastLongitude: `${east}`,
			});
			this.map.setCenter(viewport.getCenter());
			this.map.setZoom(18);
		} catch (error) {
			this.toastrService.error(`Failed to geocode location ${keywords}`);
			return;
		}

		StorageService.set('propertySearch', {
			type: 'location',
			keywords: keywords,
			filters: this.filters,
			dto: this.currentSearchDto,
		});

		if (this.searchSubscription) {
			this.searchSubscription.unsubscribe();
		}

		this.doViewportSearch();
	}

	public getFormattedAddress(property: PropertyListModel): string {
		return [property.mapAddress, property.mapCity, property.mapZip].filter(part => part && part !== '').join(', ') || null;
	}

	public getMinPageSize(): number {
		return Math.min.apply(null, PropertySearchMapComponent.DEFAULT_PAGE_SIZES);
	}

	public getPropertyStatusIcon(property: PropertyListModel): string {
		return this.iconService.getPropertyStatusIcon(property.status);
	}

	public printMapSelected(): void {
		setTimeout(() => window.print());
	}

	protected viewportSearch(resetPage: boolean = true): void {
		clearTimeout(this.populateMapTimeout);
		if (this.map === undefined) {
			return;
		}

		if (this.currentSearchType !== 'viewport' || resetPage) {
			this.currentPage = 0;
			this.currentSearchType = 'viewport';
		}

		StorageService.set('propertySearch', {
			type: 'viewport',
			viewport: {
				zoom: this.map.getZoom(),
				center: this.map.getCenter(),
			},
			filters: this.filters,
		});

		if (this.searchSubscription) {
			this.searchSubscription.unsubscribe();
		}
		this.doViewportSearch();
	}

	protected doViewportSearch(keywords?: string): void {
		const {south, north, west, east} = this.map.getBounds().toJSON();
		this.currentSearchDto = Object.assign({}, this.filters, {
			southLatitude: `${south}`,
			northLatitude: `${north}`,
			westLongitude: `${west}`,
			eastLongitude: `${east}`,
		});

		if (keywords) {
			this.currentSearchDto.keywords = keywords;
		}

		this.loading = true;
		this.searchSubscription = this.searchService
			.search(this.currentSearchDto, this.currentPage, this.pageSize)
			.subscribe((paginationDto: PaginationDto<PropertyListModel>) => this.displayResults(paginationDto, false));
	}

	protected async geocodeLocation(keywords: string): Promise<LatLngBounds> {
		await this.mapsAPILoader.load();
		return new Promise<LatLngBounds>((resolve, reject) => {
			const geocoder = new window['google'].maps.Geocoder();
			geocoder.geocode({'address': keywords, 'bounds': this.map.getBounds().toJSON()}, (results, status) => {
				if (status === 'OK') {
					resolve(results[0].geometry.viewport);
				} else {
					reject('Failed to find location');
				}
			});
		});
	}

	protected displayResults(paginationDto: PaginationDto<PropertyListModel>, fitMarkers: boolean = true) {
		const propertySearch = StorageService.has('propertySearch') ? StorageService.get('propertySearch') : {};
		propertySearch.results = paginationDto;

		StorageService.set('propertySearch', propertySearch);

		if (propertySearch.hasOwnProperty('selectedPropertyId')) {
			paginationDto.data = paginationDto.data
				.map(result => {
					result.previouslySelected = (propertySearch.selectedPropertyId === result.id);
					return result;
				});
		}

		this.totalItems = paginationDto.totalItems;
		this.pageSize = paginationDto.pageSize;
		this.currentPage = paginationDto.currentPage;
		this.searchResults = paginationDto;
		this.populateMap(paginationDto, fitMarkers);
		this.setPageSizes();
		this.loading = false;
	}

	public setSelectedProperty(selectedProperty: PropertyListModel) {
		const propertySearch = StorageService.has('propertySearch') ? StorageService.get('propertySearch') : {};
		propertySearch.selectedPropertyId = selectedProperty.id;
		StorageService.set('propertySearch', propertySearch);
	}

	protected populateMap(paginationDto: PaginationDto<PropertyListModel>, fitMarkers: boolean = true) {
		if (this.map === undefined) {
			return;
		}

		if (paginationDto.data && paginationDto.data.length > 0) {
			const markers = paginationDto.data.map(({id, name, latitude, longitude}) => ({id, name, latitude, longitude}));
			this.map.setMarkers(markers, fitMarkers);
		} else if (!this.map.isReady()) {
			this.populateMapTimeout = setTimeout(() => {
				this.populateMap(paginationDto, fitMarkers);
			}, 100);
		}
	}

	protected restorePreviousSearch() {
		this.loading = true;
		if (!this.map || !this.map.isReady()) {
			if (!this.isExtraSmall) {
				setTimeout(this.restorePreviousSearch.bind(this), 500);
			}
			return;
		}

		const propertySearch = StorageService.has('propertySearch') && StorageService.get('propertySearch');

		if (!propertySearch) {
			this.setDefaults();
			this.loading = false;
			return;
		}

		if (propertySearch.hasOwnProperty('filters')) {
			this.filters = propertySearch.filters;
		}

		if (propertySearch.hasOwnProperty('results')) {
			this.displayResults(propertySearch.results);
		}

		if (propertySearch.hasOwnProperty('keywords')) {
			this.searchText = propertySearch.keywords;
		}

		if (propertySearch.hasOwnProperty('dto')) {
			this.currentSearchDto = propertySearch.dto;
		}

		if (propertySearch.hasOwnProperty('type')) {
			this.currentSearchType = propertySearch.type;
			if (propertySearch.type === 'viewport') {
				this.map.setZoom(propertySearch.viewport.zoom);
				this.map.setCenter(new window['google'].maps.LatLng(propertySearch.viewport.center.lat, propertySearch.viewport.center.lng));
			}
		}

		this.loading = false;
	}

	protected setDefaults() {
		this.currentSearchType = 'database';
		this.filters = {statusId: SearchService.DEFAULT_STATUSES.slice()};
	}

	protected setPageSizes() {
		this.pageSizes = PropertySearchMapComponent.DEFAULT_PAGE_SIZES.slice();

		if (this.pageSizes.indexOf(this.pageSize) === -1) {
			this.pageSizes.push(this.pageSize);
		}

		if (this.totalItems > Math.max.apply(null, this.pageSizes)) {
			this.pageSizes.push(this.totalItems);
		}
	}

	protected async setPageSize(event: PageEvent): Promise<void> {
		if (event.pageSize === this.pageSize) {
			return;
		}

		if (event.pageSize > Math.max.apply(null, PropertySearchMapComponent.DEFAULT_PAGE_SIZES) && !(await this.confirmLargePageSize())) {
			this.paginator.pageSize = this.pageSize;
			return;
		}

		this.pageSize = event.pageSize;

		if (this.pageSize !== this.user.preferences.propertiesPerPage && PropertySearchMapComponent.DEFAULT_PAGE_SIZES.indexOf(this.pageSize) !== -1) {
			this.userService.setPreferences(Object.assign({}, this.user.preferences, {propertiesPerPage: this.pageSize})).subscribe();
		}
	}

	protected confirmLargePageSize(): Promise<boolean> {
		return new Promise<boolean>((resolve: any): void => {
			this.yesNoDialog
				.open(YesNoDialogComponent, {
					data: {
						title: 'Performance Impact',
						question: 'Choosing a large number of results per page will impact performance and could cause the page to become unresponsive. Do you want to continue?',
						allowCancel: false,
					},
				})
				.afterClosed()
				.subscribe(result => resolve(result === 'Yes'));
		});
	}

}
