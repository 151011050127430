import { Component, Input } from '@angular/core';
import { UserModel, ValueInterface } from '@thomas-duke-co/reis-shared';

@Component({
	selector: 'sales-dashboard',
	templateUrl: './sales-dashboard.component.pug',
	styleUrls: ['./sales-dashboard.component.scss'],
})

export class SalesDashboardComponent {

	public _user: UserModel;
	@Input() set user(user: UserModel) {
		this._user = user;
	}
	get user(): UserModel {
		return this._user;
	}

	@Input() labelNames: ValueInterface;

	public printComponent: string;

	public setPrintComponent(component: string): void {
		this.printComponent = component;
	}

	public getPrintClasses(componentName?: string): string {
		if ((this.printComponent && this.printComponent === componentName) || !this.printComponent) {
			return null;
		}

		if (this.printComponent && this.printComponent !== componentName) {
			return 'hideOnPrint';
		}
	}

	public clearPrintComponent(): void {
		this.printComponent = null;
	}

}
