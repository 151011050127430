import { MatCheckboxChange } from '@angular/material';
import { PaginationDatasource } from '../../datasource/pagination.datasource';
import { SelectionModel } from '@angular/cdk/collections';
import { IdInterface } from '@thomas-duke-co/reis-shared';
import { EventEmitter, Output } from '@angular/core';

export abstract class SelectAllEditComponent {

	public bulkEditMode: boolean = false;
	public selectAllMode: boolean = false;
	public selectAllExclusions: boolean = false;
	public bulkEditExceptionList: IdInterface[] = [];
	public dataSource: PaginationDatasource<IdInterface>;
	public bulkEditSelection: SelectionModel<IdInterface> = new SelectionModel(true, []);

	@Output() toggleBulkEdit: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() itemsSelected: EventEmitter<boolean> = new EventEmitter<boolean>();

	public toggleBulkEditMode(event?: MouseEvent): boolean {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}

		this.bulkEditSelection.clear();
		this.bulkEditExceptionList = [];
		this.selectAllExclusions = false;
		this.selectAllMode = false;
		const mode = this.bulkEditMode = !this.bulkEditMode;
		this.toggleBulkEdit.emit(mode);
		return mode;
	}

	public masterToggle(): void {
		this.bulkEditSelection.clear();
		this.bulkEditExceptionList = [];
		this.selectAllExclusions = false;
		this.selectAllMode = !this.selectAllMode;

		this.dataSource.pager.data.forEach(item => {
			this.bulkEditSelection.toggle(item);
			this.bulkEditSelection[this.selectAllMode ? 'select' : 'deselect'](item);
		});

		this.itemsSelectedEvent();
	}

	public toggleBulkEditRow(event: MatCheckboxChange, item: IdInterface): void {
		if (!event) {
			return;
		}

		if (this.selectAllMode) {
			if (!event.checked) {
				this.bulkEditExceptionList.push(item);
				if (this.bulkEditExceptionList.length && this.selectAllMode) {
					this.selectAllExclusions = true;
				}
			} else {
				this.bulkEditExceptionList = this.bulkEditExceptionList.filter(x => x.id !== item.id);
				if (!this.bulkEditExceptionList.length && this.selectAllMode) {
					this.selectAllExclusions = false;
				}
			}
		} else {
			if (event.checked) {
				this.bulkEditExceptionList.push(item);
			} else {
				this.bulkEditExceptionList = this.bulkEditExceptionList.filter(x => x.id !== item.id);
			}
		}

		this.bulkEditSelection.toggle(item);
		this.itemsSelectedEvent();
	}

	public itemsSelectedEvent(): void {
		if (this.bulkEditSelection.selected.length > 0) {
			this.itemsSelected.emit(true);
		} else {
			this.itemsSelected.emit(false);
		}
	}

	public isBulkEditRowSelected(item: IdInterface): boolean {
		return this.bulkEditSelection.isSelected(item);
	}

	protected compareArrays(otherArray): any {
		return function (current) {
			return otherArray.filter((other) => {
				return other.id === current.id;
			}).length === 0;
		};
	}

}
