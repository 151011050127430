import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatStepper } from '@angular/material';
import { ProspectListModel, UserListModel, UserModel, UserPreferencesModel } from '@thomas-duke-co/reis-shared';
import { PropertyService } from '../../service/property.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from '../../../admin/service/users.service';
import { UserService } from '../../../../service/user.service';
import { EmailSendConfirmDialogComponent } from '../../../../component/email-send-confirm-dialog/email-send-confirm-dialog.component';
import { EmailService } from '../../../../service/email.service';
import { ProspectSelectionEventInterface } from '../../../../interface/prospect-selection-event.interface';
import { ProspectSearchComponent } from '../../../../component/prospect-search/prospect-search.component';
import { IconService } from '../../../../service/icon.service';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { RouterService } from '../../../../service/router.service';
import { StorageService } from '../../../../service/storage.service';

@Component({
	templateUrl: './share-listing-search.component.pug',
	styleUrls: ['./share-listing-search.component.scss'],
})
export class ShareListingSearchComponent implements OnInit, AfterViewInit {

	@ViewChild('prospectSearch', {static: true}) protected prospectSearch: ProspectSearchComponent;
	@ViewChild('inputImage', {static: true}) protected inputImage: ElementRef;
	@ViewChild('emailControl', {static: true}) protected emailInput: ElementRef;
	@ViewChild('stepper', {static: true}) protected stepper: MatStepper;

	public loading = false;
	public recipientList: ProspectListModel[] = [];
	public propertyId: number;
	public users: UserListModel[];
	public fromUser: number;
	public customImage: any;
	public customMessage: string;
	public description: string;
	public customEmail: string;
	public shouldAddSignature: boolean;
	public selectableImages: any[] = [];
	public backToUrl: string;

	protected user: UserModel;

	constructor(
		protected usersService: UsersService,
		protected userService: UserService,
		protected propertyService: PropertyService,
		protected emailService: EmailService,
		protected activatedRoute: ActivatedRoute,
		protected toastrService: ToastrService,
		protected dialog: MatDialog,
		protected iconService: IconService,
		protected routerService: RouterService,
		protected router: Router,
	) {}

	public ngOnInit() {
		this.user = this.userService.getUser();
		if (!this.user.preferences) {
			this.user.preferences = new UserPreferencesModel();
		}
		this.customMessage = this.user.preferences.propertySharingMessage || '';
		this.shouldAddSignature = this.user.preferences.shouldAddSignature;

		this.backToUrl = this.routerService.getPreviousUrl();

		this.toggleSignature(this.shouldAddSignature);
		this.loading = true;
		combineLatest([this.activatedRoute.params, this.usersService.getAllUsers()])
			.subscribe(([params, users]) => {

				this.propertyService.getDescription(params['id']).subscribe(desc => {
					this.description = desc;
				});

				this.propertyService.getImages(params['id']).subscribe(images => {
					this.selectableImages = images;
					this.customImage = this.selectableImages.find(image => image.featured);
				});

				this.propertyId = params['id'];
				this.users = users.filter((user: UserListModel) => user.active);
				this.fromUser = this.user.userId;
				this.loading = false;
			});

		if (StorageService.has('emails')) {
			this.recipientList = StorageService.get('emails');
			this.stepper.selectedIndex = 1;
		}
	}

	public ngAfterViewInit(): void {
		this.prospectSearch.getResultsColumns().splice(1, 0, {
			allowHtml: true,
			columnDef: 'emailIcon',
			title: 'Email',
		});

		this.emailInput.nativeElement.focus();
	}

	public goBack(stepper: MatStepper) {
		stepper.previous();
	}

	public goForward(stepper: MatStepper) {
		this.checkForDuplicateEmails();
		setTimeout(() => stepper.next());
	}

	public selected(event: ProspectSelectionEventInterface) {
		const {prospect, selected} = event;
		if (selected) {
			this.recipientList.push(prospect);
		} else {
			const index: number = this.recipientList.findIndex(p => p.id === prospect.id);
			this.recipientList.splice(index, 1);
		}
	}

	public checkForDuplicateEmails(): void {
		if (this.recipientList.length > 0) {
			let emailInList: number = 0;

			this.recipientList.forEach((recipient) => {
				if (recipient.email === this.customEmail) {
					emailInList++;
				}
			});

			if (emailInList === 0) {
				this.addEmail();
			}
		}

		if (this.recipientList.length === 0) {
			this.addEmail();
		}
	}

	public async setMailingList() {
		this.checkForDuplicateEmails();

		if (!await this.confirmImage()) {
			return;
		}

		this.loading = true;
		this.emailService.shareListing(
			+this.propertyId,
			this.recipientList,
			this.fromUser,
			this.customImage ? this.customImage.original : null,
			this.customMessage,
			this.description,
		).finally(() => {
			this.loading = false;
		}).subscribe(() => {
				this.toastrService.success('Successfully shared listing');
				this.router.navigate([this.backToUrl]);
			},
			(error) => {
				this.toastrService.error(error);
			},
		);

		if (StorageService.has('emails')) {
			StorageService.remove('emails');
		}
	}

	public previewEmail() {
		this.loading = true;
		const tab: Window = window.open('/loading');
		this.emailService.previewListingEmail(this.propertyId, this.customImage ? this.customImage.original : null, this.customMessage, this.description)
			.finally(() => {
				this.loading = false;
			})
			.subscribe(({html}) => {
				const file = new Blob([html], {type: 'text/html;charset=utf-8'});
				tab.location.replace(URL.createObjectURL(file));
			});
	}

	public setImage(image) {
		this.customImage = image;
	}

	public addEmail() {
		if (!this.customEmail) {
			return;
		}

		if (this.recipientList.findIndex(x => x.email === this.customEmail) === -1) {
			const tempProspect: ProspectListModel = {
				id: 0,
				code: 'EMAILONLY',
				status: '',
				salutation: '',
				firstName: '',
				lastName: '',
				middleInitial: '',
				company: '',
				routedToId: 0,
				workPhone: '',
				extension: '',
				homePhone: '',
				cellPhone: '',
				faxNumber: '',
				address1: '',
				address2: '',
				city: '',
				state: '',
				zipcode: '',
				email: this.customEmail,
				nextContactDate: '',
			};
			this.customEmail = '';
			this.recipientList.push(tempProspect);
		}
	}

	public toggleSignature(checked: boolean): void {
		const parser = new DOMParser();
		const doc = parser.parseFromString(this.customMessage, 'text/html');

		if (checked) {
			this.addSignature(doc);
		} else {
			this.removeSignature(doc);
		}

		this.customMessage = doc.body.innerHTML;
	}

	public dataTransformation = (prospect: ProspectListModel): void => {
		const doNotEmailIcon = this.iconService.getDoNotEmailIcon();
		const noEmailAddressIcon = this.iconService.getNoEmailAddressIcon();
		const emailIcon = this.iconService.getEmailIcon();

		if (!prospect.canSendEmail) {
			(prospect as any).emailIcon = doNotEmailIcon;
		} else if (!prospect.email) {
			(prospect as any).emailIcon = noEmailAddressIcon;
		} else {
			(prospect as any).emailIcon = emailIcon;
		}

		(prospect as any).disabled = !prospect.canSendEmail || !prospect.email;
	};

	protected addSignature(doc: Document): void {
		const spacer = doc.createElement('div');
		spacer.innerHTML = '&nbsp;';

		const signature = doc.createElement('div');
		signature.id = 'emailSignature';
		signature.innerHTML = this.user.preferences.emailSignature;

		doc.body.appendChild(spacer.cloneNode());
		doc.body.appendChild(signature);
		doc.body.appendChild(spacer.cloneNode());
	}

	protected removeSignature(doc: Document): void {
		const signature = doc.getElementById('emailSignature');
		if (signature) {
			const previousSibling = signature.previousElementSibling;
			if (previousSibling && previousSibling.innerHTML === '&nbsp;') {
				previousSibling.parentNode.removeChild(previousSibling);
			}
			const nextSibling = signature.nextElementSibling;
			if (nextSibling && nextSibling.innerHTML === '&nbsp;') {
				nextSibling.parentNode.removeChild(nextSibling);
			}
			signature.parentNode.removeChild(signature);
		}
	}

	protected confirmImage() {
		return new Promise((resolve: any) => {
			if (!this.customImage) {

				const dialog = this.dialog.open(EmailSendConfirmDialogComponent, {
					width: '600px',
				});

				dialog.afterClosed().subscribe(result => {
					if (result === 'Ok') {
						resolve(true);
					} else {
						resolve(false);
					}
				});
			} else {
				resolve(true);
			}
		});
	}

}
