import { AfterViewInit, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { PropertyListModel } from '@thomas-duke-co/reis-shared';
import { PropertySelectionEventInterface } from '../../interface/property-selection-event.interface';

@Component({
	templateUrl: './property-search-dialog.component.pug',
	styleUrls: ['./property-search-dialog.component.scss'],
})
export class PropertySearchDialogComponent implements AfterViewInit {

	public data: {
		multiple: boolean,
	};
	public disableAnimation: boolean = true;

	protected selectedProperties: PropertyListModel[] = [];

	constructor(public dialogRef: MatDialogRef<PropertySearchDialogComponent>, @Inject(MAT_DIALOG_DATA) data) {
		this.data = data;
	}

	public ngAfterViewInit(): void {
		setTimeout(() => this.disableAnimation = false);
	}

	public close(): void {
		this.dialogRef.close(this.selectedProperties);
	}

	public propertySelected(event: PropertySelectionEventInterface) {
		if (!this.data.multiple) {
			this.dialogRef.close(event.property);
			return;
		}

		if (event.selected) {
			this.selectedProperties.push(event.property);
		} else {
			const index: number = this.selectedProperties.findIndex(p => p.id === event.property.id);
			if (index !== -1) {
				this.selectedProperties.splice(index, 1);
			}
		}
	}

}
