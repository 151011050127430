import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ProspectRemarkModel, UserModel, ValueInterface } from '@thomas-duke-co/reis-shared';
import { UsersService } from '../../../../admin/service/users.service';

@Component({
	selector: 'remark-dialog',
	templateUrl: './remark-dialog.component.pug',
	styleUrls: ['./remark-dialog.component.scss'],
})
export class RemarkDialogComponent implements AfterViewInit {

	public data: {
		action: string,
		agents: ValueInterface[],
		canEdit: boolean,
		canDelete: boolean,
		editMode: boolean,
		remark: ProspectRemarkModel,
		shouldUpdateLastCalledDate: boolean,
		isAdmin: boolean,
		isRoutedTo: boolean,
	};

	public completed: boolean;
	public currentDate = new Date();

	@ViewChild('comments', {read: ElementRef, static: true})
	private commentsControl: ElementRef;

	constructor(@Inject(MAT_DIALOG_DATA) data,
		private changeDetRef: ChangeDetectorRef,
		protected usersService: UsersService) {
		this.data = data;
	}

	ngAfterViewInit() {
		this.commentsControl.nativeElement.focus();
		this.changeDetRef.detectChanges();

		this.getInactiveAgents();
	}

	public getInactiveAgents(): void {
		const agent: number = this.data.remark.userId;

		if (agent && !this.agentInValues(agent)) {
			this.usersService.getUser(agent.toString()).subscribe((user: UserModel) => {
				this.data.agents.push({id: agent, name: `${user.firstName} ${user.lastName} (INACTIVE)`});
			});
		}
	}

	private agentInValues(id: number): boolean {
		return !!this.data.agents.find((agent: ValueInterface) => agent.id === id);
	}
}
