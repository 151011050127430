import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material';
import { ValueInterface } from '@thomas-duke-co/reis-shared';

@Component({
	selector: 'chart-container',
	templateUrl: './chart-container.component.pug',
	styleUrls: ['./chart-container.component.scss'],
})
export class ChartContainerComponent {

	public containerTitle = '';

	@Input()
	set title(title) {
		this.containerTitle = title;
	}

	@Input()
	public expandable = true;

	@Input()
	public dropdown: ValueInterface[];

	@Output()
	public expandEvent: EventEmitter<any> = new EventEmitter();

	@Output()
	public dropdownChangeEvent: EventEmitter<number | string> = new EventEmitter();

	@Input()
	public menuClickedCallback: Function;

	public menuClicked(): void {
		this.menuClickedCallback();
	}

	public expand(): void {
		this.expandEvent.emit();
	}

	public dropdownChanged(event: MatSelectChange): void {
		this.dropdownChangeEvent.emit(event.value);
	}

	public getSelectedDropdownValue(): string | number | boolean | null {
		const selectedOption = this.dropdown.find(option => !!option.selected);
		if (!selectedOption) {
			return null;
		}
		return selectedOption.id;
	}

}
