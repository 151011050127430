import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../service/settings.service';
import { SystemSettingsModel } from '@thomas-duke-co/reis-shared';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { PropertyService } from '../../../property/service/property.service';
import { YesNoDialogComponent } from '../../../../component/yes-no-dialog/yes-no-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
	templateUrl: './system-settings.page.pug',
	styleUrls: ['./system-settings.page.scss'],
})
export class SystemSettingsPage implements OnInit {

	public loading = false;
	public settings: SystemSettingsModel;
	public formGroup: FormGroup;

	constructor(
		protected settingsService: SettingsService,
		protected fb: FormBuilder,
		protected toastrService: ToastrService,
		protected propertyService: PropertyService,
		protected yesNoDialog: MatDialog,
	) {}

	public ngOnInit(): void {
		this.loading = true;
		this.settingsService.getSystemSettings().subscribe(settings => {
			this.settings = settings;

			this.createFormGroup();
			this.loading = false;
		});
	}

	public saveChanges(): void {
		if (this.formGroup.invalid) {
			Object.keys(this.formGroup.controls).forEach(field => {
				this.formGroup.get(field).markAsTouched({onlySelf: true});
			});

			return;
		}

		this.settingsService.setSystemSettings(this.formGroup.value).subscribe(
			() => {
				this.toastrService.success('Successfully updated system settings');
			},
			response => {
				this.toastrService.error(response.error.message);
			},
		);
	}

	public async indexAllProperties(): Promise<void> {
		if (!await this.confirmIndexAllProperties()) {
			return;
		}
		return this.propertyService.indexAllProperties();
	}

	protected confirmIndexAllProperties(): Promise<boolean> {
		return new Promise<boolean>((resolve: (result: boolean) => void): void => {
			this.yesNoDialog
				.open(YesNoDialogComponent, {
					data: {
						title: 'Index All Properties?',
						question: 'Indexing all properties will take a long time, and properties will not be searchable during this time. Do you want to continue?',
						allowCancel: false,
					},
				})
				.afterClosed()
				.subscribe(result => resolve(result === 'Yes'));
		});
	}

	protected createFormGroup() {
		if (!this.settings) {
			this.settings = {
				priceVariance: 10,
				sizeVariance: 10,
			};
		}

		this.formGroup = this.fb.group({
			priceVariance: [this.settings.priceVariance, Validators.pattern('^-?\\d*$')],
			sizeVariance: [this.settings.sizeVariance, Validators.pattern('^-?\\d*$')],
		});
	}

}
