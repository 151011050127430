import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {SystemSettingsModel} from '@thomas-duke-co/reis-shared';
import {environment} from '../../../../environments/environment';

@Injectable()
export class SettingsService {

    constructor(protected http: HttpClient) {}

    public getSystemSettings(): Observable<SystemSettingsModel> {
        return this.http.get<SystemSettingsModel>(`${environment.apiUrl}/settings/system`);
    }

    public setSystemSettings(settings: SystemSettingsModel): Observable<void> {
        return this.http.put<void>(`${environment.apiUrl}/settings/system`, settings);
    }

}
