import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { ProspectSearchDto, SavedSearchModel, UserModel, ValueInterface, ValuesModel } from '@thomas-duke-co/reis-shared';
import { TableColumnInterface } from '../table/interface/table-column.interface';
import { UserService } from '../../service/user.service';
import { UsersService } from '../../module/admin/service/users.service';
import { SavedSearchService } from '../../service/saved-search.service';
import { ProspectAdvancedSearchComponent } from '../prospect-advanced-search/prospect-advanced-search.component';
import { PropertyService } from '../../module/property/service/property.service';
import { DeleteDialogComponent } from '../deleteDialog/delete-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

@Component({
	selector: 'prospect-saved-search',
	templateUrl: './prospect-saved-search.component.pug',
	styleUrls: ['./prospect-saved-search.component.scss'],
})
export class ProspectSavedSearchComponent implements OnInit {

	@Input() protected valuesModel: ValuesModel;

	@Output() protected search: EventEmitter<SavedSearchModel<ProspectSearchDto>> = new EventEmitter();
	@Output() protected edit: EventEmitter<SavedSearchModel<ProspectSearchDto>> = new EventEmitter();

	public dataSource: MatTableDataSource<SavedSearchModel<ProspectSearchDto>>;
	public displayedColumns: TableColumnInterface[] = [
		{columnDef: 'name', title: 'Name'},
		{columnDef: 'publicPrivate', title: 'Shared/Private'},
		{columnDef: 'details', title: 'Details', allowHtml: true},
		{columnDef: 'wants', title: 'Wants', allowHtml: true},
		{columnDef: 'haves', title: 'Has', allowHtml: true},
		{columnDef: 'shown', title: 'Shown', allowHtml: true},
		{
			title: 'Actions',
			pipe: 'actions',
			buttons: [
				{
					fontSet: 'far',
					fontIcon: 'fa-pencil',
					tooltip: 'Edit',
					callback: (row: {savedSearch: SavedSearchModel<ProspectSearchDto>}): void => {
						this.edit.emit(row.savedSearch);
					},
				},
				{
					fontSet: 'far',
					fontIcon: 'fa-trash-alt',
					color: 'warn',
					tooltip: 'Delete',
					callback: (row: {savedSearch: SavedSearchModel<ProspectSearchDto>}): void => {
						// noinspection JSIgnoredPromiseFromCall
						this.deleteSavedSearch(row.savedSearch);
					},
					condition: (row: {savedSearch: SavedSearchModel<ProspectSearchDto>}): boolean => {
						return !row.savedSearch.isPublic || this.userService.hasRole('Admin');
					},
				},
			],
		},
	];

	protected user: UserModel;
	protected values: {[key: string]: ValueInterface[]};

	constructor(
		protected userService: UserService,
		protected usersService: UsersService,
		protected savedSearchService: SavedSearchService<ProspectSearchDto>,
		protected propertyService: PropertyService,
		protected deleteDialog: MatDialog,
		protected toastrService: ToastrService,
	) {
		this.user = this.userService.getUser();
	}

	public ngOnInit(): void {
		this.usersService.getAllUsers()
			.subscribe(users => {
				const userValues = users.map(user => ({id: user.userId, name: user.firstName + ' ' + user.lastName}));
				const yesNoValues = [{id: true, name: 'Yes'}, {id: false, name: 'No'}];

				this.values = {
					inputtedByIds: userValues,
					routedToIds: userValues,
					operatorIds: userValues,
					sender: userValues,
					prospectTypeIds: this.valuesModel.prospectType,
					financialClassIds: this.valuesModel.financialClass,
					propertyCategoryIds: this.valuesModel.propertyCategory,
					callSourceIds: this.valuesModel.callSource,
					countyIds: this.valuesModel.county,
					cityIds: this.valuesModel.city,
					roadIds: this.valuesModel.road,
					hasEmail: yesNoValues,
				};

				// noinspection JSIgnoredPromiseFromCall
				this.getSavedSearches();
			});
	}

	public getSavedSearches(): Promise<void> {
		return new Promise((resolve: () => void): void => {
			this.savedSearchService.getSavedSearches(this.user.userId, 'Prospect')
				.subscribe(async savedSearches => {
					this.dataSource = new MatTableDataSource<SavedSearchModel<ProspectSearchDto>>(
						await Promise.all<SavedSearchModel<ProspectSearchDto>>(savedSearches.map(this.savedSearchModelToSource.bind(this))),
					);
					resolve();
				});
		});
	}

	public runSearch(savedSearch: SavedSearchModel<ProspectSearchDto>): void {
		this.search.emit(savedSearch);
	}

	protected async deleteSavedSearch(savedSearch: SavedSearchModel<ProspectSearchDto>): Promise<void> {
		if (!await this.confirmDelete(savedSearch)) {
			return;
		}

		this.savedSearchService
			.deleteSavedSearch(this.user.userId, [savedSearch.savedSearchID])
			.subscribe(
				async () => {
					await this.getSavedSearches();
					this.toastrService.success('Saved Search successfully deleted.');
				},
				(error: HttpErrorResponse) => this.toastrService.error(error.error.message),
			);
	}

	protected confirmDelete(savedSearch: SavedSearchModel<ProspectSearchDto>): Promise<boolean> {
		return new Promise((resolve: (result: boolean) => void): void => {
			this.deleteDialog
				.open(DeleteDialogComponent, {
					width: '300px',
					data: {
						text: `your saved search, ${savedSearch.name}`,
						type: 'Saved Search',
					},
				})
				.afterClosed()
				.subscribe(result => resolve(result === 'Delete'));
		});
	}

	protected async savedSearchModelToSource(savedSearch: SavedSearchModel<ProspectSearchDto>): Promise<any> {
		this.removeNulls(savedSearch);

		const row: any = {
			savedSearch: new SavedSearchModel<ProspectSearchDto>(JSON.parse(JSON.stringify(savedSearch))),
			savedSearchID: savedSearch.savedSearchID,
			userID: savedSearch.userID,
			name: savedSearch.name,
			publicPrivate: savedSearch.isPublic ? 'Shared' : 'Private',
		};

		if (!savedSearch.hasOwnProperty('filters')) {
			return row;
		}

		const filters: ProspectSearchDto = Object.assign({}, savedSearch.filters);

		for (const group of ['details', 'wants', 'haves', 'shown']) {
			if (!filters.hasOwnProperty(group)) {
				continue;
			}

			const keys: string[] = Object.keys(filters[group]).filter(key => filters[group][key] != null);

			const relativeTimeValueFields: string[] = [
				'lastModifiedFromValue',
				'lastModifiedToValue',
				'lastCalledFromValue',
				'lastCalledToValue',
				'lastContactFromValue',
				'lastContactToValue',
				'nextContactFromValue',
				'nextContactToValue',
				'dateFromValue',
				'dateToValue',
			];

			for (let i = 0; i < keys.length; i++) {
				if (relativeTimeValueFields.indexOf(keys[i]) !== -1) {
					if (filters[group][keys[i - 1]] === 'Specific Date') {
						filters[group][keys[i - 1]] = moment(filters[group][keys[i]]).format('M/D/YY');
					} else {
						filters[group][keys[i - 1]] += ` ${filters[group][keys[i]]}`;
					}
					delete keys[i];
				} else if (keys[i] === 'propertyId') {
					filters[group][keys[i]] = await this.getPropertyName(filters[group][keys[i]]);
				} else if (Object.keys(this.values).indexOf(keys[i]) !== -1) {
					if (Array.isArray(filters[group][keys[i]])) {
						filters[group][keys[i]] = filters[group][keys[i]].map(id => this.translateValueInterface(keys[i], id)).join(', ');
					} else {
						filters[group][keys[i]] = this.translateValueInterface(keys[i], filters[group][keys[i]]);
					}
				}
			}

			row[group] = keys.filter(() => true).map(key => `<b>${ProspectAdvancedSearchComponent.labels[group][key]}</b>: ${filters[group][key]}`).join('<br />');
		}

		return row;
	}

	protected translateValueInterface(key: string, id: number): string | number {
		const value = this.values[key].find(x => x.id === id);
		return value ? value.name : id;
	}

	protected getPropertyName(id: number): Promise<string> {
		return new Promise((resolve: Function): void => {
			this.propertyService.getName(id).subscribe(name => resolve(name));
		});
	}

	protected removeNulls(obj: any) {
		for (const key in obj) {
			if (!obj.hasOwnProperty(key)) {
				continue;
			}

			if (obj[key] == null) {
				delete obj[key];
			} else if (typeof obj[key] === 'object') {
				this.removeNulls(obj[key]);
				if (Object.keys(obj[key]).length === 0) {
					delete obj[key];
				}
			}
		}
	}

}
