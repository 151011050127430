import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog, MatSelect } from '@angular/material';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {
	PaginationDto,
	PropertyListModel,
	PropertySearchDto,
	ProspectSearchDto,
	SavedSearchModel,
	UserListModel,
	UserModel,
	ValueInterface,
	ValuesModel,
} from '@thomas-duke-co/reis-shared';
import { UsersService } from '../../module/admin/service/users.service';
import { ValuesService } from '../../service/values.service';
import { SearchService } from '../../module/property/service/search.service';
import { UserService } from '../../service/user.service';
import { SearchPresetDialogComponent } from '../search-preset-dialog/search-preset-dialog.component';
import { SavedSearchService } from '../../service/saved-search.service';
import { YesNoDialogComponent } from '../yes-no-dialog/yes-no-dialog.component';
import { ProspectSearchService } from '../../module/prospect/service/prospect-search.service';
import { createNumberMask } from 'text-mask-addons';
import { forkJoin } from 'rxjs';

@Component({
	selector: 'prospect-advanced-search',
	templateUrl: './prospect-advanced-search.component.pug',
	styleUrls: ['./prospect-advanced-search.component.scss'],
})
export class ProspectAdvancedSearchComponent {

	public static readonly labels: {[key: string]: {[key2: string]: string}} = {
		details: {
			code: 'Prospect Number',
			statuses: 'Status',
			title: 'Title',
			salutation: 'Salutation',
			firstName: 'First Name',
			middleInitial: 'Middle Initial',
			lastName: 'Last Name',
			spouseName: 'Spouse Name',
			assistant: 'Assistant',
			company: 'Company',
			inputtedByIds: 'Inputted By',
			routedToIds: 'Routed To',
			operatorIds: 'Operator',
			callSourceIds: 'Call Sources',
			hasEmail: 'Has Email?',
			canSendEmail: 'Can Send Email?',
			canSendLetter: 'Can Send Letter?',
			email: 'Email',
			website: 'Website',
			workPhone: 'Work Phone',
			workExtension: 'Work Extension',
			pager: 'Pager',
			faxNumber: 'Fax Number',
			homePhone: 'Home Phone',
			cellPhone: 'Cell Phone',
			address1: 'Address Line 1',
			address2: 'Address Line 2',
			city: 'City',
			state: 'State',
			zipcode: 'Zip Code',
			prospectTypeIds: 'Type',

			lastModifiedFromRelation: 'Last Modified From',
			lastModifiedFromValue: '',
			lastModifiedToRelation: 'Last Modified To',
			lastModifiedToValue: '',

			lastCalledFromRelation: 'Last Called From',
			lastCalledFromValue: '',
			lastCalledToRelation: 'Last Called To',
			lastCalledToValue: '',

			lastContactFromRelation: 'Last Contact From',
			lastContactFromValue: '',
			lastContactToRelation: 'Last Contact To',
			lastContactToValue: '',

			nextContactFromRelation: 'Next Contact From',
			nextContactFromValue: '',
			nextContactToRelation: 'Next Contact To',
			nextContactToValue: '',

			building: 'Building',
			newsletter: 'Newsletter',
			personalMail: 'Personal Mail',
			sicCode: 'SIC Code',
		},
		wants: {
			financialClassIds: 'Financial Class',
			propertyCategoryIds: 'Property Category',
			minPrice: 'Minimum Price',
			maxPrice: 'Maximum Price',
			minSize: 'Minimum Size',
			maxSize: 'Maximum Size',
			countyIds: 'County',
			cityIds: 'City',
			roadIds: 'Road',
		},
		haves: {
			parcelId: 'Parcel ID',
			types: 'Type',
			financialClassIds: 'Financial Class',
			propertyName: 'Property Name',
			streetName: 'Street Name',
			streetNumber: 'Street Number',
			zoning: 'Zoning',
			listingBroker: 'Listing Broker',
			propertyCategoryIds: 'Property Category',
			minPrice: 'Minimum Price',
			maxPrice: 'Maximum Price',
			minSize: 'Minimum Size',
			maxSize: 'Maximum Size',
			countyIds: 'County',
			cityIds: 'City',
			roadIds: 'Road',
			propertyManagement: 'Property Management',
		},
		shown: {
			propertyCode: 'Property Code',
			propertyName: 'Property Name',
			propertyId: 'Property',
			sender: 'Sender',

			dateFromRelation: 'From',
			dateFromValue: '',
			dateToRelation: 'To',
			dateToValue: '',
		},
	};

	protected user: UserModel;

	public properties: PaginationDto<PropertyListModel>;
	public users: UserListModel[];
	public routedToAgents: ValueInterface[];
	public operatorAgents: ValueInterface[];
	public inputtedByAgents: ValueInterface[];
	public prospectTypes: ValueInterface[];
	public financialClasses: ValueInterface[];
	public propertyCategories: ValueInterface[];
	public callSources: ValueInterface[];
	public propertyStatuses: ValueInterface[];
	public counties: ValueInterface[];
	public cities: ValueInterface[];
	public roads: ValueInterface[];
	public prospectSearchFormGroup: FormGroup;
	public propertySearchFormGroup: FormGroup;
	public hasTypes = require('./has-types.json');
	public propertiesLoading = false;

	@Output() public search: EventEmitter<Partial<ProspectSearchDto>> = new EventEmitter<Partial<ProspectSearchDto>>();
	@Output() public presetSave: EventEmitter<SavedSearchModel<ProspectSearchDto>> = new EventEmitter<SavedSearchModel<ProspectSearchDto>>();
	@Output() public reset: EventEmitter<any> = new EventEmitter<any>();

	@Input() public currentPreset: SavedSearchModel<ProspectSearchDto>;

	@ViewChild('code', {static: true}) public code: ElementRef;
	@ViewChild('propertyDropdown', {static: true}) public propertyDropdown: MatSelect;
	@ViewChild('firstNameInput', {static: true}) public firstNameInput: ElementRef;

	public zipcodeMask = {
		mask: [/[1-9]/, /\d/, /\d/, /\d/, /\d/], // #####
		placeholderChar: '#',
	};

	public phoneMask = {
		mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
		showMask: true,
		placeholderChar: '_',
	};

	public currencyMask = {
		mask: createNumberMask({
			allowDecimal: true,
		}),
		guide: false,
		keepCharPositions: true,
	};

	public numberMask = {
		mask: createNumberMask({
			prefix: '',
			allowDecimal: true,
		}),
		guide: false,
		keepCharPositions: true,
	};

	public states = require('../../../assets/states.json');

	constructor(
		protected usersService: UsersService,
		protected valuesService: ValuesService,
		protected propertySearchService: SearchService,
		protected userService: UserService,
		protected fb: FormBuilder,
		protected searchPresetService: SavedSearchService<ProspectSearchDto>,
		protected searchPresetDialog: MatDialog,
		protected toastrService: ToastrService,
		protected yesNoDialog: MatDialog,
	) {
		this.user = this.userService.getUser();

		forkJoin(this.usersService.getAllUsers(), this.valuesService.getValues())
			.subscribe(([users, values]: [UserListModel[], ValuesModel]) => {
				this.prospectTypes = values.prospectType;
				this.financialClasses = values.financialClass;
				this.propertyCategories = values.propertyCategory;
				this.callSources = values.callSource;
				this.propertyStatuses = values.propertyStatus;
				this.counties = values.county;
				this.cities = values.city;
				this.roads = values.road;
				this.routedToAgents = values.routedToAgent;
				this.operatorAgents = values.operatorAgent;
				this.inputtedByAgents = values.inputtedByAgent;
				this.users = users.filter(user => user.active);
			});

		this.buildForms();
	}

	public getProperties() {
		const searchDto: PropertySearchDto = this.propertySearchFormGroup.value;
		Object.keys(searchDto).forEach(key => {
			if (!searchDto[key]) {
				delete searchDto[key];
			}
		});

		if (Object.keys(searchDto).length > 0) {
			this.propertiesLoading = true;
			this.propertySearchService.search(searchDto, 0, 100)
				.finally(() => {
					this.propertiesLoading = false;
				})
				.subscribe((properties: PaginationDto<PropertyListModel>) => {
					this.properties = properties;
					setTimeout(() => this.propertyDropdown.open());
				});
		}
	}

	public prospectSearch() {
		const searchDto: Partial<ProspectSearchDto> = ProspectSearchService.prepareSearchDto(this.prospectSearchFormGroup.value);
		this.search.emit(searchDto);
	}

	public resetFilters() {
		this.buildForms();
		this.properties = null;
		this.reset.emit(event);
	}

	public getFormControl(group: string, control: string): FormControl {
		const formGroup: FormGroup = this.prospectSearchFormGroup.controls[group] as FormGroup;
		return formGroup.controls[control] as FormControl;
	}

	public unmask(control: FormControl) {
		control.setValue(control.value.replace(/[$,]/g, ''));
	}

	public unmaskPhoneNumber(control: FormControl): void {
		control.setValue(control.value.replace(/\D/g, ''));
	}

	public async savePresetClicked(): Promise<void> {
		let presetToEdit: SavedSearchModel<ProspectSearchDto>;
		if (this.currentPreset && (!this.currentPreset.isPublic || this.userService.hasRole('Admin'))) {
			const shouldOverwrite: string = await this.askToOverwrite(this.currentPreset);

			if (shouldOverwrite === 'Cancel') {
				return;
			}

			if (shouldOverwrite === 'Yes') {
				presetToEdit = this.currentPreset;
			}
		}

		this.showSearchPresetDialog(presetToEdit);
	}

	protected askToOverwrite(searchPreset: SavedSearchModel<ProspectSearchDto>): Promise<string> {
		return new Promise((resolve: (result: string) => void): void => {
			this.yesNoDialog
				.open(YesNoDialogComponent, {
					data: {
						title: 'Overwrite Search Preset?',
						question: `Do you want to overwrite your search preset, ${searchPreset.name}?`,
						allowCancel: true,
					},
				})
				.afterClosed()
				.subscribe(result => resolve(result));
		});
	}

	protected showSearchPresetDialog(searchPreset?: SavedSearchModel<ProspectSearchDto>): void {
		let action: string = 'Edit';
		if (!searchPreset) {
			action = 'Add';
			searchPreset = new SavedSearchModel<ProspectSearchDto>({
				userID: this.user.userId,
				type: 'Prospect',
				isPublic: false,
			});
		}

		searchPreset.filters = this.prospectSearchFormGroup.value;

		this.searchPresetDialog
			.open(SearchPresetDialogComponent, {
				width: '300px',
				data: {
					searchPreset,
					action,
				},
			})
			.afterClosed()
			.subscribe((result: string | SavedSearchModel<ProspectSearchDto>): void => {
				if (!(result instanceof SavedSearchModel)) {
					return;
				}

				this.searchPresetService.saveSearch(result).subscribe(
					(id: number) => {
						result.savedSearchID = id;
						this.presetSave.emit(result);
						this.toastrService.success('Search Preset successfully saved.');
					},
					(error: HttpErrorResponse) => this.toastrService.error(error.error.message),
				);
			});
	}

	protected buildForms() {
		this.prospectSearchFormGroup = this.fb.group({
			details: this.fb.group({
				code: [],
				statuses: [['ACTIVE']],
				title: [],
				salutation: [],
				firstName: [],
				middleInitial: [],
				lastName: [],
				spouseName: [],
				assistant: [],
				company: [],
				inputtedByIds: [],
				routedToIds: [],
				operatorIds: [],
				callSourceIds: [],
				hasAddress: [],
				hasPhone: [],
				hasEmail: [],
				canSendEmail: [],
				canSendLetter: [],
				email: [],
				website: [],
				workPhone: [],
				workExtension: [],
				pager: [],
				faxNumber: [],
				homePhone: [],
				cellPhone: [],
				address1: [],
				address2: [],
				city: [],
				state: [],
				zipcode: [],
				prospectTypeIds: [],

				lastModifiedFromRelation: [],
				lastModifiedFromValue: [],
				lastModifiedToRelation: [],
				lastModifiedToValue: [],

				lastCalledFromRelation: [],
				lastCalledFromValue: [],
				lastCalledToRelation: [],
				lastCalledToValue: [],

				lastContactFromRelation: [],
				lastContactFromValue: [],
				lastContactToRelation: [],
				lastContactToValue: [],

				nextContactFromRelation: [],
				nextContactFromValue: [],
				nextContactToRelation: [],
				nextContactToValue: [],

				building: [],
				newsletter: [],
				personalMail: [],
				sicCode: [],
			}),
			wants: this.fb.group({
				financialClassIds: [],
				propertyCategoryIds: [],
				minPrice: [],
				maxPrice: [],
				minSize: [],
				maxSize: [],
				countyIds: [],
				cityIds: [],
				roadIds: [],
			}),
			haves: this.fb.group({
				parcelId: [],
				types: [],
				financialClassIds: [],
				propertyName: [],
				streetName: [],
				streetNumber: [],
				zoning: [],
				listingBroker: [],
				propertyCategoryIds: [],
				minPrice: [],
				maxPrice: [],
				minSize: [],
				maxSize: [],
				countyIds: [],
				cityIds: [],
				roadIds: [],
				propertyManagement: [],
			}),
			shown: this.fb.group({
				propertyCode: [],
				propertyName: [],
				propertyId: [],
				sender: [],

				dateFromRelation: [],
				dateFromValue: [],
				dateToRelation: [],
				dateToValue: [],
			}),
		});

		this.propertySearchFormGroup = this.fb.group({
			keywords: [],
			code: [],
			statusId: [SearchService.DEFAULT_STATUSES],
		});
	}

}
