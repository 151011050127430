import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PaginationDto, ProspectHasModel, ValuesModel } from '@thomas-duke-co/reis-shared';
import { MatTableDataSource } from '@angular/material';
import { ValuesService } from '../../../service/values.service';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Service } from '../../../service';

@Injectable()
export class ProspectHasService extends Service {

	public static readonly TYPES = [
		{id: 'O Owner', name: 'OWNER'},
		{id: 'S Seller', name: 'SELLER'},
		{id: 'T Tenant', name: 'TENANT'},
	];

	constructor(protected http: HttpClient, protected valuesService: ValuesService) {
		super();
	}

	public moveHas(oldProspectId: number, hasId: number, newProspectId: number): Observable<ProspectHasModel> {
		return this.http.put<ProspectHasModel>(`${environment.apiUrl}/has/${oldProspectId}/moveHas`, {
			has: hasId,
			newProspect: newProspectId,
		});
	}

	public searchHas(search: Partial<ProspectHasModel>): Observable<ProspectHasModel[]> {
		return this.http.get<ProspectHasModel[]>(`${environment.apiUrl}/has/search`, {params: this.buildHttpParams(search)});
	}

	public async modelsToDataSource(models: ProspectHasModel[], values: ValuesModel): Promise<MatTableDataSource<ProspectHasModel>> {
		return new MatTableDataSource<ProspectHasModel>(models.map((model) => {
			return new ProspectHasModel(model, values);
		}));
	}

	public getProspectHas(
		id: number,
		currentPage?: number,
		pageSize?: number,
	): Observable<PaginationDto<ProspectHasModel>> {
		const params = new HttpParams()
			.append('currentPage', `${currentPage}`)
			.append('pageSize', `${pageSize}`);
		return this.http.get<PaginationDto<ProspectHasModel>>(`${environment.apiUrl}/has/${id}`, {params});
	}

	public addHas(has: ProspectHasModel): Observable<any> {
		return this.http.post(`${environment.apiUrl}/has/add`, has);
	}

	public updateHas(has: ProspectHasModel): Observable<any> {
		return this.http.put(`${environment.apiUrl}/has/update`, has);
	}

	public removeHas(hasId: number, prospectId: number): Observable<any> {
		return this.http.delete(`${environment.apiUrl}/has/${hasId}/prospect/${prospectId}`);
	}

}
