import { Component, OnInit, Input } from '@angular/core';
import { TableColumnInterface } from '../../../../component/table/interface/table-column.interface';
import { SummaryService } from '../../service/summary.service';
import { MatTableDataSource } from '@angular/material';
import { UserModel } from '@thomas-duke-co/reis-shared';

@Component({
	selector: 'is2-property-status-summary',
	templateUrl: './property-status-summary.component.pug',
	styleUrls: ['./property-status-summary.component.scss'],
})

export class PropertyStatusSummaryComponent implements OnInit {

	protected _selectedUser: number;
	@Input() set selectedUser(selectedUser: number) {
		this._selectedUser = selectedUser;
		if (selectedUser) {
			this.ngOnInit();
		}
	}

	get selectedUser(): number {
		return this._selectedUser;
	}

	protected _user: UserModel;
	@Input() set user(user: UserModel) {
		this._user = user;
	}

	get user(): UserModel {
		return this._user;
	}

	@Input() propertySummaryPage: boolean = false;

	public statusSummaryDatasource: MatTableDataSource<{status: string, count: number}>;
	public statusSummaryColumns: TableColumnInterface[] = [{
		columnDef: 'status',
		title: 'Status',
	}, {
		columnDef: 'count',
		align: 'right',
		title: 'Count',
	}];
	public loading: boolean = false;
	public isAdmin: boolean = false;

	constructor(
		protected summaryService: SummaryService,
	) {}

	public ngOnInit(): void {
		this.loading = true;

		this.user.roles.map((x) => {
			if (x === 'Admin') {
				this.isAdmin = true;
			}
		});

		if (this.user && !this.propertySummaryPage && !this.selectedUser) {
			this.getPropertyStatusCount(this.user.userId);
		}

		if (this.propertySummaryPage && this.selectedUser) {
			this.getPropertyStatusCount(this.selectedUser);
		}

		if (this.propertySummaryPage && !this.selectedUser) {
			this.getPropertyStatusCount();
		}
	}

	public getPropertyStatusCount(id?: number): void {
		if (id) {
			this.summaryService.getPropertyStatusSummary(id).subscribe((statusCount: {status: string, count: number}[]) => {
				if (this.isAdmin) {
					this.setData(statusCount);
				} else {
					this.setData(statusCount, true);
				}
			});
		} else {
			this.summaryService.getPropertyStatusSummary().subscribe((statusCount: {status: string, count: number}[]) => {
				if (this.isAdmin) {
					this.setData(statusCount);
				} else {
					this.setData(statusCount, true);
				}
			});
		}
		this.loading = false;
	}

	protected setData(statusCount: {status: string, count: number}[], active?: boolean): void {
		if (active) {
			const activeStatusCount = [];
			statusCount.map((status) => {
				if (status.status === 'EXPIRED' || status.status === 'ACTIVE' || status.status === 'UNDER CONTRACT') {
					activeStatusCount.push(status);
				}
			});

			this.statusSummaryDatasource = new MatTableDataSource<{
				status: string,
				count: number
			}>(activeStatusCount);
		} else {
			this.statusSummaryDatasource = new MatTableDataSource<{
				status: string,
				count: number
			}>(statusCount);
		}
	}

}
