import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { TaskService } from '../../service/task.service';
import { MatDialog } from '@angular/material';
import { TaskGroupTemplateModel, TaskTemplateModel, UserListModel, ValueInterface } from '@thomas-duke-co/reis-shared';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material';
import { TableColumnInterface } from '../table/interface/table-column.interface';
import { TaskTemplateDialogComponent } from '../task-template-dialog/task-template-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { DeleteDialogComponent } from '../deleteDialog/delete-dialog.component';
import { Subject } from 'rxjs';

@Component({
	selector: 'task-template-list',
	templateUrl: './task-template-list.component.pug',
	styleUrls: ['./task-template-list.component.scss'],
})
export class TaskTemplateListComponent implements OnInit {

	protected _taskGroupTemplate: TaskGroupTemplateModel;
	@Input('taskGroupTemplate') set taskGroupTemplate(data: TaskGroupTemplateModel) {
		this._taskGroupTemplate = data;
		if (this.labelNames && data) {
			this.ngOnInit();
		}
	}

	get taskGroupTemplate(): TaskGroupTemplateModel {
		return this._taskGroupTemplate;
	}

	protected _labelNames: ValueInterface[];
	@Input('labelNames') set labelNames(labelNames: ValueInterface[]) {
		this._labelNames = labelNames;
		if (labelNames && this.taskGroupTemplate) {
			this.ngOnInit();
		}
	}

	get labelNames(): ValueInterface[] {
		return this._labelNames;
	}

	@Input() users: UserListModel[];

	@Output() public refreshData: EventEmitter<void> = new EventEmitter<void>();

	protected subject = new Subject<any>();

	public dataSource: MatTableDataSource<TaskTemplateModel>;
	public loading: boolean = false;
	public columns: TableColumnInterface[] = [
		{columnDef: 'isPrivateName', title: 'Private'},
		{columnDef: 'title', title: 'Task Title'},
		{columnDef: 'description', title: 'Description'},
		{columnDef: 'relativeStartDate', title: 'Due Date', editView: true},
		{columnDef: 'defaultAssigneeName', title: 'Default Assignee'},
		{columnDef: 'labelString', title: 'Labels'},
	];

	constructor(
		protected taskService: TaskService,
		protected toastrService: ToastrService,
		protected taskTemplateDialog: MatDialog,
		protected deleteDialog: MatDialog,
	) {}

	public ngOnInit(): void {
		if (this.taskGroupTemplate) {
			this.taskGroupTemplate.tasks.map((task: TaskTemplateModel) => {
				if (!task.defaultAssignee) {
					task.defaultAssignee = null;
				} else {
					(task as any).defaultAssigneeName = `${task.defaultAssignee.firstName} ${task.defaultAssignee.lastName}`;
				}

				(task as any).isPrivateName = task.isPrivate ? 'Yes' : 'No';

				if (task.labels.length > 0 && this.labelNames) {
					(task as any).labelString = task.labels.map(label => {
						if (label) {
							return (' ' + this.labelNames.find(x => x.id === label).name);
						}
					});
				}
			});

			this.dataSource = new MatTableDataSource<TaskTemplateModel>(this.taskGroupTemplate.tasks);
		}
	}

	public rowSelected(event: {row: TaskTemplateModel, i: number, $event: MouseEvent}): void {
		this.taskTemplateDialog.open(TaskTemplateDialogComponent, {
			width: '1000px',
			data: {
				taskTemplate: event.row,
				users: this.users,
			},
		}).afterClosed().subscribe((result: {data: TaskTemplateModel, action: string}) => {
			if (!result) {
				return;
			}

			if (result.action === 'Save') {
				this.taskService.updateTaskTemplate(result.data).subscribe((data) => {
					if (data) {
						this.toastrService.success(`Task Template Successfully Updated`);
						this.refreshData.emit();
					}
				}, (error: HttpErrorResponse) => {
					this.toastrService.error(error.error.message);
				});
			}

			if (result.action === 'Delete') {
				this.deleteDialog.open(DeleteDialogComponent, {
					width: '400px',
					data: {
						text: 'this Task Template',
						type: 'Task Template',
					},
				}).afterClosed().subscribe((response) => {
					if (response === 'Delete') {
						this.taskService.deleteTaskTemplate(result.data.id).subscribe(() => {
							this.toastrService.success(`Task Template ${result.data.id} has been deleted`);
							this.refreshData.emit();
						}, (error: HttpErrorResponse) => {
							this.toastrService.error(error.error.message);
						});
					}
				});
			}
		});
	}

}
