import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PaginationDto, CallsByUserModel, UserModel } from '@thomas-duke-co/reis-shared';
import { FormControl } from '@angular/forms';
import { ReportService } from '../../service/report.service';
import { Observable } from 'rxjs/Observable';
import { PaginationDatasource } from '../../../../datasource/pagination.datasource';
import { ActivatedRoute, Params } from '@angular/router';
import { RouterService } from '../../../../service/router.service';
import { Subscription } from 'rxjs/Subscription';
import { UsersService } from '../../../admin/service/users.service';
import { TableComponent } from '../../../../component/table/table.component';
import { map } from 'rxjs/operators';
import { IconService } from '../../../../service/icon.service';
import { TableColumnInterface } from '../../../../component/table/interface/table-column.interface';

@Component({
	templateUrl: './user-calls.page.pug',
	styleUrls: ['./user-calls.page.scss'],
})
export class UserCallsPage implements OnInit, OnDestroy {

	@ViewChild(TableComponent, {static: true})
	protected table: TableComponent;
	protected subscription: Subscription;

	public user: UserModel;
	public range: FormControl = new FormControl('week');
	public dataSource: PaginationDatasource<CallsByUserModel>;
	public totalItems: number;

	public columns: TableColumnInterface[] = [{
		allowHtml: true,
		title: 'Type',
		columnDef: 'type',
		sortable: true,
	}, {
		title: 'Date',
		columnDef: 'date',
		sortable: true,
		pipe: 'datetime',
	}, {
		title: 'Duration',
		columnDef: 'duration',
		sortable: true,
		pipe: 'duration',
	}, {
		title: 'Phone Number',
		columnDef: 'phoneNumber',
		sortable: true,
		pipe: 'phone',
	}, {
		title: 'Extension',
		columnDef: 'extension',
		sortable: true,
		pipe: 'phone',
	}, {
		title: ' Calling Party Number',
		columnDef: 'callingPartyNumber',
		sortable: true,
		pipe: 'phone',
	}, {
		title: 'Original Calling Party Number',
		columnDef: 'originalCalledPartyNumber',
		sortable: true,
		pipe: 'phone',
	}, {
		title: 'Final Called Party Number',
		columnDef: 'finalCalledPartyNumber',
		sortable: true,
		pipe: 'phone',
	}];

	constructor(
		protected reportService: ReportService,
		protected usersService: UsersService,
		protected routerService: RouterService,
		protected route: ActivatedRoute,
		protected iconService: IconService,
	) {}

	protected getData(): Observable<PaginationDto<CallsByUserModel>> {
		return this.reportService.getCallSummaryForUser(
			this.range.value,
			this.user.userId,
			this.table.paginator.pageIndex,
			this.table.paginator.pageSize,
			this.table.sort.active,
			this.table.sort.direction,
		)
			.pipe(map((paginationData: PaginationDto<CallsByUserModel>) => {
				paginationData.data = this.mapCallData(paginationData.data);
				return paginationData;
			}));
	}

	public backClicked() {
		this.routerService.navigate([`/dashboard/calls/team/${this.user.teamId === null ? 0 : this.user.teamId}`]);
	}

	public ngOnInit(): void {
		this.subscription = this.route.params
			.flatMap(({id}: Params) => this.usersService.getUser(id))
			.subscribe((user: UserModel) => {
				this.user = user;
				this.dataSource = new PaginationDatasource(this.getData.bind(this), this.table.paginator, this.table.sort, [this.range.valueChanges]);
				setTimeout(() => this.totalItems = this.dataSource.pager.data.length, 1000);
			});
	}

	public ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	public print(): void {
		window.print();
	}

	protected mapCallData(calls: CallsByUserModel[]) {
		const inboundCallIcon = this.iconService.getInboundCallIcon();
		const outboundCallIcon = this.iconService.getOutboundCallIcon();
		return calls.map(call => {
			if (call.type === 'INBOUND') {
				call.type = inboundCallIcon;
			} else if (call.type === 'OUTBOUND') {
				call.type = outboundCallIcon;
			}

			return call;
		});
	}
}
