import {Component, OnInit, ViewChild} from '@angular/core';
import {ReportService} from '../../service/report.service';
import 'rxjs/add/operator/finally';
import {BarChartData} from '@thomas-duke-co/reis-shared';
import {ChartModalComponent} from '../chart-modal/chart-modal.component';


@Component({
	selector: 'is2-prospects-by-agent',
	templateUrl: './prospects-by-agent.component.pug',
	styleUrls: ['./prospects-by-agent.component.scss'],
})
export class ProspectsByAgentComponent implements OnInit {

	@ViewChild('fullscreen', {static: true})
	private fullscreen: ChartModalComponent;

	public loading = false;
	public options: any = null;

	constructor(private reportService: ReportService) {
	}

	public ngOnInit() {
		this.loading = true;
		this.reportService.prospectsByAgent()
			.finally(() => {
				this.loading = false;
			})
			.subscribe(
				(results: BarChartData) => {

					const seriesData = [];

					for (const i in results) {
						if (!results.hasOwnProperty(i)) {
							continue;
						}
						seriesData.push({
							name: results[i].title,
							y: results[i].value,
						});
					}

					this.options = {
						chart: {
							type: 'column',
						},

						title: false,

						xAxis: {
							type: 'category',
						},

						yAxis: {
							title: {
								text: 'Prospects',
							},
						},

						credits: {
							enabled: false,
						},

						legend: {
							enabled: false,
						},

						series: [{
							name: 'Prospects',
							colorByPoint: true,
							data: seriesData,
						}],

						responsive: {
							rules: [{
								condition: {
									maxWidth: 500
								},
								chartOptions: {
									legend: {
										align: 'center',
										verticalAlign: 'bottom',
										layout: 'horizontal',
										x: 0,
										y: 0,
									},
								}
							}]
						},
					};
				},
				(error: any) => {
				},
			);
	}

	public openFullscreen() {
		this.fullscreen.show();
	}

}
