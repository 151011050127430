import { Component, Input, Output, EventEmitter } from '@angular/core';
import {
	PropertyModel, ProspectModel,
	TaskGroupModel,
	TaskOccurrenceModel,
	TaskOccurrenceAssigneeModel,
	UserModel, UserListModel, TaskGroupPropertyModel, TaskGroupProspectModel, ValueInterface, TaskOccurrenceDto,
} from '@thomas-duke-co/reis-shared';
import { MatDialog } from '@angular/material';
import { TaskService } from '../../service/task.service';
import { UsersService } from '../../module/admin/service/users.service';
import { TaskDialogComponent } from '../task-dialog/task-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { TaskGroupDialogComponent } from '../task-group-dialog/task-group-dialog.component';
import { DeleteDialogComponent } from '../deleteDialog/delete-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import { FormBuilder } from '@angular/forms';

@Component({
	selector: 'task-group-list',
	templateUrl: './task-group-list.component.pug',
	styleUrls: ['./task-group-list.component.scss'],
})

export class TaskGroupListComponent {

	protected _property: PropertyModel;
	@Input() set property(property: PropertyModel) {
		this._property = property;
		if (property) {
			this.getTaskGroups();
		}
	}

	get property(): PropertyModel {
		return this._property;
	}

	protected _prospect: ProspectModel;
	@Input() set prospect(prospect: ProspectModel) {
		this._prospect = prospect;
		if (prospect) {
			this.getTaskGroups();
		}
	}

	get prospect(): ProspectModel {
		return this._prospect;
	}

	protected _users: UserListModel[];
	@Input() set users(users: UserListModel[]) {
		this._users = users;
	}

	get users(): UserListModel[] {
		return this._users;
	}

	protected _user: UserModel;
	@Input() set user(user: UserModel) {
		this._user = user;
	}

	get user(): UserModel {
		return this._user;
	}

	protected _labelNames: ValueInterface[];
	@Input() set labelNames(labels: ValueInterface[]) {
		this._labelNames = labels;
		if ((this.property || this.prospect) && labels) {
			this.getTaskGroups();
		}
	}

	get labelNames(): ValueInterface[] {
		return this._labelNames;
	}

	@Input() public hasAdminRights: boolean;

	@Output() public groups: EventEmitter<boolean> = new EventEmitter<boolean>();

	protected subject = new Subject<any>();

	public taskGroups: TaskGroupModel[] | TaskGroupPropertyModel[] | TaskGroupProspectModel[];
	public hasEditRights: boolean = false;
	public loading: boolean = false;
	public showCompleted: boolean = false;
	public showArchived: boolean = false;

	constructor(
		protected taskService: TaskService,
		protected taskDialog: MatDialog,
		protected taskGroupDialog: MatDialog,
		protected deleteDialog: MatDialog,
		protected usersService: UsersService,
		protected toastrService: ToastrService,
		protected fb: FormBuilder,
	) {}

	public addTask(event: MouseEvent, groupId: number): void {
		if (event) {
			event.stopPropagation();
			event.preventDefault();
		}

		this.taskDialog.open(TaskDialogComponent, {
			width: '1000px',
			data: {
				propertyId: this.property ? this.property.id : null,
				prospectId: this.prospect ? this.prospect.id : null,
				task: new TaskOccurrenceDto({
					taskGroupId: groupId,
					occurrence: new TaskOccurrenceModel({
						assignee: new TaskOccurrenceAssigneeModel({
							userId: null,
						}),
					}),
				}),
				action: 'Add',
			},
		}).afterClosed().subscribe((data: {task: TaskOccurrenceDto, action: string}) => {
			if (!data) {
				return;
			}

			if (data.action === 'Add') {
				let method: string;
				if (data.task.propertyId) {
					method = 'createTaskProperty';
				}

				if (data.task.prospectId) {
					method = 'createTaskProspect';
				}

				this.taskService[method](data.task).subscribe((result) => {
					if (result) {
						this.toastrService.success(`Task successfully added to Task Group ${groupId}`);
						this.getTaskGroups();
					}
				}, (error) => {
					this.toastrService.error(error);
				});
			}
		});
	}

	public editTaskGroup(event: MouseEvent, group: TaskGroupModel): void {
		if (event) {
			event.stopPropagation();
			event.preventDefault();
		}

		if (group.createdBy === this.user.userId) {
			this.hasEditRights = true;
		}

		this.taskGroupDialog.open(TaskGroupDialogComponent, {
			width: '1000px',
			data: {
				propertyId: this.property ? this.property.id : null,
				prospectId: this.prospect ? this.prospect.id : null,
				taskGroup: group,
				action: 'Edit',
				hasEditRights: this.hasEditRights,
				hasAdminRights: this.hasAdminRights,
			},
		}).afterClosed().subscribe((data: {group: TaskGroupModel, templateId: number, action: string}) => {
			if (!data) {
				return;
			}
			if (data.action === 'Edit') {
				this.taskService.updateTaskGroup(data.group).subscribe((result) => {
					if (result) {
						this.toastrService.success(`Task successfully updated`);
						this.getTaskGroups();
					}
				}, (error) => {
					this.toastrService.error(error);
				});
			}

			if (data.action === 'Delete') {
				this.deleteDialog.open(DeleteDialogComponent, {
					width: '400px',
					data: {
						text: 'this Task Group',
						type: 'Task Group',
					},
				}).afterClosed().subscribe((result) => {
					if (result === 'Delete') {
						this.taskService.deleteTaskGroup(data.group.id).subscribe(() => {
							this.toastrService.success(`Task Group Successfully Deleted`);
							this.getTaskGroups();
						}, (error: HttpErrorResponse) => {
							this.toastrService.error(error.error.message);
						});
					}
				}, (error: HttpErrorResponse) => {
					this.toastrService.error(error.error.message);
				});
			}
		});
	}

	public getTaskGroups(): void {
		this.loading = true;
		let method: string;
		let id: number;
		if (this.property) {
			method = 'getTaskGroupsByPropertyId';
			id = this.property.id;
		}
		if (this.prospect) {
			method = 'getTaskGroupsByProspectId';
			id = this.prospect.id;
		}

		this.taskService[method](id, this.showCompleted, this.showArchived).subscribe((data) => {
			data.map((group: TaskGroupModel) => {
				let numCompleted = 0;
				let totalNonRepeatingTasks = 0;
				(group.tasks as TaskOccurrenceDto[]).forEach((task: TaskOccurrenceDto) => {
					if (!task.rRuleSet && task.occurrence.lastStatus === 'Done') {
						numCompleted++;
					}

					if (!task.rRuleSet) {
						totalNonRepeatingTasks++;
					}

					if (task.occurrence.labels.length > 0 && this.labelNames) {
						(task as any).labelString = task.occurrence.labels.map(label => {
							if (label) {
								return (' ' + this.labelNames.find(labelName => labelName.id === label).name);
							}
						});
					}
				});
				const percent = numCompleted / totalNonRepeatingTasks * 100;
				(group as any).completedPercent = percent ? +percent.toFixed(2) : 0;
			});

			this.taskGroups = data;

			if (data.length >= 1) {
				this.groups.emit(true);
			} else {
				this.groups.emit(false);
			}
			this.loading = false;
		});
	}

}
