import { Component, DoCheck, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DeleteDialogComponent } from '../../../../../component/deleteDialog/delete-dialog.component';
import { MatDialog, MatPaginator } from '@angular/material';
import { HasDialogComponent } from '../has-dialog/has-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { TableColumnInterface } from '../../../../../component/table/interface/table-column.interface';
import { ProspectSearchDialogComponent } from '../../../../../component/prospect-search-dialog/prospect-search-dialog.component';
import { ProspectListModel, ValuesModel, ProspectHasModel, UserModel } from '@thomas-duke-co/reis-shared';
import { ProspectHasService } from '../../../service/prospect-has.service';
import { MapDialogComponent } from '../../../../../component/map-dialog/map-dialog.component';
import { PaginationDatasource } from '../../../../../datasource/pagination.datasource';
import { Subject } from 'rxjs';
import { TableComponent } from '../../../../../component/table/table.component';
import { MatchmakerDialogComponent } from '../../../../../component/matchmaker-dialog/matchmaker-dialog.component';
import { Router } from '@angular/router';
import { HasDialogDataInterface } from '../../../interface/has-dialog-data.interface';

@Component({
	selector: 'prospect-has',
	templateUrl: './prospect-has.component.pug',
	styleUrls: ['./prospect-has.component.scss'],
})
export class ProspectHasComponent implements OnInit, DoCheck {

	@ViewChild(MatPaginator, {static: true}) protected paginator: MatPaginator;
	@ViewChild(TableComponent, {static: true}) protected table: TableComponent;

	protected _prospectId: number;

	@Input('prospectId')
	set prospectId(prospectId: number) {
		this._prospectId = prospectId;
		this.ngOnInit();
	}

	get prospectId() {
		return this._prospectId;
	}

	@Input() public values: ValuesModel;
	@Input() public hasAdminRights = false;
	@Input() public hasRoutedToRights = false;
	@Input() public isDeleted = false;
	@Input() public needToCheckDuplicates: boolean = false;
	@Input() public user: UserModel;
	@Input() protected createMode = false;

	public dataSource: PaginationDatasource<ProspectHasModel>;
	public displayedColumns: TableColumnInterface[];
	public tableLoading = false;

	protected subject = new Subject<any>();

	@Output() public valueChanged: EventEmitter<any> = new EventEmitter<any>();

	constructor(
		protected dialog: MatDialog,
		protected deleteDialog: MatDialog,
		protected searchDialog: MatDialog,
		protected toastrService: ToastrService,
		protected prospectHasService: ProspectHasService,
		protected mapDialog: MatDialog,
		protected matchDialog: MatDialog,
		protected router: Router,
	) {}

	public ngOnInit(): void {
		this.dataSource = new PaginationDatasource(this.getHas.bind(this), this.paginator, null, [this.subject.asObservable()]);
		this.paginator.page.subscribe(() => this.tableLoading = true);
	}

	public getHas() {
		return this.prospectHasService.getProspectHas(this.prospectId, this.paginator.pageIndex, this.paginator.pageSize)
			.finally(() => this.tableLoading = false);
	}

	public addHas(event) {
		event.preventDefault();
		event.stopPropagation();

		if (this.hasRoutedToRights) {
			const dialog = this.dialog.open(HasDialogComponent, {
				width: '925px',
				data: {
					has: new ProspectHasModel({
						prospectId: this.prospectId,
					}),
					prospectId: this.prospectId,
					values: this.values,
					types: ProspectHasService.TYPES,
					action: 'Add',
					userCanEdit: this.hasRoutedToRights,
					editMode: false,
					itemCanBeMoved: this.createMode,
				},
			});
			dialog.afterClosed().subscribe(async (result: HasDialogDataInterface) => {
				if (!result || result.action === 'Cancel') {
					return;
				} else {
					if (!result.has.id) {
						result.has.prospectId = this.prospectId;
						this.prospectHasService.addHas(result.has).subscribe((id) => {
								if (!id) {
									this.toastrService.error(`Must be Admin to Add a Has`);
								} else {
									this.toastrService.success('Has successfully added.');
									this.subject.next();
								}
							},
							(error: HttpErrorResponse) => {
								this.toastrService.error(error.error.message);
							});
					}
					this.subject.next();
				}
			});
		}
	}

	public async removeHas(hasId: number, prospectId: number, moved?: string) {
		this.prospectHasService.removeHas(hasId, prospectId)
			.subscribe(() => {
					if (!moved) {
						this.toastrService.success(`Has for ${this.prospectId} has been deleted`);
					}
					this.subject.next();
				},
				(error: HttpErrorResponse) => {
					this.toastrService.error(error.error.message);
				},
			);
	}

	public editHas(event) {
		const has: ProspectHasModel = event.row;

		const dialog = this.dialog.open(HasDialogComponent, {
			width: '925px',
			data: {
				has: {
					id: has.id,
					name: has.name,
					type: has.type,
					purchaseDate: has.purchaseDate ? new Date(has.purchaseDate) : null,
					financialClassId: has.financialClassId,
					financialClass: has.financialClass,
					categoryId: has.categoryId,
					category: has.category,
					streetName: has.streetName,
					streetNumber: has.streetNumber,
					street: has.street,
					location: has.location,
					parcelId: has.parcelId,
					countyId: has.countyId,
					county: has.county,
					cityId: has.cityId,
					city: has.city,
					roadId: has.roadId,
					size: has.size,
					price: has.price,
					comments: has.comments,
					sellingBroker: has.sellingBroker,
					zoning: has.zoning,
					propertyManagement: has.propertyManagement,
					prospectId: has.prospectId,
				},
				prospectId: this.prospectId,
				types: ProspectHasService.TYPES,
				values: this.values,
				action: 'Edit',
				userCanEdit: this.hasAdminRights,
				userCanDelete: this.hasAdminRights,
				editMode: true,
				itemCanBeMoved: !this.createMode && this.hasAdminRights,
			},
		});

		dialog.afterClosed().subscribe(async (data: HasDialogDataInterface) => {
			if (!data || data.action === 'Cancel') {
				return;
			} else if (data.action === 'Delete' && this.hasAdminRights) {
				const deleteDialog = this.deleteDialog.open(DeleteDialogComponent, {
					width: '400px',
					data: {
						text: `this Has entry`,
						type: 'Has Entry',
					},
				});
				deleteDialog.afterClosed().subscribe(async result => {
					if (result === 'Delete') {
						await this.removeHas(has.id, this.prospectId);
					}
				});
			} else if (data.action === 'Save & Move') {
				this.prospectHasService.updateHas(data.has)
					.subscribe(() => {
							this.toastrService.success(`Updated Has Prior to Moving`);
							this.subject.next();
						},
						(error: HttpErrorResponse) => {
							this.toastrService.error(error.error.message);
						},
					);
				this.showMoveDialog(data.has);
			} else if (data.action === 'Move') {
				this.showMoveDialog(data.has);
			} else {
				this.prospectHasService.updateHas(data.has)
					.subscribe(() => {
							this.toastrService.success(`Has for #${this.prospectId} has been updated`);
							this.subject.next();
						},
						(error: HttpErrorResponse) => {
							this.toastrService.error(error.error.message);
						});
			}
		});
	}

	public showMoveDialog(has: ProspectHasModel): void {
		this.searchDialog.open(ProspectSearchDialogComponent, {
			width: '1000px',
			data: {
				multiple: false,
				advancedExpanded: false,
			},
		}).afterClosed().subscribe((newProspect: ProspectListModel) => {
			if (!newProspect || !this.prospectId || newProspect.id === this.prospectId) {
				return;
			}
			this.prospectHasService.moveHas(this.prospectId, has.id, newProspect.id).subscribe(
				async () => {
					this.toastrService.success('Has entry successfully moved.');
					await this.removeHas(has.id, this.prospectId, 'moved');
					this.subject.next();
				},
				(error: HttpErrorResponse) => {
					this.toastrService.error(error.error.message);
				},
			);
		});
	}

	public showMap(row: {prospectHas: Partial<ProspectHasModel>}): void {
		this.mapDialog.open(MapDialogComponent, {
			width: '1000px',
			data: row,
		}).afterClosed().subscribe();
	}

	public async showMatch(has: ProspectHasModel): Promise<void> {
		this.matchDialog.open(MatchmakerDialogComponent, {
			width: '1500px',
			data: {
				has: has,
				prospectId: this.prospectId,
				user: this.user,
			},
		}).afterClosed().subscribe((result: any) => {

			if (result.location) {
				// noinspection JSIgnoredPromiseFromCall
				this.router.navigateByUrl(`/${result.location}/detail/${result.id}#details`);
			}
		});
	}

	public ngDoCheck() {
		if (this.displayedColumns) {
			return;
		}

		this.displayedColumns = [{
			columnDef: 'parcelId',
			title: 'Parcel ID',
		}, {
			columnDef: 'name',
			title: 'Name',
		}, {
			columnDef: 'street',
			title: 'Street Address',
		}, {
			columnDef: 'location',
			title: 'Location',
		}, {
			columnDef: 'financialClass',
			title: 'Financial Class',
		}, {
			columnDef: 'category',
			title: 'Category',
		}, {
			columnDef: 'addMap',
			title: 'Map',
			pipe: 'actions',
			buttons: [{
				fontSet: 'far',
				fontIcon: 'fa-map',
				color: 'primary',
				tooltip: 'View Map',
				callback: (row: {prospectHas: Partial<ProspectHasModel>}): void => {
					this.showMap(row);
				},
				condition: (row: any): boolean => {
					return !!row.roadId || !!row.city || !!row.county;
				},
			}],
		},
			{
				columnDef: 'prospectMatches',
				title: 'Find Matches',
				pipe: 'actions',
				buttons: [{
					raised: true,
					tooltip: 'Prospect Matches',
					label: 'Prospect Matches',
					callback: (row: ProspectHasModel): void => {
						this.showMatch(row);
					},
				}],
			}];
	}
}

