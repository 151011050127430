import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserListModel, ValueInterface, TaskOccurrenceDto } from '@thomas-duke-co/reis-shared';
import { MatTableDataSource, MatDialog } from '@angular/material';
import { TableColumnInterface } from '../table/interface/table-column.interface';
import { TaskService } from '../../service/task.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { TaskDialogComponent } from '../task-dialog/task-dialog.component';
import { UserModel } from '@thomas-duke-co/reis-shared';
import { ToastrService } from 'ngx-toastr';
import { EditOptionDialogComponent } from '../edit-option-dialog/edit-option-dialog.component';

@Component({
	selector: 'task-list',
	templateUrl: './task-list.component.pug',
	styleUrls: ['./task-list.component.scss'],
})

export class TaskListComponent implements OnInit {

	protected _tasks: TaskOccurrenceDto[];
	@Input() set tasks(tasks: TaskOccurrenceDto[]) {
		this._tasks = tasks;
		if (tasks && this.users && this.user) {
			this.setDataSource();
		} else {
			this.dataSource = undefined;
		}
	}

	get tasks(): TaskOccurrenceDto[] {
		return this._tasks;
	}

	protected _users: UserListModel[];
	@Input() set users(users: UserListModel[]) {
		this._users = users;
		if (users && this.tasks && this.user) {
			this.setDataSource();
		} else {
			this.dataSource = undefined;
		}
	}

	get users(): UserListModel[] {
		return this._users;
	}

	protected _user: UserModel;
	@Input() set user(user: UserModel) {
		this._user = user;
		if (this.tasks && this.users && user) {
			this.setDataSource();
		} else {
			this.dataSource = undefined;
		}
	}

	get user(): UserModel {
		return this._user;
	}

	protected _labelNames: ValueInterface[];
	@Input() set labelNames(labels: ValueInterface[]) {
		this._labelNames = labels;
		if (this.tasks && labels) {
			this.setDataSource();
		}
	}

	get labelNames(): ValueInterface[] {
		return this._labelNames;
	}

	@Input() public taskGroups: boolean;
	@Input() public hasAdminRights: boolean;
	@Input() public dashboardPage: boolean = false;

	@Output() public beforeTaskEdit: EventEmitter<void> = new EventEmitter<void>();
	@Output() public afterTaskEdit: EventEmitter<void> = new EventEmitter<void>();

	public dataSource: MatTableDataSource<TaskOccurrenceDto>;
	public columns: TableColumnInterface[] = [
		{
			columnDef: 'completed', title: 'Completed', pipe: 'actions',
			buttons: [
				{
					fontSet: 'far',
					fontIcon: 'fa-check-square',
					color: 'primary',
					tooltip: 'Task Complete',
					callback: (row: TaskOccurrenceDto, event: MouseEvent): void => {
						return this.toggleTaskCompletion(row, event);
					},
					condition: (row: TaskOccurrenceDto): boolean => {
						return (row.occurrence.lastStatus === 'Done');
					},
				},
				{
					fontSet: 'far',
					fontIcon: 'fa-square',
					color: 'primary',
					tooltip: 'Task Incomplete',
					callback: (row: TaskOccurrenceDto, event: MouseEvent): void => {
						return this.toggleTaskCompletion(row, event);
					},
					condition: (row: TaskOccurrenceDto): boolean => {
						return (row.occurrence.lastStatus === 'ToDo');
					},
				},
			],
		},
		{columnDef: 'completeBy', title: 'Complete By'},
		{columnDef: 'dateCompleted', title: 'Date Completed'},
		{columnDef: 'title', title: 'Task'},
		{columnDef: 'description', title: 'Notes'},
		{columnDef: 'assignedTo', title: 'Assigned To'},
		{columnDef: 'labelString', title: 'Labels'},
	];

	protected subject = new Subject<any>();

	constructor(
		protected taskService: TaskService,
		protected taskDialog: MatDialog,
		protected toastrService: ToastrService,
		protected editOptionDialog: MatDialog,
	) {}

	public async ngOnInit(): Promise<void> {
		this.getColumns();
	}

	public editTask(row: TaskOccurrenceDto): void {
		if (!this.hasEditRights(row.occurrence.assignee.userId) && !this.hasAdminRights && !this.isAssignee(row.occurrence.assignee.userId)) {
			this.toastrService.error(`${this.user.firstName} ${this.user.lastName} cannot edit this task`);
			return;
		}

		this.taskDialog.open(TaskDialogComponent, {
			width: '1000px',
			data: {
				task: row,
				action: 'Edit',
				hasAdminRights: this.hasAdminRights,
				hasEditRights: this.hasEditRights(row.occurrence.assignee.userId),
				isAssignee: this.isAssignee(row.occurrence.assignee.userId),
				dashboardPage: this.dashboardPage,
			},
		}).afterClosed().subscribe(async (data: {task: TaskOccurrenceDto, action: string}) => {
			let editAll: boolean = false;

			if (!data) {
				return;
			}

			this.beforeTaskEdit.emit();

			if (data.action === 'Edit Repeat') {
				await this.editTasks(data.task, true, true);
			}

			if (data.action === 'Edit' && data.task.rRuleSet) {
				this.editOptionDialog.open(EditOptionDialogComponent, {
					width: '400px',
					data: data.action,
				}).afterClosed().subscribe(async (result) => {
					if (result === 'Cancel') {
						return;
					}
					if (result === 'All') {
						editAll = true;
						await this.editTasks(data.task, editAll);
					} else {
						await this.editTasks(data.task, editAll);
					}
				});
			}

			if (data.action === 'Edit' && !data.task.rRuleSet) {
				await this.editTasks(data.task, true);
			}

			if (data.action === 'Delete' && data.task.rRuleSet) {
				this.editOptionDialog.open(EditOptionDialogComponent, {
					width: '400px',
					data: data.action,
				}).afterClosed().subscribe(async (result) => {
					if (result === 'Cancel') {
						return;
					}
					if (result === 'All') {
						editAll = true;
						await this.deleteTasks(data.task, editAll);
					} else {
						await this.deleteTasks(data.task, editAll);
					}
				});
			}

			if (data.action === 'Delete' && !data.task.rRuleSet) {
				await this.deleteTasks(data.task, true);
			}

			if (data.action === 'Toggle') {
				data.task.occurrence.isArchived = !data.task.occurrence.isArchived;
				this.taskService.updateArchiveStatus(data.task).subscribe(() => {
					this.toastrService.success(`All occurrences successfully saved`);
				});
			}
		});
	}

	public async editTasks(task: TaskOccurrenceDto, editAll: boolean, updateRRule?: boolean): Promise<void> {
		if (!updateRRule) {
			updateRRule = false;
		}

		if (editAll) {
			this.taskService.updateTaskAndOccurrences(task, updateRRule).subscribe(async (allResult) => {
				if (allResult) {
					this.toastrService.success(`All occurrences successfully updated`);
					this.afterTaskEdit.emit();
				}
			}, (error) => {
				this.toastrService.error(error);
			});
		}

		if (!editAll) {
			this.taskService.updateOccurrence(task).subscribe(async (occurrenceResult) => {
				if (occurrenceResult) {
					this.toastrService.success(`Single occurrence successfully updated`);
					this.afterTaskEdit.emit();
				}
			}, (error) => {
				this.toastrService.error(error);
			});
		}
	}

	public async deleteTasks(task: TaskOccurrenceDto, editAll: boolean): Promise<void> {
		if (editAll) {
			this.taskService.deleteTaskAndOccurrences(task.occurrence.taskId).subscribe(() => {
				this.toastrService.success('All Occurrences of this task successfully deleted');
				this.afterTaskEdit.emit();
			}, (error) => {
				this.toastrService.error(error);
			});
		}

		if (!editAll) {
			this.taskService.deleteOccurrence(task.occurrence.id).subscribe(() => {
				this.toastrService.success('Single Occurrence successfully deleted');
				this.afterTaskEdit.emit();
			}, (error) => {
				this.toastrService.error(error);
			});
		}
	}

	public getColumns(): TableColumnInterface[] {
		return this.columns;
	}

	protected isAssignee(userId: number): boolean {
		return userId === this.user.userId;
	}

	protected hasEditRights(userId: number): boolean {
		return userId === this.user.userId && userId === this.user.userId;
	}

	protected async setDataSource(): Promise<void> {
		const toDelete: number[] = [];

		await Promise.all(this.tasks.map(async (task: TaskOccurrenceDto) => {
			let assignee: UserListModel = new class implements UserListModel {
				active: boolean;
				email: string;
				firstName: string;
				initials: string;
				lastName: string;
				middleInitial: string;
				position: string;
				roles: string[];
				userId: number;
				username: string;
			};

			if (!task.occurrence.assignee.userId) {
				(task as any).assignedTo = '';
			} else {
				assignee = this.users.find(user => user.userId === task.occurrence.assignee.userId);

				if (assignee && !assignee.active) {
					(task as any).assignedTo = assignee.firstName + ' ' + assignee.lastName + ' (INACTIVE)';
				} else {
					(task as any).assignedTo = assignee.firstName + ' ' + assignee.lastName;
				}
			}

			(task as any).description = task.occurrence.description;

			if (!task.isPrivate) {
				task.isPrivate = false;
			}

			(task as any).completeBy = moment.utc(task.occurrence.startDatetime).format('MM-DD-YYYY');
			(task as any).dateCompleted = task.occurrence.dateCompleted ? moment.utc(task.occurrence.dateCompleted).format('MM-DD-YYYY') : '-';
			(task as any).title = task.occurrence.title;

			if (task.propertyId) {
				(task as any).name = task.propertyName;
			}

			if (task.prospectId) {
				(task as any).name = task.prospectName;
			}

			if (task.isPrivate) {
				if (!this.hasEditRights(task.occurrence.assignee.userId)) {
					toDelete.push(task.occurrence.id);
				}
			}

			if (task.occurrence.labels.length > 0 && this.labelNames) {
				(task as any).labelString = task.occurrence.labels.map(label => {
					if (label) {
						return (' ' + this.labelNames.find(x => x.id === label).name);
					}
				});
			}

		}));

		const y = new Set(toDelete);
		const publicTasks: TaskOccurrenceDto[] = this.tasks.filter(task => !y.has(task.occurrence.id));

		this.dataSource = new MatTableDataSource<TaskOccurrenceDto>(publicTasks);
	}

	protected toggleTaskCompletion(row: TaskOccurrenceDto, event: MouseEvent): void {
		if (event) {
			event.stopPropagation();
			event.preventDefault();
		}

		if (!this.hasEditRights(row.occurrence.assignee.userId) && !this.hasAdminRights && !this.isAssignee(row.occurrence.assignee.userId)) {
			this.toastrService.error(`${this.user.firstName} ${this.user.lastName} cannot edit this task`);
			return;
		}

		if (!this.dashboardPage) {
			this.beforeTaskEdit.emit();
		}

		if (row.occurrence.lastStatus === 'ToDo') {
			row.occurrence.lastStatus = 'Done';
		} else {
			row.occurrence.lastStatus = 'ToDo';
		}

		row.occurrence.lastUpdated = new Date().toISOString();
		row.occurrence.dateCompleted = new Date().toISOString();

		this.taskService.updateTaskStatus(row.occurrence).subscribe((result) => {
			if (result) {
				this.toastrService.success(`${row.occurrence.title} status updated`);
				if (!this.dashboardPage) {
					this.afterTaskEdit.emit();
				}
			}
		}, (error) => {
			this.toastrService.error(error);
		});
	}

}
