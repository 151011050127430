import {Pipe, PipeTransform} from '@angular/core';
import {UserListModel} from '@thomas-duke-co/reis-shared';

@Pipe({
	name: 'userFilter'
})

export class UserFilterPipe implements PipeTransform {
	transform(users: UserListModel[], showInactive: boolean): UserListModel[] {
		if (!users) {
			return [];
		}

		let result: UserListModel[] = users.slice(); // copy the users array by value

		if (!showInactive) {
			result = this.getActiveUsers(result);
		}

		return result;
	}

	private getActiveUsers(users: UserListModel[]) {
		return users.filter((user: UserListModel) => user.active);
	}
}
