import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { TaskPaginationListComponent } from '../../../../component/task-pagination-list/task-pagination-list.component';
import { ValueInterface } from '@thomas-duke-co/reis-shared';
import { BoxComponent } from '../box/box.component';
import { MatSelectChange } from '@angular/material';

@Component({
	selector: 'is2-tasks',
	templateUrl: './tasks.component.pug',
	styleUrls: ['./tasks.component.scss'],
})

export class TasksComponent {

	@Output() public print: EventEmitter<string> = new EventEmitter<string>();
	@Output() public afterPrint: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild('taskPaginationList', {static: false}) public taskPaginationList: TaskPaginationListComponent;
	@ViewChild('box', {static: true}) public box: BoxComponent;

	protected _labelNames: ValueInterface[];
	@Input() set labelNames(labels: ValueInterface[]) {
		this._labelNames = labels;
		if (labels) {
			this.box.dropdownMultiple = true;
			this.labelNames.map((label) => {
				label = Object.assign(label, { selected: false});
				this.dropdown.push(label);
			});
		}
	}

	get labelNames(): ValueInterface[] {
		return this._labelNames;
	}

	public dropdown: ValueInterface[] = [];
	public selectedLabels: number[] = [];

	public refreshData(): void {
		this.taskPaginationList.getTasks();
	}

	public printTasks(): void {
		this.print.emit('is2-tasks');
		setTimeout(() => window.print());
		window.onafterprint = () => {
			this.afterPrint.emit();
		};
	}

	public dropdownChangeEvent(event: MatSelectChange): void {
		if (event.value) {
			this.selectedLabels = event.value;
		}
	}

	public dropdownClosed(): void {
		if (this.selectedLabels) {
			this.taskPaginationList.getTasks();
		}
	}
}
